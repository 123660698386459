import React from "react";
import "./index.scss";

//Redux
import Api from "../../services/api";
import ApiV2 from "../../services/api-v2";
import { Diversos } from "../../services/diversos";
import { ApplicationState } from "../../store";
import { User } from "../../store/ducks/user/types";
import * as UserActions from "../../store/ducks/user/actions";
import { Param } from "../../store/ducks/param/types";
import * as ParamActions from "../../store/ducks/param/actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Auth } from "../../store/ducks/auth/types";

import Lottie from "react-lottie";

//Componentes
import HeaderCliente from "../../components/headerCliente";
import Footer from "../../components/Footer";
import Buscar from "../../components/Buscar";
import Container from "../../components/ContainerSlider";
import ContainerCercanos from "../../components/ContainerCercanos";
import ContainerPromo from "../../components/ContainerPromo";
import SideMenu from "../../components/SideMenu";

import Loading from "../../components/loading";

//Calling bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
//Calling WOWjs
import WOW from "wowjs";

interface StateProps {
  user: User;
  param: Param;
  auth: Auth;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  setParam(param: Param): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class HomeCliente extends React.Component<Props> {
  state: any = null;
  api: any;
  apiv2: any;
  userRef: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.apiv2 = new ApiV2();
    this.state = {
      etapa: 1,
      loading: false,
      width: 100,
      search: "",
      currentLongitude: 0,
      currentLatitude: 0,
      promocao: [],
      isLoadingPromocao: false,
      salaoProximo: [],
      isLoadingSalaoProximo: false,
      isLoadingPromocoes: false,
      promocoes: [],
    };
  }

  // Defining WOW
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();

    const self = this;

    if (
      !this.props.user ||
      !this.props.user.codigo ||
      !this.props.user.fgFuncionario
    ) {
      window.location.href = "/login";
    }
  }

  render() {
    return this.state.loading == true ? (
      <Loading />
    ) : (
      <>
        <HeaderCliente registra={false} login={false} notificacoes={true} />
        <div className="home-cliente-main">
          <SideMenu />
          <div className="container-home-usuario">
            <div className="box-home">
              <Buscar />
              <ContainerCercanos tit="Centros más cercanos" />
              <ContainerPromo />
              <Container tit="Novidades" />
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomeCliente);
