import React, { useState, useEffect } from 'react';
import './index.scss';

//Componentes
import Header from '../../../components/header';
import Footer from '../../../components/Footer';

//Calling bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
//Calling WOWjs
import WOW from 'wowjs';

import Tpromo from '../../../assets/images/t-promo.svg';
import Festa from '../../../assets/images/emojis/festa.png';
import Bomba from '../../../assets/images/emojis/bomba.png';

//Redux
import Api from '../../../services/api';
import ApiV2 from '../../../services/api-v2';
import { Diversos } from '../../../services/diversos';
import { ApplicationState } from '../../../store';
import { User } from '../../../store/ducks/user/types';
import * as UserActions from '../../../store/ducks/user/actions';
import { Param } from '../../../store/ducks/param/types';
import * as ParamActions from '../../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Auth } from '../../../store/ducks/auth/types';
import moment from 'moment';

import Lottie from 'react-lottie';
//Animaceos Lottie
import animationData from '../../../animations/loading.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

interface StateProps {
    user: User;
    param: Param;
}

interface DispatchProps {
    doLogin(user: User): void;
    setParam(param: Param): void;
    setParam(param: Param): void;
}

interface OwnProps { }

type Props = StateProps & DispatchProps & OwnProps;

class NovaPromocao extends React.Component<Props> {

    state: any = null;
    api: any;
    apiv2: any;
    userRef: any;

    constructor(props) {
        super(props);
        this.api = new Api();
        this.apiv2 = new ApiV2();
        this.state = {
            logo: require('../../../assets/images/logo-salao.png'),
            img: require('../../../assets/images/default.png'),

            logoPrev: require('../../../assets/images/logo-salao.png'),
            imgPrev: require('../../../assets/images/default.png'),

            boxPromo: false,
            boxEmoji: false,
            boxDuracion: true,

            servicos: [],

            procedimentoNome: '',
            emoji: 1,
            promo: '10',
            titulo: '',
            servico: '',
            dataInicio: moment(),
            dataFinal: moment().add(1, 'month'),
            loading: false,

        }
        this.submitPromo = this.submitPromo.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeProcedimento = this.handleChangeProcedimento.bind(this);
    }


    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();

        if (this.props.user.empresa == 0) {
            window.location.href = '/home-cliente'
        }

        moment.locale('pt-BR');
        this.getServicos()
    }

    private async getServicos() {
        const self = this;

        let param = {
            profissional: this.props.user.codigo,
            limit: 0,
        };

        try {
            const { data } = await this.api.post(
                '/Servico/getAllByProfissional',
                param,
            );

            if (data.status == true) {
                this.setState({ servicos: data.msg });
            }
        } catch (e) {
            console.log(e);
        } finally {

        }
    }

    private async submitPromo() {
        const self = this;

        this.setState({ loading: true })

        window.scrollTo(0, 0);

        const toBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });

        if (
            !self.state.servico ||
            !self.state.dataInicio ||
            !self.state.dataFinal ||
            !self.state.promo ||
            !self.state.img ||
            !self.state.logo
        ) {
            alert('Atencion Campos obligatorios no rellenados.');
        }
        else {

            let tmpLogo: any = await toBase64(this.state.logo);
            let tmpBanner: any = await toBase64(this.state.img);


            let param = {
                preco: this.state.promo,
                data_inicio: moment(this.state.dataInicio).format('YYYY-MM-DD'),
                data_fim: moment(this.state.dataFinal).format('YYYY-MM-DD'),
                banner: tmpBanner.replace("data:image/jpeg;base64,", '').replace("data:image/png;base64,", ''),
                logo: tmpLogo.replace("data:image/jpeg;base64,", '').replace("data:image/png;base64,", ''),
                servico: this.state.servico,
            };

            try {
                const { data, status } = await self.apiv2.post(
                    `/salon/${self.props.user.codigo}/service/${self.state.servico}/promotion`,
                    param,
                );
                if (data.status == false) {
                    console.log(data)
                    alert('No se puede crear la promoción ahora, inténtalo de nuevo más tarde.')
                } else {
                    console.log(data)
                    alert('Promoción creada con éxito')
                    window.location.href = "/promocion"
                }
            } catch (e) {
                console.log(e);
                alert('Seleccione imágenes de menos de 1 MB de tamaño')
            } finally {
                this.setState({ loading: false })
            }
        }
    }

    handleChangeSelect(event) {
        this.setState({
            emoji: event.target.value,
        });
    }

    handleChangeProcedimento(event) {
        this.setState({
            promocedimentoNome: event.target.value,
        });
    }

    handleChangeImage = event => {
        this.setState({
            img: event.target.files[0],
            imgPrev: URL.createObjectURL(event.target.files[0]),
        });
    }

    handleChangeLogo = event => {
        this.setState({
            logo: event.target.files[0],
            logoPrev: URL.createObjectURL(event.target.files[0]),
        });
    }

    render() {
        return (
            <>
                <Header
                    registra={false}
                    login={false}
                    notificacoes={true}
                />
                <section className="container-criar-promo">
                    <div className="row-criar-promo">
                        {
                            this.state.loading ?
                                <Lottie
                                    options={defaultOptions}
                                    height={350}
                                    width={350}
                                    isStopped={this.state.isStopped}
                                    isPaused={this.state.isPaused}
                                />
                                :
                                <>
                                    <div className="header-row">
                                        <h5>Crear Promociones</h5>
                                    </div>
                                    <div className="card-promo wow fadeInUp">
                                        <img className="fotoCapa" src={this.state.imgPrev} />
                                        <div className="content">
                                            <div className="header-content">
                                                <img src={this.state.logoPrev} />
                                            </div>
                                            <div className="layer">
                                                <img src={Tpromo} />
                                            </div>
                                            <div className="footer-content">
                                                <p className="procedimento" style={{ fontSize: '22px' }}>{this.state.procedimentoNome}</p>
                                                <div className="promo-area">
                                                    <p className="desconto">{this.state.promo}%</p>
                                                    <p className="desc">OFF</p>
                                                    {
                                                        this.state.promo == 10 || 20 || 30 || 40 || 50 ?
                                                            <img src={Festa} />
                                                            :
                                                            this.state.promo == 60 || 70 || 80 || 90 || 100 ?
                                                                <img src={Bomba} />
                                                                :
                                                                null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: '480px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: 10, }}>
                                        <i><p>Vista previa</p></i>
                                    </div>
                                    <div className="inputs-container">
                                        <div className="box-select wow fadeInUp">
                                            <div className="form-check">
                                                <select value={this.state.servico || ''} onChange={(event) => this.setState({ servico: event.target.value })} className="select">
                                                    <option value={0}>Seleccione un servicio</option>
                                                    {
                                                        this.state.servicos.map(item => (
                                                            <option value={item.codigo}>{item.nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <p className="wow fadeInUp">*Por favor, las imágenes deben tener un máximo de 1mb.</p>
                                        <div className="container-input-promo wow fadeInUp" style={{ marginTop: 10 }}>
                                            <label htmlFor="imagem">Imágen de la promoción</label>
                                            <input type="file" name="imagem" id="imagem" onChange={this.handleChangeImage} />
                                            <i className="fas fa-upload"></i>
                                        </div>
                                        <div className="container-input-promo wow fadeInUp">
                                            <label htmlFor="Logo">Tu logo</label>
                                            <input type="file" name="Logo" id="Logo" onChange={this.handleChangeLogo} />
                                            <i className="fas fa-upload"></i>
                                        </div>
                                        <div className="box-select wow fadeInUp">
                                            <div className="form-check">
                                                <select value={this.state.promo} onChange={(event) => this.setState({ promo: event.target.value })} className="select">
                                                    <option value="">Seleccione el descuento</option>
                                                    <option value="10">10% 🎉</option>
                                                    <option value="20">20% 🎉</option>
                                                    <option value="30">30% 🎉</option>
                                                    <option value="40">40% 🎉</option>
                                                    <option value="50">50% 💣</option>
                                                    <option value="60">60% 💣</option>
                                                    <option value="70">70% 💣</option>
                                                    <option value="80">80% 💣</option>
                                                    <option value="90">90% 💣</option>
                                                    <option value="100">100% 💣</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="box-select wow fadeInUp">
                                            <label>Duracion:</label>
                                            <div className="box-data-filtro">
                                                <div className="data-fil">
                                                    <form>
                                                        <label htmlFor="desde">Desde</label>
                                                        <input type="date" id="desde" onChange={(event) => {
                                                            this.setState({ dataInicio: event.target.value });
                                                        }} />
                                                    </form>
                                                </div>
                                                <div className="data-fil">
                                                    <form>
                                                        <label htmlFor="hasta">Hasta</label>
                                                        <input type="date" id="hasta" onChange={(event) => {
                                                            this.setState({ dataFinal: event.target.value });
                                                        }} />
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="btn_primary" style={{ width: '100%' }} onClick={() => this.submitPromo()}>Confirmar Promoción</button>
                                        <a href="/promocion">
                                            <p>cancelar</p>
                                        </a>
                                    </div>
                                </>
                        }
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.data,
    param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NovaPromocao);