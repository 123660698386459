import React from 'react';
import './index.scss';

interface Props {
    titulo: string,
    texto: string,
    lido: string,
    criado: any,
    id: any,
}

export class CardNotificacao extends React.Component<Props> {

    state: any = null;

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <a className="card-notificacao" href={'/informacion-procedimiento-cliente/' + this.props.id}>
                <div className="box-tit-not">
                    <h5 className="tit-not">{this.props.titulo}</h5>
                    {
                        this.props.lido == "N" ?
                            <i className="fas fa-circle blue"></i>
                            :
                            <i className="fa fa-check green"></i>
                    }
                </div>
                <p className="info-hr">{this.props.criado}</p>
                <div className="texto-area">
                    <p>{this.props.texto}</p>
                </div>
            </a>
        )
    }
};

export default CardNotificacao;

