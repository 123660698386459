import React from 'react';
import './index.scss';

import Header from '../../components/header';
import Footer from '../../components/Footer';

import Lottie from 'react-lottie';
import animationData from '../../animations/complete.json';

interface Props {

}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

class Confirmacao extends React.Component<Props> {

  state: any;

  constructor(props) {
    super(props);
    this.state = {

    }
  }


  render() {
    return (
      <>
        <Header 
          registra={false}
          login={true}
          notificacoes={true}
        />
        <div className="container-confirma">
          <Lottie options={defaultOptions}
            height={250}
            width={250}
            isStopped={this.state.isStopped}
            isPaused={this.state.isPaused} />
          <h5>Confirmado</h5>
          <a href="/home-salao">
            <button className="btn_primary" style={{ marginTop: 50 }}>
              <p>Inicio</p>
            </button>
          </a>
        </div>
        <Footer />
      </>
    )
  }
}

export default Confirmacao