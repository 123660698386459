import React from 'react';
import './index.scss';


interface Props {
    nome: String,
    valor: String,
    tempo: String,
    id: string,
    Salao: string,
}

export class LinhaServico extends React.Component<Props> {

    state: any = null;

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <>
                <a href={'/servicio/programar/' + this.props.id + '/' + this.props.Salao}>
                    <section className="linha-servico">
                        <h5>{this.props.nome}</h5>
                        <div className="dados-servico">
                            <div className="dados-1">
                                <p>${this.props.valor}</p>
                                <span>
                                    <p>Tiempo {this.props.tempo} minuto(s)</p>
                                </span>
                            </div>
                            <div className="dados-2">
                                <div className="box-stars">

                                </div>
                                <i className="fas fa-chevron-right"></i>
                            </div>
                        </div>
                    </section>
                </a>
            </>
        )
    }
};

export default LinhaServico;

