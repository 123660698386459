import React from 'react';
import './index.scss';

import Lottie from 'react-lottie';
//Animaceos Lottie
import animationData from '../../../animations/loading.json';

//Componentes
import Header from '../../../components/header';
import Footer from '../../../components/Footer';

//Calling bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
//Calling WOWjs
import WOW from 'wowjs';

//Redux
import Api from '../../../services/api';
import ApiV2 from '../../../services/api-v2';
import { Diversos } from '../../../services/diversos';
import { ApplicationState } from '../../../store';
import { User } from '../../../store/ducks/user/types';
import * as UserActions from '../../../store/ducks/user/actions';
import { Param } from '../../../store/ducks/param/types';
import * as ParamActions from '../../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Auth } from '../../../store/ducks/auth/types';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};


interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  setParam(param: Param): void;
}

interface OwnProps { }

type Props = StateProps & DispatchProps & OwnProps;

class MiDireccion extends React.Component<Props> {

  state: any = null;
  api: any;
  apiv2: any;
  userRef: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.apiv2 = new ApiV2();
    this.state = {
      isLoading: false,
      rua: null,
      cidade: null,
      numero: '',
      cep: '',
    }
  }

  // Defining WOW 
  componentDidMount() {
    new WOW.WOW({
      live: false
    }).init();

    if (this.props.user.empresa == 0) {
      window.location.href = '/home-cliente'
    }

    this.buscarEnd()
  }

  private async submitForm() {
    const self = this;

    this.setState({ isLoading: true })

    try {
      let param = {
        perfil: 2,
        codigo: this.props.user.codigo,
        rua: this.state.rua,
        numero: this.state.numero,
        cidade: this.state.cidade,
        estado: "",

      };

      const { data } = await self.api.post(
        `/User/update/${self.props.user.codigo}`,
        param,
      );

      if (data.status === false) {
        alert('Ocurrió un error inesperado, inténtalo de nuevo más tarde.')
        console.log(Error)
      } else {
        alert('Dirección actualizada con éxito')
        window.location.href = '/mi-direccion-salon'
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isLoading: false })
    }
  }

  async buscarEnd() {
    const self = this;

    this.setState({ isLoading: true })

    try {
      let param = {
        codigo: this.props.user.codigo
      };

      const { data } = await self.apiv2.get(
        `/salon/${self.props.user.codigo}`,
        param,
      );

      this.setState({
        cidade: data.msg.CIDA,
        rua: data.msg.ENDE,
        numero: data.msg.NREND,
        cep: data.msg.CEP
      })

      if (data.status === false) {
        alert('erro' + Error)
      } else {

      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isLoading: false })
    }
  }


  render() {
    return (
      <>
        <Header
          registra={false}
          login={false}
          notificacoes={true}
        />

        <section className="container-mi-direccion">
          <div className="row-mi-direccion">
            <div className="header-direccion wow fadeInUp">
              <i className="fas fa-map-marker-alt"></i>
              <h5>Mi Dirección</h5>
            </div>
            {
              this.state.isLoading ?
                <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                  <Lottie
                    options={defaultOptions}
                    height={350}
                    width={350}
                    isStopped={this.state.isStopped}
                    isPaused={this.state.isPaused}
                  />
                </div>
                :
                <>
                  <div className="input-box wow fadeInUp">
                    <small>Dirección*</small>
                    <input type="text" value={this.state.rua} onChange={(event) => this.setState({ rua: event.target.value })} />
                  </div>
                  <div className="input-box wow fadeInUp">
                    <small>Cuidad</small>
                    <input type="text" value={this.state.cidade} onChange={(event) => this.setState({ cidade: event.target.value })} />
                  </div>
                  <div className="input-box wow fadeInUp">
                    <small>Numero</small>
                    <input type="number" value={this.state.numero} onChange={(event) => this.setState({ numero: event.target.value })} />
                  </div>
                  <div className="input-box wow fadeInUp" style={{ width: '50%' }}>
                    <small>Código posral</small>
                    <input type="number" value={this.state.cep} onChange={(event) => this.setState({ cep: event.target.value })} />
                  </div>
                  <button className="btn_primary wow fadeInUp"
                    style={{ width: '100%', marginTop: 25 }}
                    onClick={() => this.submitForm()}>
                    <i className="fas fa-save"></i>
                    <p>Guardar</p>
                  </button>
                </>
            }
          </div>
        </section>
        <Footer />
      </>
    )
  }
}


const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MiDireccion);