import {combineReducers} from 'redux';
import user from './user';
import param from './param';
import auth from './auth';
import cart from './cart';
import categoria from './categoria';

export default combineReducers({
  user,
  param,
  auth,
  cart,
  categoria,
});