import React from 'react';
import './index.scss';

//Componentes
import Header from '../../../components/header';
import Footer from '../../../components/Footer';

import Tpro from '../../../assets/images/t-pro.png';

//Calling bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
//Calling WOWjs
import WOW from 'wowjs';

class Estatisticas extends React.Component {

    state: any = null;

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();
    }

    render() {

        return (
            <>
                <Header
                    registra={false}
                    login={false}
                    notificacoes={true}
                />

                <section className="container-estatisticas">
                    <div className="row-estatisticas">
                        <div className="header-estatisticas">
                            <i className="fas fa-sliders-h"></i>
                            <h5>Estadisticas</h5>
                        </div>
                        <p style={{ marginTop: 20 }}>Selecciona un perfil para ver las estadisticas</p>
                        <div className="box-perfil-select">
                            <div className="salao-logo-box">
                                <img src={Tpro} />
                                <p>Salon</p>
                            </div>

                        </div>
                        <p style={{ marginTop: 20 }}>Fechas predefinidas</p>
                        <div className="box-filtro">
                            <button>
                                <p>Este mes</p>
                            </button>
                            <button>
                                <p>Últimos 7 dias</p>
                            </button>
                            <button>
                                <p>Últimos 30 dias</p>
                            </button>
                            <button>
                                <p>Últimos 6 meses</p>
                            </button>
                            <button>
                                <p>Últimos 12 meses</p>
                            </button>
                        </div>
                        <div className="box-data-filtro">
                            <div className="data-fil">
                                <form>
                                    <label htmlFor="desde">Desde</label>
                                    <input type="date" id="desde" />
                                </form>
                            </div>
                            <div className="data-fil">
                                <form>
                                    <label htmlFor="hasta">Hasta</label>
                                    <input type="date" id="hasta" />
                                </form>
                            </div>
                        </div>

                        <div className="box-select">
                            <div className="form-check">
                                <select className="select">
                                    <option value="Default">Tratamientos</option>
                                    <option value="Nanos">Nanos</option>
                                    <option value="Peluqueria">Peluqueria</option>
                                    <option value="Masajes">Masajes</option>
                                </select>
                            </div>
                        </div>

                        <div className="box-dados-estatisticas">
                            <div className="info-box">
                                <p>Cantidad de servicios realizados</p>
                                <p className="segundo">180</p>
                            </div>
                            <div className="info-box">
                                <p>Monto total de servicios realizados</p>
                                <p className="segundo">$ 200.000</p>
                            </div>
                            <span />
                            <div className="info-box">
                                <p>Comisíon total de profesionales</p>
                                <p className="segundo">$ 20.000</p>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </>
        )
    }
}

export default Estatisticas