import React from 'react';
import './index.scss';

import Header from '../../components/header';
import Footer from '../../components/Footer';

import WOW from 'wowjs';

import Celular1 from '../../assets/images/celular-1.png';
import Celular2 from '../../assets/images/celular-2.png';
import Celular3 from '../../assets/images/celular-3.png';
import Missao from '.././../assets/images/missao.png';
import Visao from '.././../assets/images/visao.png';
import Valores from '.././../assets/images/valores.png';

interface Props {
}

class QuemSomos extends React.Component<Props> {

  state: any;

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  // Defining WOW 
  componentDidMount() {
    new WOW.WOW({
      live: false
    }).init();
  }


  render() {
    return (
      <>
        <Header 
          registra={false}
          login={true}
          notificacoes={true}
        />
        <div className="container-quemsomos">
          <div className="row">
            <h1 className="wow fadeInUp">Quienes somos</h1>
            <p className="wow fadeInUp">Somos una empresa de tecnología innovadora en desarrollo de aplicaciones. Una plataforma digital de descarga gratuita en donde conectamos personas que quieren ofrecer servicios con las que lo están buscando de manera rápida e intuitiva. Dedicamos todo nuestro esfuerzo a hacer crecer a estos negocios como también que toda la comunidad Turnau encuentre ese lugar que tanto desea para sus tratamientos. </p>
            <div className="box-phone">
              <img src={Celular2} className="celular-1 wow fadeInLeft" />
              <img src={Celular1} className="celular-2 wow fadeIn" />
              <img src={Celular3} className="celular-3 wow fadeInRight" />
            </div>
            <div className="box-missao">
              <div className="box wow fadeIn">
                <img src={Missao} />
                <h5>Misión</h5>
                <p>Desarrollar experiencias magníficas en reservas de turnos, estando muy cerca de nuestros centros Turnau PRO para acompañarlos en el crecimiento que nuestra plataforma ofrece y que los usuarios finales consigan rápidamente ¨su sitio preferido¨.</p>
              </div>
              <div className="box wow fadeIn">
                <img src={Visao} />
                <h5>Visión</h5>
                <p>Ser la plataforma digital líder en reserva de turnos de América.</p>
              </div>
              <div className="box wow fadeIn">
                <img src={Valores} />
                <h5>Valores</h5>
                <p>Cumplimos nuestros objetivos.</p>
                <p>Trabajamos en equipo.</p>
                <p>Somos responsables con el mundo.</p>
                <p>Creemos en las personas.</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default QuemSomos