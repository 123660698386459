/**
 * ACTIONS TYPES
 */
export enum ParamTypes {
  SET_PARAM = '@param/SET_PARAM',
}

/**
 * Data types
 */
export interface Param {
  perfil: number;
  fgNovoCartao?: boolean;
  latitude?: string;
  longitude?: string;
  endereco?: string;
  favoritos?: Array<number>;
  isLoading?: boolean;
  fgHasRefresh?: boolean;
  acceptCookies: boolean;
}

/**
 * State types
 */
export interface ParamState {
  readonly data: Param;
}
