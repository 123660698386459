/**
 * ACTIONS TYPES
 */
export enum AuthTypes {
  SET = '@auth/SET',
}

/**
 * Data types
 */
export interface Auth {
  access_token: string;
  type: string;
  expire: string;
}

/**
 * State types
 */
export interface AuthState {
  data: Auth;
}
