import React from 'react';
import './index.scss';

//Redux
import Api from '../../services/api';
import ApiV2 from '../../services/api-v2';
import { Diversos } from '../../services/diversos';
import { ApplicationState } from '../../store';
import { User } from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import { Param } from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Auth } from '../../store/ducks/auth/types';


interface StateProps {
    user: User;
    param: Param;
}

interface DispatchProps {
    doLogin(user: User): void;
    setParam(param: Param): void;
    setParam(param: Param): void;
}

interface OwnProps {
    fotosDados: any,
}

type Props = StateProps & DispatchProps & OwnProps;

export class GaleriaItem extends React.Component<Props> {

    state: any = null;
    api: any;
    userRef: any;

    constructor(props) {
        super(props);
        this.api = new Api();
        this.state = {
            modal: false,
        }
    }

    componentDidMount() {

    }


    render() {
        return (
            <>
                <div className={this.state.modal ? 'fotoUpScreen' : 'fotoUpScreen fotoOff'} onClick={() => this.setState({ modal: false })}>
                    <img src={this.props.fotosDados} />
                </div>
                <div className="foto-ico-galeria" style={{ backgroundImage: 'url(' + this.props.fotosDados + ')' }} onClick={() => this.setState({ modal: true })} />
            </>
        )
    }
};

const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.data,
    param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GaleriaItem);

