import { Reducer } from 'redux';
import { CartState, CartTypes } from './types';

const INITIAL_STATE: CartState = {
  data: {
    produtos: [],
  },
}

const reducer: Reducer<CartState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CartTypes.ADD:
      return { ...state, data: action.payload.data };

    case CartTypes.DROP:
      return {...state, data: action.payload.data};
    
    default:
      return state;
  }
}

export default reducer;