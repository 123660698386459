import React, { useState } from 'react';
import './index.scss';

//Componentes
import Header from '../../components/header';
import Footer from '../../components/Footer';
import SideMenu from '../../components/SideMenu';
import Loading from '../../components/loading';
import CardFuncionarioProgramar from '../../components/CardFuncionarioProgramar';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import moment from 'moment';
import CardFuncionario from '../../components/CardFuncionario';

import Lottie from 'react-lottie';
//Animaceos Lottie
import animationData from '../../animations/loading.json';


//Calling bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
//Calling WOWjs
import WOW from 'wowjs';

//Redux
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../store';
import { User } from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import { Param } from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { Auth } from '../../store/ducks/auth/types';
import * as AuthActions from '../../store/ducks/auth/actions';
import * as CartActions from '../../store/ducks/cart/actions';
import Api2 from '../../services/api-v2';
import Api from '../../services/api';
import { Diversos } from '../../services/diversos';
import { convertToObject, createThis } from 'typescript';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

interface StateProps {
    user: User;
    param: Param;
}

interface DispatchProps {
    doLogin(user: User): void;
    setParam(param: Param): void;
}

interface OwnProps {

}

type Props = StateProps & DispatchProps & OwnProps;

class InfoServico extends React.Component<Props> {

    apiV2: any = null;
    api: any = null;
    state: any = {};

    constructor(props) {
        super(props);
        this.apiV2 = new Api2();
        this.api = new Api();
        const { id } = props.match.params;
        const { salao } = props.match.params;
        const { promo } = props.match.params;
        this.state = {
            loading: false,
            loadingHorarios: 1,
            loadingHandle: false,
            profissionais: [],

            promoDesconto: promo,
            valorDesconto: '',

            id: id,
            salao: salao,
            data: '',

            profissional: '',
            servico: id,

            horarios: '',
            hora: '',

        }
    }

    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();

        window.scrollTo(0, 0);

        this.getInfo()
        this.getProfissionais()

        if (this.props.user && this.props.user.codigo !== this.props.user.empresa) {

        } else {
            this.setState({
                profissionais: [
                    { CODIGO: this.props.user.nome, NOME: this.props.user.codigo },
                ],
                profissionaisPicker: [
                    { label: this.props.user.nome, value: this.props.user.codigo },
                ],
            });
        }

    }

    private async getInfo() {
        const self = this;

        let param = {
            codigo: this.state.id,
            salao: this.state.salao
        };

        try {
            const { data } = await self.api.post('/Servico/getById', param);

            if (data.status === false) {
                console.log('Nada localizado');
            } else {
                self.setState({ servico: data.msg });

                //Calcula valor do desconto na Promocao
                this.setState({ valorDesconto: data.msg.valor * this.state.promoDesconto / 100 })

            }
        } catch (e) {
            console.log(e);
        } finally {

        }
    }

    async getProfissionais() {
        const self = this;

        try {
            let param = {
                codigo: this.state.salao
            };

            const { data } = await self.apiV2.get(
                `/salon/${self.state.salao}`,
                param,
            );

            this.setState({ profissionais: data.msg.PROFISSIONAIS })

            if (data.status === false) {
                alert('erro ao buscar profissionais')
            } else {

            }
        } catch (e) {
            console.log(e);
        } finally {

        }
    }

    private async getHorarios() {
        const self = this;

        this.setState({ loadingHorarios: 2 })

        if (self.state.profissional == null || '' || false) {
            alert('Selessione um profissional')
        } else {
            let param = {
                salon: self.state.salao,
                professional: 0,
                service: this.state.servico.codigo,
                date: this.state.data
            };

            console.log(param)

            try {
                const { data } = await self.apiV2.post('/schedules/availability', param,
                );

                if (data.status == true) {
                    this.setState({ horarios: data.msg.agenda });
                    console.log(this.state.horarios)
                    this.setState({ loadingHorarios: 3 })
                }
            } catch (e) {
                console.log(e);
                this.setState({ loadingHorarios: 1 })
            } finally {
            }
        }
    }

    private async _handleSubmit() {
        const self = this;

        this.setState({ loadingHorarios: 2 })

        moment.locale('pt-BR');

        console.log(this.state.hora)

        let dataAgenda = moment(this.state.hora, "DD/MM/YYYY HH:mm");

        try {

            let param = {
                cliente: this.props.user.codigo,
                data: dataAgenda.format('YYYY-MM-DD'),
                hora: dataAgenda.format('HH:mm'),
                obs: this.state.detalhes,
                //VALOR DO DESCONTO JA CALCULADO NO STATE VALORDESCONTO
                desconto: 0.0,
                formapg: 1,
                cupom: '',
                salao: this.state.salao,
                itens: [{
                    servico: this.state.id,
                    qtd: 1,
                    valor: this.state.valorDesconto,
                    desconto: 0,
                }],
            }

            console.log(param)

            const { data } = await self.apiV2.post('/schedule', param);

            if (!data.status) {
                console.log(data.msg)
                alert('No se puede crear el calendario, verifique su conexión a Internet y vuelva a intentarlo');
            } else {
                alert('Agenda creada con éxito, seguir el estado de su pedido en las notificaciones')
                window.location.href = '/home-cliente'
            }
        } catch (e) {
            console.log(e);
            alert('No se puede crear el calendario, verifique su conexión a Internet y vuelva a intentarlo')
        } finally {
            this.setState({ loadingHorarios: 2 })
        }
    }

    render() {
        return (

            this.state.loading == true ?
                <Loading />
                :
                <>
                    <Header
                        registra={false}
                        login={false}
                        notificacoes={true}
                    />

                    <div className="salao-servicos">
                        <SideMenu />
                        <div className="container-home-usuario">
                            <div className="box-info-servico">
                                {
                                    this.state.profissionais.length >= 1 ?
                                        <>
                                            <h5 className="Tit-Page">Selecciona un profesional para tu servicio</h5>
                                            <div className="line-2">
                                                <i className="fas fa-users"></i>
                                                <p>Profesionales disponibles para este servicio</p>
                                            </div>
                                            <div className="container_funcionarios">
                                                {
                                                    this.state.profissionais.map(item => (
                                                        <div className="card-funcionario-serv">
                                                            <CardFuncionarioProgramar
                                                                setFuncionario={
                                                                    (props) => {
                                                                        this.setState({ profissional: props });
                                                                    }
                                                                }
                                                                codigo={item.CODIGO}
                                                                nome={item.NOME}
                                                                opacity={this.state.profissional == item.CODIGO ? true : false}
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </>
                                        :
                                        null
                                }
                                <div className="calendario">
                                    {/* <Calendar
                                        minDate={new Date()}
                                        value={this.state.data}
                                    /> */}
                                    <div style={{ display: 'flex', alignItems: 'center', width: '50%' }} className="wow fadeInUp">
                                        <div className="input-box" style={{ marginRight: 5 }}>
                                            <small>Seleccione el mejor día para su procedimiento*</small>
                                            <input
                                                type="date"
                                                value={this.state.data || ''}
                                                onChange={(event) => {
                                                    this.setState({ data: event.target.value }, () => this.getHorarios());
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="line-1">
                                    <h5>{this.state.servico.nome}</h5>
                                    <p className="valorAntigo" style={{ fontFamily: 'Arial' }}>$ {this.state.servico.salao_preco}</p>
                                    <p style={{ fontFamily: 'Arial' }}>$ {this.state.valorDesconto}</p>
                                    <span>{this.state.servico.categoria_nome}</span>
                                </div>
                                <span />
                                {
                                    this.state.loadingHorarios == 1 ?
                                        <div style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <h1>Seleccione una fecha</h1>
                                        </div>
                                        :
                                        this.state.loadingHorarios == 2 ?
                                            <div style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Lottie
                                                    options={defaultOptions}
                                                    height={350}
                                                    width={350}
                                                    isStopped={this.state.isStopped}
                                                    isPaused={this.state.isPaused}
                                                />
                                            </div>
                                            :
                                            this.state.loadingHorarios == 3 ?
                                                this.state.horarios.map(item => (
                                                    <div className="line-3">
                                                        <p style={{ marginLeft: 10 }}>{item.data.slice(item.data.length - 5)}</p>
                                                        <p style={{ marginLeft: 10 }}>Disponible</p>
                                                        <button
                                                            onClick={() => {
                                                                this.setState({ hora: item.data });
                                                                setTimeout(() => {
                                                                    this._handleSubmit();
                                                                }, 1000);
                                                            }
                                                            }
                                                            className="btn_primary"
                                                            style={{ height: '140%', marginLeft: 100 }}
                                                        >
                                                            <p>Reservar</p>
                                                        </button>
                                                    </div>
                                                ))
                                                :
                                                null
                                }
                            </div>
                        </div>
                    </div>

                    <Footer />
                </>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.data,
    param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InfoServico);