import React from "react";
import "./index.scss";

//Componentes
import Header from "../../components/header";
import Footer from "../../components/Footer";
import SideMenu from "../../components/SideMenu";
import Loading from "../../components/loading";
import LinhaServico from "../../components/LinhaServico";
import LinhaServicoPromo from "../../components/LinhaServicoPromo";
import GaleriaItem from "../../components/GaleriaItem";

//Icones Titulos
import ManosIco from "../../assets/images/manos-ico.png";
import PeluqueriaIco from "../../assets/images/peluqueria-ico.png";
import CuerpoIco from "../../assets/images/cuerpo-ico.png";
import RostroIco from "../../assets/images/rostro-ico.png";
import LegsIco from "../../assets/images/legs-ico.png";
import MassageIco from "../../assets/images/massage-ico.png";

//Calling bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
//Calling WOWjs
import WOW from "wowjs";

//Redux
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../../store";
import { User } from "../../store/ducks/user/types";
import * as UserActions from "../../store/ducks/user/actions";
import { Param } from "../../store/ducks/param/types";
import * as ParamActions from "../../store/ducks/param/actions";
import { Auth } from "../../store/ducks/auth/types";
import * as AuthActions from "../../store/ducks/auth/actions";
import { Cart } from "../../store/ducks/cart/types";
import * as CartActions from "../../store/ducks/cart/actions";
import Api2 from "../../services/api-v2";
import Api from "../../services/api";
import { Diversos } from "../../services/diversos";
import { couldStartTrivia } from "typescript";

import Lottie from "react-lottie";
//Animaceos Lottie
import animationData from "../../animations/loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface StateProps {
  user: User;
  param: Param;
  auth: Auth;
  cart: Cart;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  setAuth(auth: Auth): void;
  addCart(cart: Cart): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class Servicos extends React.Component<Props> {
  api2: any;
  api: any;
  state: any;

  constructor(props) {
    super(props);
    this.api2 = new Api2();
    this.api = new Api();
    const { id } = props.match.params;
    const { fotoCapa } = props.location.state;
    this.state = {
      idBusca: id,

      isLoading: true,
      salao: null,
      servicos: [],
      limit: 0,
      modalMapVisible: false,
      mapsLat: 0,
      mapsLng: 0,
      mapsLatDelta: 0,
      mapsLngDelta: 0,
      mapsAddress: "",
      isLoadingFotos: true,
      loadingMsgFotos: "",
      limitFotos: 5,
      foto: !fotoCapa
        ? require("../../assets/images/salao-fundo.jpg")
        : fotoCapa,
      type: 2,

      //Promocoes
      loadingPromos: false,
      promos: "",

      //Box de cada item
      boxRostro: false,
      boxCuerpo: false,
      boxNanos: false,
      boxMassajes: false,
      boxPeluqueria: false,
      boxDepilacion: false,

      //galeria
      galeria: "",
      galeriaLoading: true,
      galeriaScreen: false,

      //favorito
      favorito: "",
    };
  }

  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();

    window.scrollTo(0, 0);

    this.getById();
    this.getFotos();
    this.getPromos();
  }

  private async getById() {
    const self = this;

    self.setState({ isLoading: true }, () =>
      this.props.setParam({ ...this.props.param, isLoading: true })
    );

    try {
      const { data } = await self.api2.get("/salon/" + this.state.idBusca);

      if (data.status == false) {
        console.log("no és posible buscar las informaciones");
      }
      await self.setState({ salao: data.msg });
      await self.setState({ servicos: this.state.salao.SERVICOS });

      // Verifica a categoria para exibir o box------
      this.state.servicos.map((item) =>
        item.servicoDados.CATEGORIA == 1
          ? this.setState({ boxRostro: true })
          : item.servicoDados.CATEGORIA == 2
          ? this.setState({ boxCuerpo: true })
          : item.servicoDados.CATEGORIA == 3
          ? this.setState({ boxManos: true })
          : item.servicoDados.CATEGORIA == 4
          ? this.setState({ boxMassajes: true })
          : item.servicoDados.CATEGORIA == 5
          ? this.setState({ boxPeluqueria: true })
          : item.servicoDados.CATEGORIA == 6
          ? this.setState({ boxDepilacion: true })
          : null
      );
    } catch (e) {
      console.error(e);
    } finally {
      self.setState({ isLoading: false }, () =>
        this.props.setParam({ ...this.props.param, isLoading: false })
      );
    }
  }

  private async getFotos() {
    const self = this;

    this.setState({ galeriaLoading: true });

    let param = {
      limit: this.state.limit,
      codigo: this.state.idBusca,
      perfil: 2,
      tipo: "galeria",
    };

    try {
      const { data } = await self.api.post("/User/galeria", param);

      if (data.status == false) {
        console.log("No se pudieron recuperar las fotos: ");
        this.setState({ galeriaLoading: false });
      } else {
        this.setState({ galeria: data.msg });
      }
    } catch (e) {
      console.log("No se pudieron recuperar las fotos: ", e);
    } finally {
      this.setState({ galeriaLoading: false });
    }
  }

  private async getPromos() {
    const self = this;

    this.setState({ loadingPromos: true });

    try {
      const { data } = await self.api2.get(
        `/salon/${this.state.idBusca}/service/0/promotions`
      );

      if (data.status == false) {
        console.log("No se pudieron recuperar las promociones");
        this.setState({ promos: false });
      } else {
        this.setState({ promos: data.msg.data });
      }
    } catch (e) {
      console.log("No se pudieron recuperar las promociones", e);
    } finally {
      this.setState({ loadingPromos: false });
    }
  }

  private _handleFavorito() {
    let favoritos: any = null;

    if (typeof this.props.param.favoritos === "undefined" || null) {
      favoritos = [];
    } else {
      favoritos = this.props.param.favoritos;
    }

    let index = favoritos.indexOf(this.state.idBusca);

    if (index < 0) {
      favoritos.push(this.state.idBusca);
    } else {
      favoritos.splice(index, 1);
    }
    this.props.setParam({ ...this.props.param, favoritos: favoritos });
  }

  render() {
    return this.state.isLoading == true || !this.state.salao ? (
      <Loading />
    ) : (
      <>
        <Header registra={false} login={false} notificacoes={true} />
        <div className="salao-servicos">
          <SideMenu />
          <div className="container-home-usuario">
            <div className="box-servicos">
              <div
                className="capa-salao"
                style={{ backgroundImage: 'url("' + this.state.foto + '")' }}
              >
                <div className="filter">
                  <div className="container-nome-salao">
                    <div className="box-nome">
                      <h5>{this.state.salao.NOME}</h5>
                      <p>
                        {!this.state.salao.ENDE
                          ? null
                          : this.state.salao.ENDE +
                            ", " +
                            this.state.salao.CIDA}
                      </p>
                    </div>

                    {!this.props.param.favoritos ||
                    this.props.param.favoritos.indexOf(this.state.idBusca) <=
                      -1 ? (
                      <i
                        className="far fa-heart"
                        onClick={() => this._handleFavorito()}
                        style={{ cursor: "pointer" }}
                      ></i>
                    ) : (
                      <i
                        className="fas fa-heart"
                        onClick={() => this._handleFavorito()}
                        style={{ cursor: "pointer" }}
                      ></i>
                    )}
                  </div>
                </div>
              </div>
              {/* GALERIA DE FOTOS---------------------- */}
              {this.state.galeriaLoading ? (
                <section className="container-galeria">
                  <Lottie
                    options={defaultOptions}
                    height={200}
                    width={200}
                    isStopped={this.state.isStopped}
                    isPaused={this.state.isPaused}
                  />
                </section>
              ) : this.state.galeria ? (
                <section className="container-galeria">
                  {this.state.galeria.map((item) => (
                    <GaleriaItem fotosDados={item.link} />
                  ))}
                </section>
              ) : null}

              {!this.state.salao.ENDE ? null : (
                <div className="container-info-salao">
                  <div className="tit-box-salao">
                    <i className="fas fa-map-marker-alt"></i>
                    <h5>Ver Ubicación</h5>
                  </div>
                  <h5 className="title">{this.state.salao.NOME}</h5>
                  <p>
                    {this.state.salao.ENDE +
                      ", " +
                      this.state.salao.CIDA +
                      ", " +
                      this.state.salao.ESTA}
                  </p>
                </div>
              )}

              {this.state.loadingPromos ? (
                <div className="container-info-salao">
                  <Lottie
                    options={defaultOptions}
                    height={200}
                    width={200}
                    isStopped={this.state.isStopped}
                    isPaused={this.state.isPaused}
                  />
                </div>
              ) : this.state.promos.length <= 0 ? null : (
                <div className="container-info-salao">
                  <div className="tit-box-salao">
                    <i className="fa fa-star"></i>
                    <h5>Promociones</h5>
                  </div>
                  {this.state.promos.map((item) =>
                    !item.servicoSalaoDados ? null : (
                      <LinhaServicoPromo
                        nome={
                          !item.servicoSalaoDados
                            ? "nada"
                            : item.servicoSalaoDados.servicoDados.SERVICO
                        }
                        promo={item.preco}
                        valor={item.servicoSalaoDados.preco}
                        tempo={item.servicoSalaoDados.tempo}
                        id={item.servicoSalaoDados.servicoDados.CODIGO}
                        Salao={item.salaoDados.CODIGO}
                        idPromo={item.id}
                      />
                    )
                  )}
                </div>
              )}

              {this.state.boxRostro == true ? (
                <div className="container-info-salao">
                  <div className="tit-box-salao">
                    <img src={RostroIco} className="ico-tit" />
                    <h5>Rostro</h5>
                  </div>
                  {this.state.servicos.map((item) =>
                    item.servicoDados.CATEGORIA == 1 ? (
                      <LinhaServico
                        nome={item.servicoDados.SERVICO}
                        valor={item.servicoDados.VALOR}
                        tempo={item.servicoDados.TEMPO + " Min"}
                        id={item.servicoDados.CODIGO}
                        Salao={this.state.idBusca}
                      />
                    ) : null
                  )}
                </div>
              ) : null}
              {this.state.boxCuerpo == true ? (
                <div className="container-info-salao">
                  <div className="tit-box-salao">
                    <img src={CuerpoIco} className="ico-tit" />
                    <h5>Cuerpo</h5>
                  </div>
                  {this.state.servicos.map((item) =>
                    item.servicoDados.CATEGORIA == 2 ? (
                      <LinhaServico
                        nome={item.servicoDados.SERVICO}
                        valor={item.servicoDados.VALOR}
                        tempo={item.servicoDados.TEMPO + " Min"}
                        id={item.id}
                        Salao={this.state.idBusca}
                      />
                    ) : null
                  )}
                </div>
              ) : null}
              {this.state.boxManos == true ? (
                <div className="container-info-salao">
                  <div className="tit-box-salao">
                    <img src={ManosIco} className="ico-tit" />
                    <h5>Manos</h5>
                  </div>
                  {this.state.servicos.map((item) =>
                    item.servicoDados.CATEGORIA == 3 ? (
                      <LinhaServico
                        nome={item.servicoDados.SERVICO}
                        valor={item.servicoDados.VALOR}
                        tempo={item.servicoDados.TEMPO + " Min"}
                        id={item.id}
                        Salao={this.state.idBusca}
                      />
                    ) : null
                  )}
                </div>
              ) : null}
              {this.state.boxMassajes == true ? (
                <div className="container-info-salao">
                  <div className="tit-box-salao">
                    <img src={MassageIco} className="ico-tit" />
                    <h5>Massajes</h5>
                  </div>
                  {this.state.servicos.map((item) =>
                    item.servicoDados.CATEGORIA == 4 ? (
                      <LinhaServico
                        nome={item.servicoDados.SERVICO}
                        valor={item.servicoDados.VALOR}
                        tempo={item.servicoDados.TEMPO + " Min"}
                        id={item.id}
                        Salao={this.state.idBusca}
                      />
                    ) : null
                  )}
                </div>
              ) : null}
              {this.state.boxPeluqueria == true ? (
                <div className="container-info-salao">
                  <div className="tit-box-salao">
                    <img src={PeluqueriaIco} className="ico-tit" />
                    <h5>Peluqueria</h5>
                  </div>
                  {this.state.servicos.map((item) =>
                    item.servicoDados.CATEGORIA == 5 ? (
                      <LinhaServico
                        nome={item.servicoDados.SERVICO}
                        valor={item.servicoDados.VALOR}
                        tempo={item.servicoDados.TEMPO + " Min"}
                        id={item.id}
                        Salao={this.state.idBusca}
                      />
                    ) : null
                  )}
                </div>
              ) : null}

              {this.state.boxDepilacion == true ? (
                <div className="container-info-salao">
                  <div className="tit-box-salao">
                    <img src={LegsIco} className="ico-tit" />
                    <h5>Depilación</h5>
                  </div>
                  {this.state.servicos.map((item) =>
                    item.servicoDados.CATEGORIA == 6 ? (
                      <LinhaServico
                        nome={item.servicoDados.SERVICO}
                        valor={item.servicoDados.VALOR}
                        tempo={item.servicoDados.TEMPO + " Min"}
                        id={item.id}
                        Salao={this.state.idBusca}
                      />
                    ) : null
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Servicos);
