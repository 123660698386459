import React from 'react';
import './index.scss';

import CardNotificacaoCliente from '../CardNotificacaoCliente';

//Redux
import ApiV2 from '../../services/api-v2';
import { Diversos } from '../../services/diversos';
import { ApplicationState } from '../../store';
import { User } from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import { Param } from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Auth } from '../../store/ducks/auth/types';

import Lottie from 'react-lottie';
//Animaceos Lottie
import animationData from '../../animations/loading.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

interface StateProps {
    user: User;
    param: Param;
}

interface DispatchProps {
    doLogin(user: User): void;
    setParam(param: Param): void;
    setParam(param: Param): void;
}

interface OwnProps {
    display: boolean,
}

type Props = StateProps & DispatchProps & OwnProps;

export class ContainerNotificacoes extends React.Component<Props> {

    state: any = null;
    api: any;
    apiv2: any;
    userRef: any;

    constructor(props) {
        super(props);
        this.apiv2 = new ApiV2();
        this.state = {
            Loading: true,
            isLoadingMore: false,
            isRefreshing: true,
            regs: [],
            total: 1,
            page: 1,
            perPage: 10,
            lastPage: 2,
        }
    }

    componentDidMount() {
        this.getRegs();

        setInterval(() => {
            this.getRegs();
        }, 30000);
    }

    private async getRegs() {
        const self = this;

        try {
            const { data } = await self.apiv2.get(
                `/notifications?page=${this.state.page}&per_page=${this.state.perPage}&filter=destinatario:${this.props.user.codigo}|tipo:cliente`,
            );
            if (!data.status) {
                console.log('No se puede cargar la información, intente nuevamente')
            }
            await self.setState({
                regs:
                    data.msg.page > 1
                        ? [...self.state.regs, ...data.msg.data]
                        : data.msg.data,
                total: data.msg.total,
                page: data.msg.page,
                perPage: data.msg.perPage,
                lastPage: data.msg.lastPage,
            });


        } catch (e) {
            console.error(e);
            console.log('No se puede cargar la información, intente nuevamente')
        } finally {
            this.setState({ Loading: false })
        }
    }

    render() {
        return (

            this.props.display == true ?
                <section className="container-notificacoes">
                    <div className="header-notificacoes">
                        <p>Notificaciones</p>
                    </div>
                    {
                        this.state.Loading ?
                            <div className="loading-box">
                                <Lottie
                                    options={defaultOptions}
                                    height={350}
                                    width={350}
                                    isStopped={this.state.isStopped}
                                    isPaused={this.state.isPaused}
                                />
                            </div>
                            :
                            this.state.regs.lenght <= 0 ?
                                <div className="box-sem-notificacoes">
                                    <p>No hay notificaciones por aquí.</p>
                                </div>
                                :
                                <div className="notificacoes-area">
                                    {
                                        this.state.regs.map(item => (
                                            <CardNotificacaoCliente
                                                titulo={item.titulo}
                                                texto={item.mensagem}
                                                lido={item.lido}
                                                criado={item.criado}
                                                id={item.id}
                                            />
                                        ))
                                    }
                                </div>
                    }

                </section>
                :
                null
        )
    }
};


const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.data,
    param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContainerNotificacoes);

