import React from "react";
import swal from "sweetalert";
import "./index.scss";

//Componentes
import Logo from "../../assets/images/logo-vetor-hd.svg";
import LoginImg from "../../assets/images/login-img.svg";

import MascaraMobileCliente from "../../components/MascaraMobileCliente";

//Calling bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
//Calling WOWjs
import WOW from "wowjs";

//Redux
import Api from "../../services/api";
import ApiV2 from "../../services/api-v2";
import { Diversos } from "../../services/diversos";
import { ApplicationState } from "../../store";
import { User } from "../../store/ducks/user/types";
import * as UserActions from "../../store/ducks/user/actions";
import { Param } from "../../store/ducks/param/types";
import * as ParamActions from "../../store/ducks/param/actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Auth } from "../../store/ducks/auth/types";

import Lottie from "react-lottie";
//Animaceos Lottie
import animationData from "../../animations/loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface StateProps {
  user: User;
  param: Param;
  auth: Auth;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  setAuth(auth: Auth): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class Login extends React.Component<Props> {
  api: any;
  apiv2: any;
  state: any;
  userRef: any;
  passwordRef: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.apiv2 = new ApiV2();
    this.userRef = React.createRef();
    this.passwordRef = React.createRef();
    this.state = {
      isLoading: false,
      showPassword: false,
      email: "",
      senha: "",
    };
  }

  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();

    const self = this;

    if (
      self.props.user &&
      self.props.user.status === true &&
      !self.props.user.fgFuncionario
    ) {
      // SE USUARIO ESTIVER LOGADO REDIRECIONA PARA OUTRA PAGINA
      window.location.href = "/home-cliente";
    } else if (
      self.props.user &&
      self.props.user.status === true &&
      self.props.user.fgFuncionario
    ) {
      window.location.href = "/home-salon";
    }
  }

  private async login() {
    const self = this;

    if (!self.state.email || !self.state.senha) {
      swal("¡Atención!", "Formulario incompleto", "error");
      return false;
    }

    self.setState({ isLoading: true }, () =>
      this.props.setParam({ ...this.props.param, isLoading: true })
    );

    let param = {
      email: this.state.email,
      senha: this.state.senha,
    };

    try {
      const { data } = await self.api.post("/User/login", param);

      if (data.status === false) {
        alert(
          "No se puede iniciar sesión, verifique los datos y vuelva a intentarlo"
        );
        this.setState({ isLoading: false });
      } else {
        await self.props.doLogin({
          codigo: data.msg.codigo,
          nome: data.msg.nome,
          email: data.msg.email,
          cpf: data.msg.cnpj,
          status: true,
          fgFuncionario: false,
          avatar:
            data.avatar !== "" && data.avatar !== "undefined"
              ? data.avatar
              : null,
          empresa: 0,
        });

        window.location.href = "/home-cliente";
      }
    } catch (e) {
      console.log(e);
      swal("¡Atención!", "Inicio de sesión incorrecto", "error");
    } finally {
      self.setState({ isLoadingPromocao: false }, () =>
        this.props.setParam({ ...this.props.param, isLoading: false })
      );
    }
  }

  render() {
    return (
      <>
        <MascaraMobileCliente />
        <button className="voltar-btn" onClick={() => window.history.back()}>
          <i className="fas fa-chevron-left"></i>
          <p>Volver</p>
        </button>
        <div className="login-screen">
          <div className="row">
            <div className="box-1-ico">
              {this.state.isLoading == true ? (
                <Lottie
                  options={defaultOptions}
                  height={350}
                  width={350}
                  isStopped={this.state.isStopped}
                  isPaused={this.state.isPaused}
                />
              ) : (
                <img src={LoginImg} className="pic wow fadeInLeft" />
              )}
            </div>

            <div className={"box-2-dados"}>
              <div className="box-login wow fadeInRight">
                <img
                  src={Logo}
                  className="logo"
                  alt="Logo Turnau"
                  title="Logo Turnau"
                  onClick={() => (window.location.href = "/")}
                />
                <h4>Ingresar</h4>
                <div className="input-box">
                  <small>Correo electrónico*</small>
                  <input
                    type="email"
                    value={this.state.email || ""}
                    onChange={(event) =>
                      this.setState({ email: event.target.value })
                    }
                  />
                </div>
                <div className="input-box">
                  <small>Contraseña*</small>
                  <input
                    type="password"
                    value={this.state.senha || ""}
                    onChange={(event) =>
                      this.setState({ senha: event.target.value })
                    }
                  />
                </div>
                <button className="btn_primary" onClick={this.login.bind(this)}>
                  <i className="fas fa-sign-in-alt"></i>
                  <p>Ingresar</p>
                </button>
                <button
                  className="btn_secondary"
                  style={{ border: "solid 1px" }}
                  onClick={() => (window.location.href = "/registrate-cliente")}
                >
                  <i className="fas fa-user-plus"></i>
                  <p>Regístrate</p>
                </button>
                <button
                  style={{ border: "none", backgroundColor: "transparent" }}
                  onClick={() =>
                    (window.location.href = "/recuperar-contrasena-cliente")
                  }
                >
                  <h5>Recuperar contraseña?</h5>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
