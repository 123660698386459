import React from 'react';
import './index.scss';

//Redux
import Api from '../../services/api';
import ApiV2 from '../../services/api-v2';
import { Diversos } from '../../services/diversos';
import { ApplicationState } from '../../store';
import { User } from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import { Param } from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Auth } from '../../store/ducks/auth/types';


interface StateProps {
    user: User;
    param: Param;
}

interface DispatchProps {
    doLogin(user: User): void;
    setParam(param: Param): void;
    setParam(param: Param): void;
}

interface OwnProps {
    codigo: String,
    nome: String,
    opacity: boolean,
    setFuncionario: Function,
}

type Props = StateProps & DispatchProps & OwnProps;

export class CardFuncionarioHome extends React.Component<Props> {


    state: any = null;
    api: any;
    apiv2: any;
    userRef: any;

    constructor(props) {
        super(props);
        this.api = new Api();
        this.apiv2 = new ApiV2();
        this.state = {
            fotoFuncionairo: require('../../assets/images/user.png'),
            select: false,
            avatar: ''
        }
    }

    componentDidMount() {
        this.getFotoProfissional()
    }

    async getFotoProfissional() {
        const self = this;

        try {
            let param = {
                codigo: this.props.codigo,
                perfil: 3
            };

            const { data } = await self.api.post(
                `/User/GetById`, param
            );

            this.setState({ avatar: data.msg.avatar })


            if (data.status === false) {
                console.log('erro ao buscar fotos dos profissionais')
                this.setState({ avatar: require('../../assets/images/user.png') })
            } else {

            }
        } catch (e) {
            console.log(e);
        } finally {

        }
    }

    render() {
        return (
            <>
                <section className={this.props.opacity ? 'card-funcionario-serv' : 'card-funcionario-serv inativo'}
                    onClick={() => {
                        this.props.setFuncionario(this.props.codigo);
                    }
                    }
                >
                    <div className="foto-fun" style={{ backgroundImage: `url(${this.state.avatar})` }} />
                    <p>{this.props.nome}</p>
                </section>
            </>
        )
    }
};

const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.data,
    param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CardFuncionarioHome);