import React from 'react';
import './index.scss';
import { Link } from 'react-router-dom'
import ReactStars from "react-rating-stars-component";

const ratingChanged = (newRating) => {
    console.log(newRating);
};

interface Props {
    nome: String,
    distancia: String,
    procedimento: String,
    local: String,
    img: any,
    id: String,
    idServico: String,
}

export class CardSalao extends React.Component<Props> {

    state: any = null;

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <Link
                className="card-salao"
                to={{
                    pathname: `/servicio/programar/${this.props.idServico}/${this.props.id}`
                }}>
                <div className="foto-salao">
                    {
                        this.props.img == null || false ?
                            <p>sem foto</p>
                            :
                            <img src={this.props.img} />
                    }
                </div>
                <div className="layer-1">
                    <p>{this.props.nome}</p>
                    <p>{this.props.distancia} Km</p>
                </div>
                <h5>{this.props.procedimento}</h5>
                <div className="layer-2">
                    <div className="stars">
                        <ReactStars
                            count={5}
                            value={4.5}
                            onChange={ratingChanged}
                            size={16}
                            activeColor="#6851FB"
                        />,
                    </div>
                    <div className="local">
                        {
                            this.props.local ?
                                <>
                                    <i className="fas fa-map-marker-alt"></i>
                                    <p>{this.props.local}</p>
                                </>
                                :
                                null
                        }
                    </div>
                </div>
                <button className="btn_primary qtd">
                    <p>Ver más</p>
                </button>
            </Link>
        )
    }
};
export default CardSalao;

