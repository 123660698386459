import { Reducer } from 'redux';
import { CategoriaState, CategoriaTypes } from './types';

const INITIAL_STATE: CategoriaState = {
  data: {
    items: [],
  },
} 

const reducer: Reducer<CategoriaState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CategoriaTypes.ADD:
      let {data} = state;
      data.items.push(action.payload.data);
      return { ...state, data: data };

    case CategoriaTypes.RESET:
      return { ...state, data: {items: []} };
    
    default:
      return state;
  }
}

export default reducer;