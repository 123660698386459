import React from "react";
import "./index.scss";
import moment from "moment";
import Header from "../../../components/header";
import Footer from "../../../components/Footer";
import Cartao from "../../../components/CardCartao";
import "bootstrap/dist/css/bootstrap.min.css";
import WOW from "wowjs";
import Api from "../../../services/api";
import ApiV2 from "../../../services/api-v2";
import { Diversos } from "../../../services/diversos";
import { ApplicationState } from "../../../store";
import { User } from "../../../store/ducks/user/types";
import * as UserActions from "../../../store/ducks/user/actions";
import { Param } from "../../../store/ducks/param/types";
import * as ParamActions from "../../../store/ducks/param/actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import Lottie from "react-lottie";
import animationData from "../../../animations/loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  setParam(param: Param): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class HorariosDetalhe extends React.Component<Props> {
  state: any = null;
  api: any;
  apiv2: any;

  constructor(props) {
    super(props);
    this.apiv2 = new ApiV2();
    this.api = new Api();

    const id =
      props.match || props.match.params || props.match.params.id
        ? props.match.params.id
        : 0;

    this.state = {
      reg: null,
      isLoading: false,
      id,
    };
  }

  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();

    window.scrollTo(0, 0);

    if (this.state.id) {
      this.get();
    }
  }

  private async get() {
    const self = this;

    self.setState({ isLoading: true });

    try {
      const { data } = await self.apiv2.get(`/schedule/${self.state.id}`);

      if (!data.status) {
        throw new Error(data.msg);
      }

      self.setState({ reg: data.msg });
    } catch (e) {
      console.log("erro" + e);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <>
        <Header registra={false} login={false} notificacoes={true} />
        <section className="container-horarios">
          <div className="row-horarios">
            <div className="header-horarios">
              <i className="far fa-clock"></i>
              <h5>Mi horario</h5>
            </div>

            {this.state.isLoading ? (
              <div
                style={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Lottie
                  options={defaultOptions}
                  height={350}
                  width={350}
                  isStopped={this.state.isStopped}
                  isPaused={this.state.isPaused}
                />
              </div>
            ) : !this.state.reg ? (
              <div className="alert-card wow fadeInUp">
                <h5>No hay horario registrado.</h5>
              </div>
            ) : (
              <form className="form">
                <div className="form-group mb-2">
                  <label>Recibo</label>
                  <input
                    className="form-control"
                    name="pedido"
                    value={this.state.reg[0].PEDIDO}
                    disabled
                  />
                </div>

                <div className="form-group mb-2">
                  <label>Fecha de compra</label>
                  <input
                    className="form-control"
                    name="datacriado"
                    value={this.state.reg[0].DATACRIADO}
                    disabled
                  />
                </div>

                <div className="form-group mb-2">
                  <label>Hora programada</label>
                  <input
                    className="form-control"
                    name="horario"
                    value={`${this.state.reg[0].DATA} ${this.state.reg[0].HORA}`}
                    disabled
                  />
                </div>

                <div className="form-group mb-2">
                  <div className="d-flex flex-row my-3">
                    <i className="fas fa-cut"></i>
                    <p>Datos de servicios</p>
                  </div>
                </div>

                {this.state.reg.map((row, index) => (
                  <div className="d-flex flex-row align-items-center justify-content-center">
                    <div
                      className="form-group mb-2"
                      style={{ flex: 1, marginRight: 15 }}
                    >
                      <label>Servicio</label>
                      <input
                        className="form-control"
                        name="Servicio"
                        value={row.SERVICO_NOME}
                        disabled
                      />
                    </div>
                    <div className="form-group mb-2" style={{ flex: 1 }}>
                      <label>Precio</label>
                      <input
                        className="form-control"
                        name="Precio"
                        value={`$ ${Diversos.maskPreco(
                          row.ADMPED2_VALOR.toFixed(2)
                        )}`}
                        disabled
                      />
                    </div>
                  </div>
                ))}

                <div className="form-group mb-2">
                  <div className="d-flex flex-row my-3">
                    <i className="fas fa-credit-card"></i>
                    <p>Detalhes del pago</p>
                  </div>
                </div>

                <div className="form-group mb-2">
                  <label>Forma de pago</label>
                  <input
                    className="form-control"
                    name="Forma de pago"
                    value={`${this.state.reg[0].FORMAPG_NOME}`}
                    disabled
                  />
                </div>

                <div className="form-group mb-2">
                  <div className="d-flex flex-row my-3">
                    <i className="fas fa-comments"></i>
                    <p>Comentários</p>
                  </div>
                </div>

                <div className="form-group mb-2">
                  <label>Comentario</label>
                  <input
                    className="form-control"
                    name="Comentario"
                    value={`${this.state.reg[0].OBS || ""}`}
                    disabled
                  />
                </div>

                <div className="form-group mb-2">
                  <div className="d-flex flex-row my-3">
                    <i className="fas fa-tag"></i>
                    <p>Status</p>
                  </div>
                </div>

                <div className="form-group mb-2">
                  <label>Status</label>
                  <input
                    className="form-control"
                    name="Status"
                    value={`${Diversos.getTituloStatus(
                      this.state.reg[0].STATUS
                    )}`}
                    disabled
                  />
                </div>
              </form>
            )}
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HorariosDetalhe);
