import React from 'react';
import './index.scss';

import Header from '../../components/header';
import Footer from '../../components/Footer';


interface Props {
}

class Politica extends React.Component<Props> {

  state: any;

  constructor(props) {
    super(props);
    this.state = {

    }
  }


  render() {
    return (
      <>
        <Header 
          registra={false}
          login={true}
          notificacoes={true}
        />
        <div className="container-politica">
          <div className="box-politica">
            <div className="header-box">

            </div>
            <div className="box-content">
              <h5>CLÁUSULA DE CONFIDENCIALIDAD</h5>
              <h4>General</h4>
              <p>TURNAU respeta la confidencialidad de todas las personas que entran en nuestras páginas web o app y que responden a nuestros anuncios interactivos o demás comunicaciones. Esta Política de Privacidad describe la información que TURNAU como responsable de esta página, recabará y cómo utilizará dicha información. Esta cláusula también le instruye a Ud. como usuario, sobre qué hacer si no desea que TURNAU recoja sus datos personales o los comunique a terceros cuando visite las páginas web de TURNAU o responda a sus anuncios interactivos y demás comunicaciones.</p>

              <p>TURNAU puede modificar esta Cláusula de Confidencialidad para nuevas recogidas de datos de carácter personal que se produzcan tras tal modificación, y usted debería por tanto consultar periódicamente esta página para comprobar que está de acuerdo con las modificaciones que se hayan podido introducir en nuestra cláusula de Confidencialidad, antes de proporcionar nuevamente datos de carácter personal.</p>

              <p>Datos de carácter personal
                TURNAU no recabará ningún dato de carácter personal suyo que le pueda identificar (tales como nombre, dirección, número de teléfono o dirección de e-mail) si usted no los aporta voluntariamente, salvo únicamente por lo dispuesto más abajo en relación con la recogida automática de información y las «Cookies».

              </p>

              <p>Cuando nos envíe datos de carácter personal le podremos preguntar si desea recibir futuras comunicaciones nuestras, como, por ejemplo, boletines informativos, e-mails u otras comunicaciones. Puede optar por no recibir estas comunicaciones, si así lo desea.
              </p>

              <p>Si no desea que recabemos sus datos de carácter personal, por favor no nos los envíe. Si ya nos los ha enviado, y le gustaría que los eliminásemos de nuestros archivos, contacte con nosotros en las direcciones especificadas en la cláusula titulada «Con quien contactar» al final de esta Política de Privacidad.
              </p>

              <p>Los datos de carácter personal se podrán utilizar para ofrecerle servicios y/o informaciones que solicite, así como para la llevanza y control de la promoción, concurso o actividad en cuyo marco se recaben. Asimismo, dichos datos se podrán utilizar, además de para ofrecerle el servicio o la información que nos haya solicitado, para ayudarnos a conocer cómo usa nuestra página web y nuestros productos.  También puede que combinemos los datos que usted nos proporciona con los datos que recabamos de otras fuentes, incluyendo datos que usted haya proporcionado a otras organizaciones (siempre y cuando concurra el consentimiento que habilite que sean compartidos) y con datos que procedan de fuentes públicas. Toda esta información nos servirá, por ejemplo, para diseñar mejor nuestros productos, páginas web y anuncios. Con el fin de conseguir esto puede que compartamos sus datos con compañías que trabajan para nosotros. Estas compañías habrán aceptado utilizar los datos sólo en la forma que nosotros les digamos, y contar con procedimientos que garanticen la protección de esa información. Si deseamos compartir sus datos con otras compañías de forma diferente a la establecida más arriba o a la prevista a continuación, antes nos pondremos en contacto con usted para ofrecerle la posibilidad de suprimir su información personal de nuestra base de datos, a menos que ya nos haya dado su consentimiento para dicha comunicación de datos.
              </p>

              <h4>Menores</h4>

              <p>TURNAU no tiene intención alguna de recabar datos de carácter personal (tales como el nombre, dirección, número de teléfono o dirección de e-mail) de personas menores de catorce años. Allí donde sea apropiado, TURNAU indicará expresamente a los menores de 14 años que no deben enviar sus datos. Si un menor de edad nos ha enviado datos de carácter personal, un padre o tutor legal del niño deberá contactar con nosotros en las direcciones especificadas en la cláusula titulada «Con quien contactar» al final de esta Política de Privacidad, si desea que borremos esos datos personales de nuestros archivos.</p>

              <p> Información recogida de manera disociada de personas
                En algunos casos, podemos obtener información que no le identifica a usted personalmente. Algunos ejemplos podrían ser el tipo de buscador de Internet que utiliza, o el tipo de sistema operativo que tiene su ordenador, o nombre del dominio de la página desde la que realizó el link con nuestra página web o nuestro anuncio. Utilizamos esta información para comprender mejor qué les interesa a nuestros clientes y para mejorar nuestro negocio y nuestras páginas web.
              </p>

              <p>Información que podemos colocar automáticamente en el disco duro de su ordenador
                Cuando entra en una de nuestras páginas web o anuncios podríamos guardar alguna información en su ordenador. Por ejemplo, podría ser una cookie o un archivo similar que nos permita personalizar una página web o un anuncio para que se adapte mejor a lo que a usted le interesa o prefiere.  La mayoría de los buscadores de Internet permiten borrar cookies del disco duro, bloquearlas todas, o recibir un mensaje cada vez que guarda una cookie. Consulte las instrucciones o la sección de Ayuda de su buscador si desea más información sobre estas funciones.
              </p>

              <p>Tenga en cuenta que eliminar o bloquear cookies puede interferir con el pleno uso de nuestras páginas web</p>

              <p>Con quién contactar
                Si ha enviado datos de carácter personal a una página web, anuncio interactivo u otra comunicación de TURNAU y desea ejercitar sus derechos de acceso, cancelación, rectificación u oposición; así como para cualquier cuestión relativa al tratamiento de sus datos de carácter personal, por favor contacte con nosotros a través del apartado Contáctanos
              </p>

              <h5>CONDICIONES LEGALES</h5>

              <p>Términos y condiciones legales</p>

              <h4>IMPORTANTE: NOTA LEGAL</h4>

              <p>Esta Página World Wide Web está operada por TURNAU.
                Puede contactar con nosotros a través del apartado Contáctanos.
              </p>

              <p>ATENCIÓN: El acceso y la utilización de esta Página web están sujetos a los siguientes Términos y Condiciones legales, y a lo establecido por todas las leyes que le sean aplicables. El visionado de esta Página implica que acepta las condiciones; si no acepta las condiciones, no visione su contenido. Nuestras páginas pueden contener condiciones y términos particulares aplicables al uso de dichas páginas. En caso de conflicto entre estos Términos y Condiciones y las condiciones y términos particulares de cualquiera de dichas páginas, entonces prevalecerán éstas sobre aquellos.
              </p>

              <p>TURNAU   se reserva el derecho a cambiar estos términos y condiciones legales, lo que tendrá efectos para cualquier utilización que se realice a partir del tercer día de tal modificación y usted debería consultar periódicamente esta Página para comprobar las condiciones a las que Ud. está sujeto en cada momento en que utilice la Página.
              </p>

              <p>Puede navegar por esta Página web por diversión personal o para buscar información. Puede descargarse en un ordenador o imprimir cualquier material de la Página únicamente para su propio uso no comercial, educativo, privado o doméstico. Sin embargo, no podrá reproducir, copiar, distribuir, modificar, transmitir, volver a publicar o revisar los contenidos de esta Página web sin permiso por escrito de TURNAU. No puede dirigir ningún vínculo a la Página desde la página de una tercera parte sin nuestra autorización previa y por escrito.</p>

              <p>Salvo que se especifique lo contrario, los derechos de autor y otros derechos de propiedad intelectual que recaigan sobre cualesquiera materiales incluidos en la Página (incluyendo a título meramente enunciativo y no limitativo fotografías e imágenes gráficas) son propiedad de TURNAU o sus licenciantes. No se transferirán a terceros ni la titularidad ni la propiedad intelectual por el uso o el acceso a esta Página web. Al contrario, todos los derechos, títulos e intereses en parte o en la totalidad de los aspectos de esta Página web serán propiedad exclusiva de TURNAU o de sus licenciantes. Las Páginas de TURNAU están protegidas por derecho de autor y otras leyes aplicables. Si infringe los términos de este aviso legal o de cualquiera de las otras páginas de TURNAU, su autorización para usar esta Página y dichas páginas quedará automáticamente revocada. Quedan reservados cualesquiera derechos no concedidos expresamente por la presente.</p>

              <p>Si quiere comunicarse con TURNAU   en relación con cualquier asunto legal, por favor contacte con la compañía local del grupo TURNAU. Nuestra página corporativa localizable en depimiel.com contiene una lista de todas nuestras compañías locales.
              </p>

              <p>Los materiales de esta página se facilitan «a cuerpo cierto», sin ninguna condición, garantía, manifestación o término de ninguna otra clase. TURNAU   no garantiza que sean correctos ni que estén completos los contenidos de esta Página web ni de cualquier otra que cuente con enlace a esta página.
              </p>

              <p>TURNAU puede cambiar el contenido en cualquier momento sin preaviso. El material en la Página puede no estar actualizado y TURNAU no se compromete de ninguna manera a actualizar dicho material. Por consiguiente, y en la medida en que ello esté permitido en Derecho, TURNAU facilita la página bajo condición de que TURNAU excluye la aplicación de cualquier manifestación, garantía, obligación de saneamiento, condición u otros términos en la medida en que puedan tener efecto en relación con la Página y no estén expresamente incluidos en este Aviso Legal.
                Cualquier afirmación que se haga en esta Página web se refiere exclusivamente a los productos o servicios que ofrece TURNAU.</p>

              <p>En la medida de lo legalmente posible, ni TURNAU ni cualquiera de las sociedades de su mismo grupo empresarial (ni ninguno de los administradores, directivos, empleados, accionistas o agentes de TURNAU o de cualquiera de las sociedades de su mismo grupo empresarial) serán declarados responsables de daños o perjuicios directos, indirectos incidentales, emergentes o punitivos que sean consecuencia de: (a) la capacidad (o incapacidad) de acceder a esta página web; (b) el uso  (o la incapacidad de usar) cualquier contenido de esta página web, (c) o el contenido de cualquier página web que tenga un enlace con esta página web. Además, TURNAU no garantiza que esta página web o el servidor que permite el acceso a ella no contengan ningún virus u otro elemento perjudicial. Asimismo en la medida de lo legalmente posible, será Ud. y no TURNAU, quien asuma el entero coste de cualquier suministro, reparación o corrección necesarios y la condena en cualquier procedimiento relacionado se limitará al pago de la indemnización monetaria correspondiente y no incluirá ninguna condena distinta de esa condena al pago de indemnización.
              </p>

              <p>Cualquier comunicación o material que envíe por correo o por Internet a esta Página web es -y será tratada como- no confidencial y no propiedad del remitente, y será para siempre propiedad exclusiva de TURNAU y de las compañías de su mismo grupo empresarial. Al transmitir o enviar por correo cualquier comunicación o material a esta Página web usted accede a que TURNAU o cualquiera de las compañías de su mismo grupo empresarial puedan utilizar su comunicación y/o material para cualquier fin, incluyendo su reproducción, transmisión, publicación, emisión o envío por correo. TURNAU no está obligada a responder a los mensajes enviados a esta Página web, ni ofrecer compensación alguna por tales comunicaciones y/o materiales. Por favor tenga en cuenta que la Ley local aplicable regula el tipo de material que puede remitirse a una Página Web, y no debe Ud. contravenir tales leyes incluyendo aquellas que prohíben el envío de material amenazante, injurioso, difamatorio, obsceno, pornográfico, indecente o blasfemo, ni tampoco ningún material que pueda constituir o inducir a conductas consideradas un delito penal, o que contravenga leyes o regulaciones vigentes.
              </p>

              <p>TURNAU no tiene capacidad ni acepta la responsabilidad de revisar las comunicaciones o materiales remitidos o posteados a través de su Página.  Sin perjuicio de ello, TURNAU se reserva el derecho, ejercitable a su sola discreción, de retirar cualesquiera comunicaciones o materiales que considere inaceptables por cualquier razón.</p>

              <p>TURNAU sólo utilizará los nombres, logos o marcas que aparecen en esta Página web para los ámbitos geográficos en los que ella o las compañías de su mismo grupo empresarial tengan derecho a hacerlo, en virtud de marcas o licencias vigentes o pendientes de registro, o por cualquier otro derecho. Para evitar cualquier duda, TURNAU no pretende utilizar ningún nombre, logo o marca en un ámbito geográfico en el que no esté autorizada a hacerlo, y no proporcionará ni ofrecerá ningún producto y/o servicio que lleven dicho nombre, logo o marca en dichos ámbitos geográficos. Está terminantemente prohibida la utilización correcta o incorrecta de estas marcas registradas o de cualquier otro contenido de esta Página web, excepto en la forma que establecen estos Términos y Condiciones Legales.
              </p>

              <p>Estos Términos y Condiciones Legales se regirán por las leyes españolas.
              </p>

              <p>Estos Términos y Condiciones Legales estarán vigentes a partir del 8 de septiembre del 2020. © TURNAU. Reservados todos los derechos.
              </p>

            </div>

          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default Politica