import React from 'react';
import './index.scss';

import Toggle from '../../components/toggle';

//Componentes
import Header from '../../components/header';
import Footer from '../../components/Footer';
import Loading from '../../components/loading';

//Calling bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
//Calling WOWjs
import WOW from 'wowjs';

//Redux
import Api from '../../services/api';
import ApiV2 from '../../services/api-v2';
import { Diversos } from '../../services/diversos';
import { ApplicationState } from '../../store';
import { User } from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import { Param } from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Auth } from '../../store/ducks/auth/types';
import Informacoes from '../MenuCliente';
import moment from 'moment';



interface StateProps {
    user: User;
    param: Param;
}

interface DispatchProps {
    doLogin(user: User): void;
    setParam(param: Param): void;
    setParam(param: Param): void;
}

interface OwnProps { }

type Props = StateProps & DispatchProps & OwnProps;

class PerfilSalao extends React.Component<Props> {

    state: any = null;
    api: any;
    apiv2: any;
    userRef: any;

    constructor(props) {
        super(props);
        this.api = new Api();
        this.apiv2 = new ApiV2();
        this.state = {
            etapa: 1,
            loading: false,

            horarios: [],

            cliente: '',
            email: '',
            not: '',
            tel: '',
            servico: '',
            detalhes: '',
            profissional: '',

            isLoading: false,
            loadingMsg: '',
            servicos: [],
            servicosSelected: [],
            profissionais: [],
            profissionaisPicker: [],
            data: '',
            hora: '',
            showModalDatePicker: false,
            showModalHourPicker: false,
            showModalProfissionalPicker: false,
            notificarPorEmail: false,
        }
    }

    // Defining WOW 
    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();

        moment.locale('pt-BR');

        this.getServicos()

        this.getProfissionais();

        if (this.props.user && this.props.user.codigo !== this.props.user.empresa) {

        } else {
            this.setState({
                profissionais: [
                    { CODIGO: this.props.user.nome, NOME: this.props.user.codigo },
                ],
                profissionaisPicker: [
                    { label: this.props.user.nome, value: this.props.user.codigo },
                ],
            });
        }
    }

    private async getServicos() {
        const self = this;

        let param = {
            profissional: self.props.user.codigo,
            limit: 0,
        };

        try {
            const { data } = await self.api.post(
                '/Servico/getAllByProfissional',
                param,
            );

            if (data.status == true) {
                this.setState({ servicos: data.msg });
            }
        } catch (e) {
            console.log(e);
        } finally {

        }
    }

    async getProfissionais() {
        const self = this;

        try {
            let param = {
                codigo: this.props.user.codigo
            };

            const { data } = await self.apiv2.get(
                `/salon/${self.props.user.codigo}`,
                param,
            );

            this.setState({ profissionais: data.msg.PROFISSIONAIS })

            if (data.status === false) {
                alert('erro' + Error)
            } else {

            }
        } catch (e) {
            console.log(e);
        } finally {

        }
    }

    private async getHorarios() {
        const self = this;

        if (this.state.servico == null || '') {
            alert('Seleccione un servicio.')
        }

        let param = {
            salon: this.props.user.codigo,
            professional: 0,
            service: this.state.servico,
            date: this.state.data
        };

        try {
            const { data } = await self.apiv2.post('/schedules/availability', param,
            );

            if (data.status == true) {
                this.setState({ horarios: data.msg.agenda });
            }
        } catch (e) {
            console.log(e);
        } finally {

        }
    }

    private async _handleSubmit() {
        const self = this;

        this.setState({ loading: true })

        moment.locale('pt-BR');

        let dataAgenda = moment(this.state.hora, "DD/MM/YYYY HH:mm");

        if (!dataAgenda.isValid()) {
            alert('La fecha dataAgenda seleccionada no es valida')
        }

        if (
            !this.state.cliente ||
            !this.state.email ||
            !this.state.tel
        ) {
            alert('El cliente informado no es válido')
        }

        if (this.state.servico <= 0 || null) {
            alert('Debes seleccionar al menos un servicio')
        }

        let paramCli = {
            perfil: 1,
            nome: this.state.cliente,
            email: this.state.email,
            senha: Math.random().toString(36).substr(2, 5),
        };

        let retornoCli: any = null;

        try {
            const { data } = await self.api.post('/User/create', paramCli);

            if (!data.status) {
                console.log(data.msg)
                alert('No se pudo registrar al cliente, verifique su conexión a Internet y vuelva a intentarlo')
            } else {
                retornoCli = data;
            }
        } catch (e) {
            console.log(e);
            alert('No se pudo registrar al cliente, verifique su conexión a Internet y vuelva a intentarlo')
        } finally {

        }

        if (!retornoCli || !retornoCli) {
            alert('No se pudo registrar al cliente, verifique su conexión a Internet y vuelva a intentarlo')
            return false;
        }

        try {

            let param = {
                cliente: retornoCli.codigo,
                data: dataAgenda.format('YYYY-MM-DD'),
                hora: dataAgenda.format('HH:mm'),
                obs: this.state.detalhes,
                desconto: 0.0,
                formapg: 1,
                cupom: '',
                salao: this.props.user.codigo,
                itens: [{
                    servico: this.state.servico,
                    qtd: 1,
                    valor: 0.00,
                    desconto: 0,
                }],
            }

            console.log(param)

            const { data } = await self.apiv2.post('/schedule', param);

            if (!data.status) {
                console.log(data.msg)
                alert('No se puede crear el calendario, verifique su conexión a Internet y vuelva a intentarlo');
            } else {
                alert('Agenda creada con éxito')
                window.location.href = '/home-salon'
            }
        } catch (e) {
            console.log(e);
            alert('No se puede crear el calendario, verifique su conexión a Internet y vuelva a intentarlo')
        } finally {
            this.setState({ loading: false })
        }
    }


    render() {
        return (

            this.state.loading == true ?
                <Loading />
                :
                <>
                    <Header
                        registra={false}
                        login={true}
                        notificacoes={true}
                    />
                    <div className="novo-horario">
                        <div className="row">
                            <div className="header-novo-horario wow fadeInUp">
                                <i className="far fa-calendar-alt"></i>
                                <h5>Crear nueva reserva</h5>
                            </div>
                            {
                                this.state.etapa == 1 ?
                                    <div className="container-inputs">
                                        <div className="input-box wow fadeInUp">
                                            <small>Servicios</small>
                                            <select name="data" value={this.state.servico || ''} onChange={(event) => this.setState({ servico: event.target.value })}>
                                                <option value={0}>Seleccione un servicio</option>
                                                {
                                                    this.state.servicos.map(item => (
                                                        <option
                                                            value={item.codigo}>{item.nome}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }} className="wow fadeInUp">
                                            <div className="input-box" style={{ marginRight: 5 }}>
                                                <small>Fecha*</small>
                                                <input
                                                    type="date"
                                                    value={this.state.data || ''}
                                                    onChange={(event) => {
                                                        this.setState({ data: event.target.value }, () => this.getHorarios());
                                                    }}
                                                />
                                            </div>
                                            <div className="input-box " style={{ marginLeft: 5 }}>
                                                <small>Hora*</small>
                                                <select name="hora" value={this.state.hora || ''} onChange={(event) => this.setState({ hora: event.target.value })}>
                                                    <option value={0}>Selecciona hora</option>
                                                    {
                                                        this.state.horarios.map(item => (
                                                            <option
                                                                value={item.data}>{item.data.slice(item.data.length - 5)}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="input-box wow fadeInUp">
                                            <small>Profesional*</small>
                                            <select name="profissional" value={this.state.profissional || ''} onChange={(event) => this.setState({ profissional: event.target.value })}>
                                                <option value={0}>Selecciona</option>
                                                {
                                                    this.state.profissionais.map(item => (
                                                        <option
                                                            value={item.CODIGO}>{item.NOME}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="input-box wow fadeInUp">
                                            <small>Cliente</small>
                                            <input type="text" value={this.state.cliente || ''} onChange={(event) => this.setState({ cliente: event.target.value })} />
                                        </div>
                                        <div className="input-box wow fadeInUp">
                                            <small>Correo Electrónico</small>
                                            <input type="email" value={this.state.email || ''} onChange={(event) => this.setState({ email: event.target.value })} />
                                        </div>
                                        <div className="box-toggle wow fadeInUp">
                                            <p>Notificar por correo electrónico</p>
                                            <div className={`toggle-box ${this.state.not == false ? 'off-b' : 'on-b'}`} onClick={() => this.setState({ not: !this.state.not })}>
                                                <div className={`toggle ${this.state.not == false ? 'off' : 'on'}`}></div>
                                            </div>
                                        </div>
                                        <div className="input-box wow fadeInUp">
                                            <small>Teléfono</small>
                                            <input type="phone" value={this.state.tel || ''} onChange={(event) => this.setState({ tel: event.target.value })} style={{ fontFamily: 'Arial !important' }} />
                                        </div>
                                        <div className="input-box wow fadeInUp">
                                            <small>Detalles</small>
                                            <input type="text" value={this.state.detalhes || ''} onChange={(event) => this.setState({ detalhes: event.target.value })} />
                                        </div>
                                        <div className="footer-inputs wow fadeInUp">
                                            <button onClick={() =>
                                                this._handleSubmit()
                                            }>
                                                <i className="far fa-thumbs-up"></i>
                                                <p>próximo</p>
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    this.state.etapa == 2 ?
                                        <div className="container-inputs">

                                        </div>
                                        :
                                        null
                            }

                        </div>

                    </div>
                    <Footer />
                </>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.data,
    param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PerfilSalao);