import React from 'react';
import './index.scss';

//Componentes
import Header from '../../../components/header';
import Footer from '../../../components/Footer';
import CardFuncionario from '../../../components/CardFuncionario';

//Calling bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
//Calling WOWjs
import WOW from 'wowjs';

//Redux
import Api from '../../../services/api';
import ApiV2 from '../../../services/api-v2';
import { Diversos } from '../../../services/diversos';
import { ApplicationState } from '../../../store';
import { User } from '../../../store/ducks/user/types';
import * as UserActions from '../../../store/ducks/user/actions';
import { Param } from '../../../store/ducks/param/types';
import * as ParamActions from '../../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Auth } from '../../../store/ducks/auth/types';
import Informacoes from '../../MenuCliente';

import Lottie from 'react-lottie';
//Animaceos Lottie
import animationData from '../../../animations/loading.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


interface StateProps {
    user: User;
    param: Param;
}

interface DispatchProps {
    doLogin(user: User): void;
    setParam(param: Param): void;
    setParam(param: Param): void;
}

interface OwnProps { }

type Props = StateProps & DispatchProps & OwnProps;

class Profissionais extends React.Component<Props> {

    state: any = null;
    api: any;
    apiv2: any;
    userRef: any;

    constructor(props) {
        super(props);
        this.api = new Api();
        this.apiv2 = new ApiV2();
        this.state = {
            add: false,
            profissionais: [],
            isLoading: false,
            nome: '',
            email: '',
            telefone: '',
        }
    }

    // Defining WOW 
    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();

        if (this.props.user.empresa == 0) {
            window.location.href = '/home-cliente'
        }

        this.getProfissionais()
    }

    async getProfissionais() {
        const self = this;

        this.setState({ isLoading: true })

        try {
            let param = {
                codigo: this.props.user.codigo
            };

            const { data } = await self.apiv2.get(
                `/salon/${self.props.user.codigo}`,
                param,
            );

            this.setState({ profissionais: data.msg.PROFISSIONAIS })

            if (data.status === false) {
                alert('erro' + Error)
            } else {

            }
        } catch (e) {
            console.log(e);
        } finally {
            this.setState({ isLoading: false })
        }
    }

    private async submitForm() {
        const self = this;

        if (self.state.nome == '' || self.state.nome == null) {
            alert('Atencion Campos obligatorios no rellenados.');
            return;
        }

        this.setState({ isLoading: true })

        let param = {
            perfil: 3,
            nome: this.state.nome,
            email: this.state.email,
            empresa: self.props.user.codigo,
            senha: `${self.state.nome.split(' ')[0]}${Math.random()
                .toString(36)
                .substr(2, 3)}`,
        };

        try {
            const { data } = await self.api.post('/User/create', param);

            if (data.status === false) {
                console.log('erro' + Error)
                window.location.href = '/mi-profesionales'
            } else {
                alert('Profesional añadido con éxito')
                window.location.href = '/mi-profesionales'
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.setState({ isLoading: false })
        }
    }

    render() {
        return (
            <>
                <Header
                    registra={false}
                    login={false}
                    notificacoes={true}
                />
                {
                    this.state.add == true ?
                        <section className="modal-funcionarios">
                            <div className="box">
                                <div style={{ display: 'flex' }}>
                                    <i className="fas fa-user"></i>
                                    <h5>Agregar empleado</h5>
                                </div>
                                {
                                    this.state.isLoading ?
                                        <Lottie
                                            options={defaultOptions}
                                            height={350}
                                            width={350}
                                            isStopped={this.state.isStopped}
                                            isPaused={this.state.isPaused}
                                        />
                                        :
                                        <>
                                            <div className="dados-inf" style={{ marginBottom: 15 }}>
                                                <input type="text" placeholder="Nombre" className="form-controls"
                                                    value={this.state.nome || ''} onChange={(event) => this.setState({ nome: event.target.value })} />
                                            </div>
                                            <div className="dados-inf">
                                                <input type="text" placeholder="Ingrese correo electrónico o apodo" className="form-controls"
                                                    value={this.state.email || ''} onChange={(event) => this.setState({ email: event.target.value })} />
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                                                <button className="btn_secondary" onClick={() => this.setState({ add: !this.state.add })}>
                                                    <p>Volver</p>
                                                </button>
                                                <button className="btn_primary" onClick={() => this.submitForm()}>
                                                    <p style={{ color: '#fff' }}>Mandar</p>
                                                </button>
                                            </div>
                                        </>
                                }
                            </div>
                        </section>
                        :
                        null
                }
                <section className="container-funcionarios">
                    <div className="header-funcionarios wow fadeInUp">
                        <i className="fas fa-users"></i>
                        <h5>Profesionales</h5>
                    </div>
                    <div className="row-funcionarios wow fadeInUp">
                        {
                            this.state.profissionais.lenght <= 0 ?
                                <p>Aún no tienes ningún profesional registrado.</p>
                                :
                                this.state.profissionais.map(item => (
                                    <CardFuncionario
                                        codigo={item.CODIGO}
                                        nome={item.NOME}
                                    />
                                ))
                        }
                    </div>
                    <button className="btn-add wow fadeInUp" onClick={() => this.setState({ add: !this.state.add })}>
                        <i className="fas fa-plus"></i>
                    </button>
                </section>


                <Footer />
            </>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.data,
    param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Profissionais);