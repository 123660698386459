import React from "react";
import "./index.scss";

//Calling WOWjs
import WOW from "wowjs";

//Redux
import Api from "../../services/api";
import ApiV2 from "../../services/api-v2";
import { Diversos } from "../../services/diversos";
import { ApplicationState } from "../../store";
import { User } from "../../store/ducks/user/types";
import * as UserActions from "../../store/ducks/user/actions";
import { Param } from "../../store/ducks/param/types";
import * as ParamActions from "../../store/ducks/param/actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Auth } from "../../store/ducks/auth/types";

import Usuario from "../../assets/images/usuario.png";

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  doLogout(): void;
  setParam(param: Param): void;
  setParam(param: Param): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

export class SideMenu extends React.Component<Props> {
  state: any = null;
  api: any;
  apiv2: any;
  userRef: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.apiv2 = new ApiV2();
    this.state = {};
  }

  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (
      !this.props.user ||
      !this.props.user.codigo ||
      !this.props.user.status
    ) {
      window.location.href = "/";
    }
  }

  render() {
    return (
      <section className="container-perfil  wow fadeInLeft">
        <div className="box-roxo" />
        <div className="border-user">
          {this.props.user && this.props.user.avatar && (
            <img src={this.props.user.avatar} className="user-img" />
          )}
        </div>
        <h4>
          {this.props.user && this.props.user.avatar && this.props.user.nome}
        </h4>
        <div className="container-dados-user">
          <div className="header-container">
            <h5>Mi Cuenta</h5>
          </div>
          <a className="line-opt-perfil" href="/mi-perfil">
            <div className="name">
              <p style={{ marginLeft: 10 }}>Mi perfil</p>
            </div>
            <i className="fas fa-chevron-right arrow"></i>
          </a>
          <a className="line-opt-perfil" href="/favoritos">
            <div className="name">
              <p style={{ marginLeft: 10 }}>Favoritos</p>
            </div>
            <i className="fas fa-chevron-right arrow"></i>
          </a>
          <a className="line-opt-perfil" href="/horarios-registrados">
            <div className="name">
              <p style={{ marginLeft: 10 }}>Mi agenda</p>
            </div>
            <i className="fas fa-chevron-right arrow"></i>
          </a>
          {/* <a className="line-opt-perfil" href="/beautypoints">
                        <div className="name">
                            <p style={{ marginLeft: 10 }}>BeautyPoints</p>
                        </div>
                        <i className="fas fa-chevron-right arrow"></i>
                    </a> */}
          <a className="line-opt-perfil" href="/ayuda">
            <div className="name">
              <p style={{ marginLeft: 10 }}>Contacto</p>
            </div>
            <i className="fas fa-chevron-right arrow"></i>
          </a>

          <div
            style={{
              width: "90%",
              height: "auto",
              display: "flex",
              justifyContent: "flex-end",
              color: "#000",
              cursor: "pointer",
            }}
            title="cerrar sesión en la cuenta"
            onClick={() => this.props.doLogout()}
          >
            <p style={{ fontSize: "14px" }}>Cerrar sesíon</p>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
