import React from 'react';
import './index.scss';

import Lottie from 'react-lottie';

//Componentes
import Header from '../../../components/header';
import Footer from '../../../components/Footer';
import Cartao from '../../../components/CardCartao';

//Calling bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
//Calling WOWjs
import WOW from 'wowjs';




class Points extends React.Component {

    state: any = null;

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    // Defining WOW 
    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();
    }

    render() {
        return (
            <>
                <Header
                    registra={false}
                    login={false}
                    notificacoes={true}
                />

                <section className="container-horarios">
                    <div className="row-horarios">
                        <div className="header-horarios">
                            <i className="fas fa-star"></i>
                            <h5>Points</h5>
                        </div>

                        <div className="alert-card wow fadeInUp">
                            <h5>No hay Points registradas.</h5>
                        </div>

                    </div>

                </section>

                <div className="spacing" />

                <Footer />
            </>
        )
    }
}

export default Points