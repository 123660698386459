import React from "react";
import "./index.scss";
import swal from "sweetalert";
import Lottie from "react-lottie";

//Componentes
import Header from "../../../components/header";
import Footer from "../../../components/Footer";
import Cartao from "../../../components/CardCartao";

//Calling bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
//Calling WOWjs
import WOW from "wowjs";

//Redux
import Api from "../../../services/api";
import ApiV2 from "../../../services/api-v2";
import { Diversos } from "../../../services/diversos";
import { ApplicationState } from "../../../store";
import { User } from "../../../store/ducks/user/types";
import * as UserActions from "../../../store/ducks/user/actions";
import { Param } from "../../../store/ducks/param/types";
import * as ParamActions from "../../../store/ducks/param/actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Auth } from "../../../store/ducks/auth/types";
import Informacoes from "../../MenuCliente";

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  setParam(param: Param): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class Tarjetas extends React.Component<Props> {
  state: any = null;
  api: any;
  apiv2: any;
  userRef: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.apiv2 = new ApiV2();
    this.state = {
      Modal: false,
      cards: [],

      //Add card
      codigo: this.props.user.codigo,
      numero: " ",
      validade: " ",
      cvv: " ",
      portador: " ",
    };
  }

  // Defining WOW
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();

    this.getCards();
  }

  private async addCard() {
    const self = this;

    self.setState({ isLoadingPromocao: true }, () =>
      this.props.setParam({ ...this.props.param, isLoading: true })
    );

    let param = {
      codigo: this.props.user.codigo,
      numero: this.state.numero,
      validade: this.state.validade,
      cvv: this.state.cvv,
      portador: this.state.portador,
    };

    try {
      const { data } = await self.api.post("MercadoPago/saveCard", param);

      if (data.status === false) {
        swal(
          "Atencion",
          "No se pudo agregar la tarjeta ahora, verifique los datos o vuelva a intentarlo más tarde.",
          "error"
        );
      } else {
        this.setState({ isLoading: false });
        swal("Exito", "Tarjeta añadida con éxito.", "success");
        window.location.href = "/tarjetas";
      }
    } catch (e) {
      console.log(e);
    } finally {
      self.setState({ isLoadingPromocao: false }, () =>
        this.props.setParam({ ...this.props.param, isLoading: false })
      );
    }
  }

  private async getCards() {
    // eslint-disable-next-line consistent-this
    const self = this;

    self.setState({ isLoadingPromocao: true }, () =>
      this.props.setParam({ ...this.props.param, isLoading: true })
    );

    let param = {
      codigo: this.props.user.codigo,
    };

    try {
      const { data } = await self.api.post("/MercadoPago/getCards", param);

      if (data.status === false) {
        throw new Error("Nenhum cartão localizado");
      } else {
        self.setState({ cards: data.msg });
        this.setState({ isLoading: false });
      }
    } catch (e) {
      console.log(e);
      self.setState({ endereco: [] });
    } finally {
      self.setState({ isLoadingPromocao: false }, () =>
        this.props.setParam({ ...this.props.param, isLoading: false })
      );
    }
  }

  render() {
    return (
      <>
        <Header registra={false} login={false} notificacoes={true} />
        {this.state.Modal == true ? (
          <div className="modal-content">
            <div className="box-modal">
              <div className="header-modal">
                <h5>Agregar nueva tarjeta</h5>
              </div>
              <div className="input-box">
                <small>Numero de tarjeta*</small>
                <input
                  type="number"
                  value={this.state.numero}
                  onChange={(event) =>
                    this.setState({ numero: event.target.value })
                  }
                />
              </div>
              <div className="input-box">
                <small>Validez* MM/AAAA</small>
                <input
                  type="text"
                  value={this.state.validade}
                  onChange={(event) =>
                    this.setState({ validade: event.target.value })
                  }
                />
              </div>
              <div className="input-box">
                <small>Código da seguridad*</small>
                <input
                  type="number"
                  value={this.state.cvv}
                  onChange={(event) =>
                    this.setState({ cvv: event.target.value })
                  }
                />
              </div>
              <div className="input-box">
                <small>Titular de la tarjeta*</small>
                <input
                  type="text"
                  value={this.state.portador}
                  onChange={(event) =>
                    this.setState({ portador: event.target.value })
                  }
                />
              </div>
              <button
                className="btn_primary wow fadeInUp"
                style={{ width: "100%", marginTop: 25 }}
                onClick={() => this.addCard()}
              >
                <i className="fas fa-plus"></i>
                <p>agregar tarjeta</p>
              </button>
              <button
                className="btn_secondary wow fadeInUp"
                style={{
                  width: "100%",
                  marginTop: 25,
                  color: "#6851FB",
                  border: "solid 1px",
                  borderColor: "#6851FB",
                }}
                onClick={() => this.setState({ Modal: !this.state.Modal })}
              >
                <i className="fas fa-chevron-left"></i>
                <p>Volver</p>
              </button>
            </div>
          </div>
        ) : null}

        <section className="container-card">
          <div className="row-card">
            <div className="header-perfil">
              <i className="far fa-credit-card"></i>
              <h5>Mis tarjetas de credito</h5>
            </div>

            {this.state.cards == null || this.state.cards.length <= 0 ? (
              <div className="alert-card">
                <h5>No hay tarjetas registradas.</h5>
              </div>
            ) : (
              this.state.cards.map((item) => (
                <Cartao
                  id={item.id}
                  numero={`${String(item.numero).substring(0, 6)}******`}
                  validade={item.validade}
                  bandeira={String(Diversos.getBrandCard(item.numero))}
                  link={item.mercadopago_cartao_bandeira_logo}
                />
              ))
            )}

            <button
              className="btn_primary wow fadeInUp"
              style={{ width: "100%", marginTop: 25 }}
              onClick={() => this.setState({ Modal: !this.state.Modal })}
            >
              {/* <i className="fas fa-plus"></i> */}
              <p>agregar tarjeta</p>
            </button>
          </div>
        </section>

        <div className="spacing" />

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Tarjetas);
