import React from "react";
import "./index.scss";

//Componentes
import Header from "../../components/header";
import Footer from "../../components/Footer";

import Loading from "../../components/loading";
import SideMenu from "../../components/SideMenuSalao";
import CardFuncionarioHome from "../../components/CardFuncionarioHome";

import { ReactAgenda, ReactAgendaCtrl, guid, Modal } from "react-agenda";

//Calling bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
//Calling WOWjs
import WOW from "wowjs";

import moment from "moment";

//Redux
import Api from "../../services/api";
import ApiV2 from "../../services/api-v2";
import { Diversos } from "../../services/diversos";
import { ApplicationState } from "../../store";
import { User } from "../../store/ducks/user/types";
import * as UserActions from "../../store/ducks/user/actions";
import { Param } from "../../store/ducks/param/types";
import * as ParamActions from "../../store/ducks/param/actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Auth } from "../../store/ducks/auth/types";
import Informacoes from "../MenuCliente";

import Lottie from "react-lottie";
//Animaceos Lottie
import animationData from "../../animations/loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  setParam(param: Param): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

var colors = {
  "color-1": "#FF7900",
};

var now = new Date();

class HomeSalao extends React.Component<Props> {
  state: any = null;
  api: any;
  apiv2: any;
  userRef: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.apiv2 = new ApiV2();
    this.state = {
      loading: false,
      agenda: [],
      selected: [],
      cellHeight: 20,
      showModal: false,
      locale: "br",
      rowsPerHour: 1,
      numberOfDays: 7,
      startDate: new Date(),

      profissionais: [],
      loadingAgenda: true,
      avatarTodos: require("../../assets/icons/pessoas.jpg"),
      funSelecionado: "",
      nomeFunAgenda: "",

      /* items: [
                 {
                     _id: guid(),
                     name: 'Evento',
                     startDateTime: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10, 0),
                     endDateTime: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 12, 0),
                     classes: 'color-1'
                 },
             ],
             */
    };
  }

  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();

    if (
      !this.props.user ||
      !this.props.user.codigo ||
      !this.props.user.fgFuncionario
    ) {
      window.location.href = "/login";
    }

    moment.locale("pt-BR");

    this.getProfissionais();
    this.getAgenda();
  }

  async getProfissionais() {
    const self = this;

    this.setState({ isLoading: true });

    try {
      let param = {
        codigo: this.props.user.codigo,
      };

      const { data } = await self.apiv2.get(
        `/salon/${self.props.user.codigo}`,
        param
      );

      this.setState({ profissionais: data.msg.PROFISSIONAIS });

      if (data.status === false) {
        alert("erro" + Error);
      } else {
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async getAgenda() {
    const self = this;

    this.setState({ loadingAgenda: true });

    try {
      const { data } = await self.apiv2.get(
        `/schedules/${this.props.user.codigo}`
      );

      if (data.status === false) {
        alert("erro");
      } else {
        this.setState({ agenda: data.msg });

        const tmp: any = [];

        for (let i = 0; i < data.msg.length; i++) {
          tmp.push({
            _id: data.msg[i].PEDIDO,
            name: data.msg[i].clienteDados.NOME,
            startDateTime: moment(
              `${data.msg[i].DATA} ${data.msg[i].HORA}`,
              "DD/MM/YYYY HH:mm"
            ).toDate(),
            endDateTime: moment(
              `${data.msg[i].DATA} ${data.msg[i].HORA}`,
              "DD/MM/YYYY HH:mm"
            )
              .add(data.msg[i].ITENS[0].servicoDados.TEMPO, "minutes")
              .toDate(),
            classes: "color-1",
            funcionario: data.msg[i].PROFISSIONAL
              ? data.msg[i].PROFISSIONAL
              : null,
          });
        }

        this.setState({ agenda: data.msg, items: tmp });
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loadingAgenda: false });
    }
  }

  render() {
    return this.state.loading == true ? (
      <Loading />
    ) : (
      <>
        <Header registra={false} login={false} notificacoes={true} />
        <div className="perfil-salao">
          <SideMenu />
          <div className="row">
            <div className="container-profissionais">
              <p>Selecciona un perfil para ver la agenda</p>
              <div className="box-cards-funcionarios">
                {this.state.profissionais.lenght <= 0 || null
                  ? null
                  : this.state.profissionais.map((item) => (
                      <CardFuncionarioHome
                        setFuncionario={(props) => {
                          this.setState({ funSelecionado: props });
                          this.getAgenda();
                        }}
                        setNomeFun={(props) =>
                          this.setState({ nomeFunAgenda: props })
                        }
                        codigo={item.CODIGO}
                        nome={item.NOME}
                        opacity={
                          this.state.funSelecionado == item.CODIGO ? "1" : "2"
                        }
                      />
                    ))}
                <div
                  className="card-funcionario"
                  style={
                    this.state.funSelecionado
                      ? { opacity: "0.5" }
                      : { opacity: "1" }
                  }
                  onClick={() => {
                    this.setState({ funSelecionado: "" });
                    this.setState({ nomeFunAgenda: "Todos empleados" });
                  }}
                >
                  <div
                    className="foto-funcionario"
                    style={{
                      backgroundImage: `url(${this.state.avatarTodos})`,
                    }}
                  />
                  <p>Todos empleados</p>
                </div>
              </div>
            </div>
            <div className="header-salao">
              <i className="far fa-calendar-alt"></i>
              {this.state.nomeFunAgenda ? (
                <h5>Agenda de {this.state.nomeFunAgenda}</h5>
              ) : (
                <h5>Agenda de todos los empleados </h5>
              )}
            </div>
            <div className="box-calendario">
              {this.state.loadingAgenda ? (
                <Lottie
                  options={defaultOptions}
                  height={400}
                  width={400}
                  isStopped={this.state.isStopped}
                  isPaused={this.state.isPaused}
                  className="anim"
                />
              ) : (
                <ReactAgenda
                  minDate={moment()
                    .utcOffset("-03:00")
                    .subtract(6, "months")
                    .toDate()}
                  maxDate={moment()
                    .utcOffset("-03:00")
                    .add(3, "months")
                    .toDate()}
                  disablePrevButton={false}
                  startDate={this.state.startDate}
                  cellHeight={this.state.cellHeight}
                  locale={this.state.locale}
                  items={
                    !this.state.funSelecionado
                      ? this.state.items
                      : this.state.items.filter(
                          (row) =>
                            Number(row.funcionario) ===
                            Number(this.state.funSelecionado)
                        )
                  }
                  numberOfDays={this.state.numberOfDays}
                  rowsPerHour={this.state.rowsPerHour}
                  itemColors={colors}
                  autoScale={false}
                  fixedHeader={true}
                />
              )}
            </div>
            <a href="/nuevo-horario">
              <button className="btn_fl">
                <i className="fas fa-plus"></i>
                <p>Nuevo horario</p>
              </button>
            </a>

            {!this.state.funSelecionado
              ? this.state.agenda.map((item) => (
                  <div className="box-hs">
                    <h5>{item.clienteDados.NOME}</h5>
                    <p>{item.ITENS[0].servicoDados.SERVICO}</p>
                    <p className="destaque">{item.DATA}</p>
                    <p className="destaque">{item.HORA}</p>
                  </div>
                ))
              : this.state.agenda
                  .filter(
                    (row) =>
                      Number(row.PROFISSIONAL) ===
                      Number(this.state.funSelecionado)
                  )
                  .map((item) => (
                    <div className="box-hs">
                      <h5>{item.clienteDados.NOME}</h5>
                      <p>{item.ITENS[0].servicoDados.SERVICO}</p>
                      <p className="destaque">{item.DATA}</p>
                      <p className="destaque">{item.HORA}</p>
                    </div>
                  ))}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomeSalao);
