import React from 'react';
import './index.scss';

//iImagens 
import Logo from '../../assets/images/turnau.png';
import Apple from '../../assets/images/footer/apple.png';
import Google from '../../assets/images/footer/google.png';


interface Props {

}

export class Footer extends React.Component<Props> {

    state: any = null;

    constructor(props) {
        super(props);
        this.state = {
            popUpCondicoes: true,
        }
    }

    render() {
        return (
            <>
                <footer>
                    <div className="row">
                        <div className="layer-1">
                            <img src={Apple} />
                            <img src={Google} />
                        </div>
                        <div className="layer-2">
                            <div className="box-1">
                                <img src={Logo} className="logo" />
                                <div className="box-options">
                                    <h5>Sobre Turnau</h5>
                                    <a href="./quienes-somos">
                                        <p>Quiénes somos</p>
                                    </a>
                                    <a href="./login">
                                        <p>Quiero ser miembro</p>
                                    </a>
                                    <a href="./politica">
                                        <p>Términos y condiciones</p>
                                    </a>
                                </div>
                            </div>
                            <div className="instagram">
                                <i className="fab fa-instagram"></i>
                                <div className="box">
                                    <h5>¡Seguinos en Instagram!</h5>
                                    <p>@turnauok</p>
                                </div>
                            </div>
                        </div>
                        <div className="layer-3">
                            <p>Clausula de confidencialidad</p>
                            <span></span>
                            <a href="./politica">
                                <p>Condiciones legales</p>
                            </a>
                            <span></span>
                            <p>Politicas de cookies</p>
                        </div>
                        <p className="copy">Copyright © 2021 Turnau - Todos los derechos reservados.</p>
                    </div>
                </footer>
            </>
        )
    }
};

export default Footer;

