import React from 'react';
import './index.scss';

import Lottie from 'react-lottie';
//Animaceos Lottie
import animationData from '../../animations/loading.json';


interface Props {

}


const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};


class Loading extends React.Component<Props> {

  state: any = null;

  constructor(props) {
    super(props);
    this.state = {

    }
  }


  render() {
    return (
      <div className="loading-container">
        <Lottie options={defaultOptions}
          height={400}
          width={400}
          isStopped={this.state.isStopped}
          isPaused={this.state.isPaused}
          className="anim"
        />
      </div>
    )
  }
}

export default Loading