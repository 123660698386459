import axios from "axios";
import https from "https";
declare var global: any;

const apiAxio = axios.create({
  baseURL: "https://api-v2.turnau.com.ar",
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
});

export default class ApiV2 {
  constructor() {
    // console.log(BASE_URL_V2);
  }

  public async get(url: string) {
    try {
      return apiAxio.get(url, {
        headers: {
          Accept: "application/json",
          Authorization:
            " Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjEsImlhdCI6MTY1MDkxMTgyNn0.qNFPqdm5WhQ54-bK6nql2IhytlLFh7T6ibLyts0ywCk",
        },
      });
    } catch (e) {
      console.error("api (ERROR): ", e);
    }
  }

  public async post(url: string, body: any = null) {
    try {
      var bodyEnd = {
        ...body,
        HTTP_X_AUTH_TOKEN:
          "fGtaUSg0Q2J8MC9XU2UxNSo4JnJ6c2JiT2hZe2JXaWMyNFVXdlZ7X3F2OG9iNDxYbG1LR3VeK0A8JD02UDtp",
      };

      return apiAxio.post(url, bodyEnd, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization:
            " Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjEsImlhdCI6MTY1MDkxMTgyNn0.qNFPqdm5WhQ54-bK6nql2IhytlLFh7T6ibLyts0ywCk",
        },
      });
    } catch (e) {
      console.log("api (ERROR): ", e);
    }
  }

  public async put(url: string, body: any = null) {
    try {
      var bodyEnd = {
        ...body,
        HTTP_X_AUTH_TOKEN:
          "fGtaUSg0Q2J8MC9XU2UxNSo4JnJ6c2JiT2hZe2JXaWMyNFVXdlZ7X3F2OG9iNDxYbG1LR3VeK0A8JD02UDtp",
      };

      return apiAxio.put(url, bodyEnd, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization:
            " Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjEsImlhdCI6MTY1MDkxMTgyNn0.qNFPqdm5WhQ54-bK6nql2IhytlLFh7T6ibLyts0ywCk",
        },
      });
    } catch (e) {
      console.log("api (ERROR): ", e);
    }
  }

  public async delete(url: string) {
    try {
      return apiAxio.delete(url, {
        headers: {
          Accept: "application/json",
          Authorization:
            " Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjEsImlhdCI6MTY1MDkxMTgyNn0.qNFPqdm5WhQ54-bK6nql2IhytlLFh7T6ibLyts0ywCk",
        },
      });
    } catch (e) {
      console.log("api (ERROR): ", e);
    }
  }
}
