import React from 'react';
import './index.scss';
import CardPromo from '../CardPromo';

//Redux
import Api from '../../services/api';
import { Diversos } from '../../services/diversos';
import { ApplicationState } from '../../store';
import { User } from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import { Param } from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';


import Lottie from 'react-lottie';
//Animaceos Lottie
import animationData from '../../animations/loading.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
}

interface OwnProps { }

type Props = StateProps & DispatchProps & OwnProps;

export class Slider extends React.Component<Props> {

  state: any = null;
  api: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      promocao: [],
      isLoading: true,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.getPromocao();
    }, 500);
  }

  private async getPromocao() {
    const self = this;

    self.setState({ isLoadingPromocao: true }, () =>
      this.props.setParam({ ...this.props.param, isLoading: true }),
    );

    let param = {
      limit: 0,
      lat: self.props.param.latitude,
      lng: self.props.param.longitude,
    };

    try {
      const { data } = await self.api.post('/Servico/getByPromocao', param);

      if (data.status === false) {
        throw new Error('Nenhum servico localizado');
      } else {
        self.setState({ promocao: data.msg });
        this.setState({ isLoading: false });
      }
    } catch (e) {
      console.log(e);
      self.setState({ promocao: [] });
    } finally {
      self.setState({ isLoadingPromocao: false }, () =>
        this.props.setParam({ ...this.props.param, isLoading: false }),
      );
    }
  }

  render() {
    return (
      <section className="container-slider-promo">
        <div className="header-container-slider">
          <h4>Promociones</h4>
        </div>
        <div className="container-slider-cards">
          <div className="fadeInLeft" />
          <div className="container-cards">
            <div className="space"></div>
            {
              this.state.isLoading == true ?
                <div className="containerLoading">
                  <Lottie options={defaultOptions}
                    height={250}
                    width={250}
                    isStopped={this.state.isStopped}
                    isPaused={this.state.isPaused}
                  />
                </div>
                :
                this.state.promocao.map(item => (
                  <CardPromo
                    distancia="1.2"
                    local={item.salao_ende}
                    procedimento={item.nome}
                    desconto={item.promocao_desconto}
                    emoji={2}
                    logo={item.promocao_logo}
                    img={item.promocao_foto}
                    id={item.salao_codigo}
                  />
                ))
            }
          </div>
          <div className="fadeInRight" />
        </div>
      </section>
    )
  }
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Slider);

