/**
 * ACTIONS TYPES
 */
export enum UserTypes {
  DO_LOGIN = '@repositories/DO_LOGIN',
  DO_LOGOUT = '@repositories/DO_LOGOUT',
}

/**
 * Data types
 */
export interface User {
  codigo: number
  nome: string
  email: string
  cpf: string
  status: boolean
  fgFuncionario?: boolean
  avatar?: string
  empresa?: number
}

/**
 * State types
 */
export interface UserState {
  readonly data: User
  readonly loading: boolean
  readonly error: boolean
}