import React from 'react';
import './index.scss';

import Lottie from 'react-lottie';

//Componentes
import Header from '../../../components/header';
import Footer from '../../../components/Footer';
import Logo from '../../../assets/images/turnau-pro-logo.png';

//Calling bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
//Calling WOWjs
import WOW from 'wowjs';

import Usuario from '../../assets/images/usuario.png';



class Contacto extends React.Component {

    state: any = null;

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    // Defining WOW 
    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();
    }

    render() {
        return (
            <>
                <Header
                    registra={false}
                    login={false}
                    notificacoes={true}
                />

                <section className="container-contacto">
                    <div className="row-contacto">
                        <div className="header-perfil wow fadeInUp">
                            <i className="fas fa-user"></i>
                            <h5>Contacto</h5>
                        </div>
                        <img src={Logo} className="logo wow fadeInUp" />
                        <p className="wow fadeInUp" style={{ color: '#FFFFFF', marginBottom: '50px', }}>Como está tu dia? Te dejemos un espacio para que puedas enviarnos tus dudas o temas particulares</p>
                        <div className="input-box wow fadeInUp">
                            <small>Nobre*</small>
                            <input type="text" />
                        </div>
                        <div className="input-box wow fadeInUp">
                            <small>Correo electrónico*</small>
                            <input type="email" />
                        </div>
                        <div className="input-box wow fadeInUp">
                            <small>Télefono</small>
                            <input type="phone" />
                        </div>
                        <div className="input-box wow fadeInUp">
                            <small>Menjase*</small>
                            <input type="text" />
                        </div>
                        <button className="btn_primary wow fadeInUp" style={{ width: '100%', marginTop: 25 }}>
                            <i className="fas fa-save"></i>
                            <p>Guardar</p>
                        </button>
                    </div>

                </section>

                <div className="spacing" />

                <Footer />
            </>
        )
    }
}

export default Contacto