import React from 'react';
import './index.scss';

//Componentes
import Header from '../../../components/header';
import Footer from '../../../components/Footer';
import CardPromos from '../../../components/CardPromos'

//Calling WOWjs
import WOW from 'wowjs';

//Redux
import Api from '../../../services/api';
import ApiV2 from '../../../services/api-v2';
import { Diversos } from '../../../services/diversos';
import { ApplicationState } from '../../../store';
import { User } from '../../../store/ducks/user/types';
import * as UserActions from '../../../store/ducks/user/actions';
import { Param } from '../../../store/ducks/param/types';
import * as ParamActions from '../../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Auth } from '../../../store/ducks/auth/types';
import Informacoes from '../../MenuCliente';
import moment from 'moment';
import { convertTypeAcquisitionFromJson } from 'typescript';

interface StateProps {
    user: User;
    param: Param;
}

interface DispatchProps {
    doLogin(user: User): void;
    setParam(param: Param): void;
    setParam(param: Param): void;
}

interface OwnProps { }

type Props = StateProps & DispatchProps & OwnProps;

class NovaPromocao extends React.Component<Props> {

    state: any = null;
    api: any;
    apiv2: any;
    userRef: any;

    constructor(props) {
        super(props);
        this.api = new Api();
        this.apiv2 = new ApiV2();
        this.state = {
            promos: [],
        }
    }

    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();

        if (this.props.user.empresa == 0) {
            window.location.href = '/home-cliente'
        }

        this.getPromos();
    }

    private async getPromos() {
        moment.locale('pt-BR');
        const self = this;

        let param = {
            profissional: self.props.user.codigo,
            page: self.state.page,
        };

        try {
            const { data } = await this.apiv2.get(
                `/salon/${this.props.user.codigo}/service/1/promotions`,
                param,
            );

            if (!data.status) {
                console.log(data.msg)
            }

            console.log(data.msg.data)

            this.setState({
                promos: data.msg.data,
            });
        } catch (e) {
            console.log(e);
            if (self.state.page <= 1) self.setState({ regs: [] });
        } finally {

        }
    }

    render() {
        return (
            <>
                <Header
                    registra={false}
                    login={false}
                    notificacoes={true}
                />
                <section className="containerPromos">
                    <div className="header-funcionarios wow fadeInUp">
                        <i className="fas fa-star "></i>
                        <h5>Promociones</h5>
                    </div>
                    <div className="rowPromos">
                        {
                            this.state.promos.lenght <= 0 ?
                                <p>Sem promocoes</p>
                                :
                                this.state.promos.map(item => (
                                    <CardPromos
                                        foto={item.foto}
                                        servico={!item.servicoSalaoDados ? null : item.servicoSalaoDados.servicoDados.SERVICO}
                                        inicio={moment(item.data_inicio).format('DD/MM/YYYY')}
                                        final={moment(item.data_fim).format('DD/MM/YYYY')}
                                        valor={!item.servicoSalaoDados ? null : item.servicoSalaoDados.servicoDados.VALOR}
                                        id={item.id}
                                        idServico={!item.servicoSalaoDados ? null : item.servicoSalaoDados.servicoDados.CODIGO}
                                    />
                                ))
                        }
                        <a href="/nueva-promocion">
                            <button className="btn_fl">
                                <i className="fas fa-plus"></i>
                                <p>Nueva promoción</p>
                            </button>
                        </a>
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.data,
    param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NovaPromocao);