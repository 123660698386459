import React from "react";
import "./index.scss";

import swal from "sweetalert";
//Componentes
import Header from "../../../components/header";
import Footer from "../../../components/Footer";

//Calling bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
//Calling WOWjs
import WOW from "wowjs";

//Redux
import Api from "../../../services/api";
import ApiV2 from "../../../services/api-v2";
import { ApplicationState } from "../../../store";
import { User } from "../../../store/ducks/user/types";
import * as UserActions from "../../../store/ducks/user/actions";
import { Param } from "../../../store/ducks/param/types";
import * as ParamActions from "../../../store/ducks/param/actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import Lottie from "react-lottie";
//Animaceos Lottie
import animationData from "../../../animations/loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  setParam(param: Param): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class TrocarSenha extends React.Component<Props> {
  state: any = null;
  api: any;
  apiv2: any;
  userRef: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.apiv2 = new ApiV2();
    this.state = {
      senha: "",
      novaSenha: "",
      confNovaSenha: "",
      loading: false,
    };
  }

  // Defining WOW
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();

    if (this.props.user.empresa != 0) {
      window.location.href = '/home-salon'
    }
  }

  private async submitForm() {
    const self = this;
    console.log("passo 1");

    this.setState({ loading: true });

    if (
      self.state.senha == "" ||
      self.state.novaSenha == "" ||
      self.state.confNovaSenha == ""
    ) {
      swal("Atencion", "Atencion Campos obligatorios no rellenados.", "error");
      return;
    }

    console.log("passo 2");

    if (self.state.novaSenha != self.state.confNovaSenha) {
      swal(
        "Atencion",
        "Atencion Confirmación de contraseña inválida.",
        "error"
      );
      return;
    }

    console.log("passo 3");

    try {
      let param = {
        email: self.props.user.email,
        senha: self.state.senha,
      };

      console.log(param);

      const response = await self.api.post("/User/login", param);
      console.log(response);
      if (response.data.status === false) {
        swal(
          "Atencion",
          "No se puede actualizar la información, intente nuevamente.",
          "error"
        );
        return;
      }
      let param2 = {
        perfil: 1,
        codigo: this.props.user.codigo,
        senha: this.state.novaSenha,
      };
      console.log(param2);

      const response2 = await self.api.post("/User/update", param2);
      console.log(response2);
      if (response2.data.status === false) {
        swal(
          "Atencion",
          "No se puede actualizar la información, intente nuevamente.",
          "error"
        );
        return;
      }
      swal("Exito", "Información actualizada con éxito", "success");
    } catch (e) {
      console.error(e);
      swal(
        "Atencion",
        "No se puede actualizar la información, intente nuevamente",
        "error"
      );
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <>
        <Header registra={false} login={false} notificacoes={true} />
        {this.state.loading == true ? (
          <div className="modal-content">
            <Lottie
              options={defaultOptions}
              height={250}
              width={250}
              isStopped={this.state.isStopped}
              isPaused={this.state.isPaused}
            />
          </div>
        ) : null}
        <section className="container-contrasena">
          <div className="row-contrasena">
            <div className="header-perfil wow fadeInUp">
              <i className="fas fa-unlock"></i>
              <h5>Cambiar Contraseña</h5>
            </div>
            <div className="input-box wow fadeInUp">
              <small>Contraseña*</small>
              <input
                type="password"
                value={this.state.senha}
                onChange={(event) =>
                  this.setState({ senha: event.target.value })
                }
              />
            </div>
            <div className="input-box wow fadeInUp">
              <small>Nueva Contraseña*</small>
              <input
                type="password"
                value={this.state.novaSenha}
                onChange={(event) =>
                  this.setState({ novaSenha: event.target.value })
                }
              />
            </div>
            <div className="input-box wow fadeInUp">
              <small>Confirmación de contraseña</small>
              <input
                type="password"
                value={this.state.confNovaSenha}
                onChange={(event) =>
                  this.setState({ confNovaSenha: event.target.value })
                }
              />
            </div>
            <button
              className="btn_primary wow fadeInUp"
              style={{ width: "100%", marginTop: 25 }}
              onClick={() => this.submitForm()}
              disabled={this.state.loading}
            >
              {/* <i className="fas fa-save"></i> */}
              <p>Guardar</p>
            </button>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TrocarSenha);
