import React from 'react';
import './index.scss';
import Tpromo from '../../assets/images/t-promo.svg';
import Festa from '../../assets/images/emojis/festa.png';
import Bomba from '../../assets/images/emojis/bomba.png';
import { Link } from 'react-router-dom'

interface Props {
    distancia: String,
    local: String,
    procedimento: String,
    desconto: String,
    emoji: Number,
    logo: any,
    img: any,
    id: String,
}

export class CardPromo extends React.Component<Props> {

    state: any = null;

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <Link className="card-promo"
                to={{
                    pathname: '/salon/' + this.props.id,
                    state: { fotoCapa: this.props.img }
                }}

                href={'/salon/' + this.props.id}>
                <div className="content" style={{ backgroundImage: `url(${this.props.img})` }}>
                    <div className="header-content">
                        <img src={this.props.logo} />
                    </div>
                    <div className="layer">
                        <img src={Tpromo} />
                    </div>
                    <div className="footer-content">
                        <p className="procedimento">{this.props.procedimento}</p>
                        <div className="promo-area">
                            <p className="desconto">{this.props.desconto.slice(0, -3)}%</p>
                            <p className="desc">OFF</p>
                            {
                                this.props.emoji == 1 ?
                                    <img src={Bomba} />
                                    :
                                    this.props.emoji == 2 ?
                                        <img src={Festa} />
                                        :
                                        null
                            }
                        </div>
                    </div>
                </div>
                <div className="layer-2">
                    <div className="local">{
                        this.props.local ?
                            <>
                                <i className="fas fa-map-marker-alt"></i>
                                <p>{this.props.local}</p>
                            </>
                            :
                            null
                    }
                    </div>
                    <p>{this.props.distancia} Km</p>
                </div>
            </Link>
        )
    }
};

export default CardPromo;

