import React from 'react';
import './index.scss';


interface Props {
    modal:boolean,
}

export class ContainerModal extends React.Component<Props> {

    state: any = null;

    constructor(props) {
        super(props);
        this.state = {
            foto:require('../../assets/images/user.png'),
            modalState:this.props.modal,
        }
    }

    render() {
        return (
            this.state.modalState == true ?
            <section className="container-modal-notificacao">
                <div className="box-notificacao">
                    <h5>Cliente</h5>
                    <section className="area-foto-usuario-modal">
                        <div className="foto-usuario-modal" style={{backgroundImage: `url(${this.state.foto})`}}/>
                        <p>Maria Suarez</p>
                    </section>
                    <p>Lifting de pestañas</p>
                    <p>11:00am</p>
                    <button className="btn_aceptar">
                        <p>Aceptar</p>
                    </button>
                    <button className="btn_negar" onClick={() => this.setState({modalState:false})}>
                        <p>Rechazar</p>
                    </button>
                    <button className="btn_reprogramar">
                        <p>Reprogramar</p>
                    </button>
                </div>
            </section>
            :
            null
        )
    }
};

export default ContainerModal;

