import React from 'react';
import './index.scss';

//Componentes
import Header from '../../../components/header';
import Footer from '../../../components/Footer';
import CardTratamento from '../../../components/CardTratamento';

//Calling bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
//Calling WOWjs
import WOW from 'wowjs';

//Redux
import Api from '../../../services/api';
import ApiV2 from '../../../services/api-v2';
import { Diversos } from '../../../services/diversos';
import { ApplicationState } from '../../../store';
import { User } from '../../../store/ducks/user/types';
import * as UserActions from '../../../store/ducks/user/actions';
import { Param } from '../../../store/ducks/param/types';
import * as ParamActions from '../../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Auth } from '../../../store/ducks/auth/types';
import Informacoes from '../../MenuCliente';

import Lottie from 'react-lottie';
//Animaceos Lottie
import animationData from '../../../animations/loading.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


interface StateProps {
    user: User;
    param: Param;
}

interface DispatchProps {
    doLogin(user: User): void;
    setParam(param: Param): void;
    setParam(param: Param): void;
}

interface OwnProps { }

type Props = StateProps & DispatchProps & OwnProps;


class Tratamentos extends React.Component<Props> {

    state: any = null;
    api: any;
    apiv2: any;
    userRef: any;

    constructor(props) {
        super(props);
        this.api = new Api();
        this.apiv2 = new ApiV2();
        this.state = {
            limit: 0,
            servicos: [],
        }
    }

    // Defining WOW 
    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();

        if (this.props.user.empresa == 0) {
            window.location.href = '/home-cliente'
        }

        this.getServicos()
    }

    private async getServicos() {
        const self = this;

        let param = {
            profissional: self.props.user.codigo,
            limit: self.state.limit,
        };

        try {
            const { data } = await self.api.post(
                '/Servico/getAllByProfissional',
                param,
            );

            if (data.status == true) {

                this.setState({ servicos: data.msg });
            }
        } catch (e) {
            console.log(e);

        } finally {

        }
    }


    render() {

        return (
            <>
                <Header
                    registra={false}
                    login={false}
                    notificacoes={true}
                />
                <section className="container-tratamentos">
                    <div className="row-tratamentos">
                        <div className="header-tratamentos">
                            <h5>Mis Servicios</h5>
                            <a className="btn_primary" href="/nuevo-tratamiento">
                                <p>Nuevo Servicio</p>
                            </a>
                        </div>
                        <div className="servicos-container">
                            <div className="container-inf">
                                {
                                    this.state.servicos.map(item => (
                                        <CardTratamento
                                            titulo={item.nome}
                                            valor={item.valor}
                                            tempo={item.tempo}
                                            descricao={item.descricao}
                                            catetegoria={item.categoria_nome}
                                            codigo={item.codigo}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <div className="spacing" />
                <Footer />
            </>
        )
    }
}


const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.data,
    param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Tratamentos);