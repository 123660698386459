import React from 'react';
import './index.scss';

//Redux
import Api from '../../services/api';
import { Diversos } from '../../services/diversos';
import { ApplicationState } from '../../store';
import { User } from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import { Param } from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';

//Componentes
import Header from '../../components/header';
import Footer from '../../components/Footer';
import Buscar from '../../components/Buscar';
import SideMenu from '../../components/SideMenu';
import Card from '../../components/CardSalao';
import Loading from '../../components/loading';

//Calling bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
//Calling WOWjs
import WOW from 'wowjs';

import Lottie from 'react-lottie';
//Animaceos Lottie
import animationData from '../../animations/loading.json';

declare var window;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
}

type Props = StateProps & DispatchProps;

class BuscarPage extends React.Component<Props> {
  api: any = null;
  state: any = {};

  constructor(props) {
    super(props);
    this.api = new Api();
    const { termo } = props.match.params;
    this.state = {
      paramTermo: termo,
      produtos: [],
      isLoading: true,
    };
  }

  // Defining WOW 
  componentDidMount() {
    new WOW.WOW({
      live: false
    }).init();

    setTimeout(() => {
      this.buscar();
    }, 500);
  }

  private async buscar() {
    const self = this;

    self.setState({ isLoadingPromocao: true }, () =>
      this.props.setParam({ ...this.props.param, isLoading: true }),
    );

    let param = {
      termo: this.state.paramtermo,
      limit: 10,
    };

    try {
      const { data } = await self.api.post('/Servico/search', param);

      if (data.status = false) {
        console.log('Nenhum servico localizado');
      } else {
        self.setState({ produtos: data.msg });
        console.log(data)
      }
    } catch (e) {
      console.log(e);
      self.setState({ produtos: [] });
    } finally {
      this.setState({ isLoading: false })
    }
  }



  render() {
    return (

      this.state.loading == true ?
        <Loading />
        :
        <>
          <Header
            registra={false}
            login={false}
            notificacoes={true}
          />

          <div className="home-cliente-main">
            <SideMenu />
            <div className="container-home-usuario">
              <div className="box-home">
                <Buscar />
                <div className="box-tit-busca">
                  <h5>Resultados para busca {this.state.paramTermo}</h5>
                </div>
                <div className="container-resultado-busca">

                  {
                    this.state.isLoading == true ?
                      <Lottie options={defaultOptions}
                        height={250}
                        width={250}
                        isStopped={this.state.isStopped}
                        isPaused={this.state.isPaused}
                      />
                      :
                      this.state.produtos.map(item => (
                        <Card
                          nome={item.salao_nome}
                          distancia="1.2"
                          procedimento={item.nome}
                          local={item.salao_ende}
                          img={item.promocao_foto}
                          id={item.codigo}
                        />
                      ))
                  }

                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BuscarPage);