
import Geolocation from 'react-native-geolocation-service';

export const Diversos = {

  convStrToUrlMaps: (text: string) => {
    let aux: any = text.replace(/[^\w\s]/gi, '');
    aux = aux.split(' ');
    return aux.join("+");
  },

  captalize: (text: string) => {
    return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
  },

  getUFs: () => {
    // CRIA LISTA DE ESTADOS
    return [
      { value: "--", label: "--" },
      { value: "CA", label: "CABA" },
      { value: "BA", label: "Buenos Aires" },
      { value: "CT", label: "Catamarca" },
      { value: "CC", label: "Chaco" },
      { value: "CH", label: "Chubut" },
      { value: "CB", label: "Córdoba" },
      { value: "CN", label: "Corrientes" },
      { value: "ER", label: "Entre Ríos" },
      { value: "FM", label: "Formosa" },
      { value: "JY", label: "Jujuy" },
      { value: "LP", label: "La Pampa" },
      { value: "LR", label: "La Rioja" },
      { value: "MZ", label: "Mendoza" },
      { value: "MN", label: "Misiones" },
      { value: "NQ", label: "Neuquén" },
      { value: "RN", label: "Río Negro" },
      { value: "SA", label: "Salta" },
      { value: "SJ", label: "San Juan" },
      { value: "SL", label: "San Luis" },
      { value: "SC", label: "Santa Cruz" },
      { value: "SF", label: "Santa Fe" },
      { value: "SE", label: "Santiago del Estero" },
      { value: "TF", label: "Tierra del Fuego" },
      { value: "TM", label: "Tucumán" },
    ];
  },

  getSexos: () => {
    return [
      { value: 1, label: "Masculino" },
      { value: 2, label: "Feminino" },
      { value: 3, label: "Não informar" }
    ];
  },


  /**
  *   left padding s with c to a total of n chars
  */
  padding_left: (s, c, n) => {
    if (!s || !c || s.length >= n) {
      return s;
    }
    var max = (n - s.length) / c.length;
    for (var i = 0; i < max; i++) {
      s = c + s;
    }
    return s;
  },

  /**
  *   right padding s with c to a total of n chars
  */
  padding_right: (s, c, n) => {
    if (!s || !c || s.length >= n) {
      return s;
    }
    var max = (n - s.length) / c.length;
    for (var i = 0; i < max; i++) {
      s += c;
    }
    return s;
  },

  /**
  *   FUNÇÃO PARA RETORNO APENAS NUMEROS DE UMA STRING
  *   @param string
  *   @return int pertencentes a string
  */

  getnums: (str) => {
    var num = str.toString().replace(/[^0-9]/g, '');
    return num;
  },

  /**
  *   FUNÇÃO PARA CONVERTER STRING DATA DO TIPO YYYY-MM-DD HH:MM:SS EM DD/MM/YYYY HH:MM:SS
  *   @param string tipo YYYY-MM-DD HH:MM:SS
  *   @return DD/MM/YYYY HH:MM:SS
  */

  convData: (data) => {
    if ((data == "") || (data == null))
      return "// ::";
    // return data[8] + data[9] + "/" + data[5] + data[6] + "/" + data[0] + data[1] + data[2] + data[3] + " " + data[11] + data[12] + ":" + data[14] + data[15] + ":" + data[17] + data[18];
    // return data.substr(8, 2) + "/" + data.substr(5, 2) + "/" + data.substr(0, 4) + "/" + data.substr(11, 2) + ":" + data.substr(14, 2) + ":" + data.substr(17, 2);

    if (data.length > 10)
      return data.substr(8, 2) + "/" + data.substr(5, 2) + "/" + data.substr(0, 4) + " " + data.substr(11, 2) + ":" + data.substr(14, 2);
    else
      return data.substr(8, 2) + "/" + data.substr(5, 2) + "/" + data.substr(0, 4);
  },

  convDataToBD: (data) => {
    if ((data == "") || (data == null))
      return "--";
    // return data[8] + data[9] + "/" + data[5] + data[6] + "/" + data[0] + data[1] + data[2] + data[3] + " " + data[11] + data[12] + ":" + data[14] + data[15] + ":" + data[17] + data[18];
    return data.substr(6, 4) + "-" + data.substr(3, 2) + "-" + data.substr(0, 2);
  },

  /**
  *    FUNÇÃO PARA FORMATAR STRING DE VALOR NO FORMATO DE MOEDA (IGUAL AO PHP)
  *    @param float valor - Ex.: 4.40
  *    @return string valor - E.: 4,40
  */
  number_format: (number, decimals, dec_point, thousands_sep) => {
    number = (number + '')
      .replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
      dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
      s = [''],
      toFixedFix = function (n, prec) {
        var k = Math.pow(10, prec);
        return '' + (Math.round(n * k) / k)
          .toFixed(prec);
      };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
      .split('.');
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '')
      .length < prec) {
      s[1] = s[1] || '';
      s[1] += new Array(prec - s[1].length + 1)
        .join('0');
    }
    return s.join(dec);
  },

  /**
  *	FUNÇÃO PARA VERIFICA SE STRING DE E-MAIL É VÁLIDO
  *	@param string email
  *	@return bool
  */

  validateEmail: (sEmail) => {
    var filter = /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,4}$/;
    if (filter.test(sEmail))
      return true;
    else
      return false;
  },

  /**
  *	FUNÇÃO PARA VALIDAR CNPJ
  *	@param string cnpj ex.: 00.000.000/0000-00
  *	@return bool
  */

  validateCNPJ: (sCNPJ) => {
    var cnpjValor = sCNPJ;
    let cnpj = cnpjValor.replace(/[^\d]+/g, '');
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj == '') return false;
    if (cnpj.length != 14)
      return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" || cnpj == "11111111111111" || cnpj == "22222222222222" || cnpj == "33333333333333" || cnpj == "44444444444444" || cnpj == "55555555555555" || cnpj == "66666666666666" || cnpj == "77777777777777" || cnpj == "88888888888888" || cnpj == "99999999999999")
      return false;

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
      return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
      return false;

    return true;
  },

  /**
  *   FUNÇÃO PARA VALIDAR CPF
  *   @param string cpf ex.: 000.000.000-00
  *   @return bool
  */

  validateCPF: (sCPF) => {
    var cpfValor = sCPF;
    let cpf = cpfValor.replace(/[^\d]+/g, '');
    if (cpf == '') return false;
    // Elimina CPFs invalidos conhecidos
    if (cpf.length != 11 || cpf == "00000000000" || cpf == "11111111111" || cpf == "22222222222" || cpf == "33333333333" || cpf == "44444444444" || cpf == "55555555555" || cpf == "66666666666" || cpf == "77777777777" || cpf == "88888888888" || cpf == "99999999999")
      return false;

    // Valida 1o digito
    let add = 0;
    for (var i = 0; i < 9; i++)
      add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
      rev = 0;
    if (rev != parseInt(cpf.charAt(9)))
      return false;
    // Valida 2o digito
    add = 0;
    for (i = 0; i < 10; i++)
      add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
      rev = 0;
    if (rev != parseInt(cpf.charAt(10)))
      return false;
    return true;
  },

  /**
  * FUNCAO PARA SOMAR DAYS EM DATE
  * @param date
  * @param days
  */
  addDays: (date, days) => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  },

  /**
   * FUNCAO PARA CALCULAR IDADE A PARTIR DA DATA DE NASCIMENTO
   * @param nascimento
   */
  getIdade: (nascimento: Date): number => {
    let hoje = new Date;
    // nascimento = new Date($("#dtnascimento").val());
    var diferencaAnos = hoje.getFullYear() - nascimento.getFullYear();
    if (new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()) < new Date(hoje.getFullYear(), nascimento.getMonth(), nascimento.getDate()))
      diferencaAnos--;
    return diferencaAnos;
  },

  /**
  * FUNCAO PARA CONVERTER STRING DE PRECO EM FLOAT
  * @param preco
  */
  convPrecoToFloat: (preco: any): any => {
    // let aux = preco.replace(/./g, "");
    // aux = aux.replace(/,/g, ".");
    return parseFloat(preco);
  },

  /**
   *  CRIA MASCARA EM CAMPO PARA MOEDA BRL
   */
  maskPreco: (text: string) => {
    let money = text.replace(/[^\d]/g, '');
    let tmp = money + "";
    tmp = money.replace(/([0-9]{2})$/g, ",$1");
    if (tmp.length > 6)
      tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    return tmp;
  },

  /**
   * CRIA MASCARA EM CAMPO PARA CPF
   */
  maskCPF: (text: string) => {
    let cpf = text.replace(/[^\d]/g, '');
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  },

  /**
   * APLICA MASCARA DE DATA FORMATO DD/MM/YYYY
   */
  maskData: (text: string) => {
    let cpf = text.replace(/[^\d]/g, '');
    return cpf.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
  },

  maskCreditData: (text: string) => {
    let cpf = text.replace(/[^\d]/g, '');
    return cpf.replace(/(\d{2})(\d{2})/, "$1/$2");
  },

  /**
   * APLICA MASCARA EM CARTAO DE CREDITO
   */
  maskCreditCard: (text: string) => {

    if ((typeof text != 'string') || (!text))
      return text;

    let brand = Diversos.getBrandCard(text);

    if (!brand)
      return text;

    if (brand == 'visa') {
      let tmp = Diversos.padding_right(text, '_', 16);
      let tmp2 = '';

      for (var i = 0; i < tmp.length; i++) {
        tmp2 += `${tmp[i]}`;

        if (((i % 4) == 0) && (i > 0) && (i < (tmp.length - 1)))
          tmp2 += `_`;
      }

      return tmp2;
    }

    return text;
  },

  /**
   * PESQUISA BANDEIRA DO CARTAO
   */
  getBrandCard: (text: any) => {

    if (!text)
      return false;

    let cardnumber = text.replace(/[^0-9]+/g, '');

    let cards = {
      visa: /^4[0-9]{12}(?:[0-9]{3})/,
      mastercard: /^5[1-5][0-9]{14}/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      amex: /^3[47][0-9]{13}/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
      hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
      elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}/,
      aura: /^(5078\d{2})(\d{2})(\d{11})$/
    };

    for (var flag in cards) {
      if (cards[flag].test(cardnumber)) {
        return flag;
      }
    }

    return false;
  },

  // decimalAdjust: (type: string, value: number, exp: number) => {
  //   // Se exp é indefinido ou zero...
  //   if (typeof exp === 'undefined' || +exp === 0) {
  //     return Math[type](value);
  //   }
  //   value = +value;
  //   exp = +exp;
  //   // Se o valor não é um número ou o exp não é inteiro...
  //   if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
  //     return NaN;
  //   }
  //   // Transformando para string
  //   value = value.toString().split('e');
  //   value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
  //   // Transformando de volta
  //   value = value.toString().split('e');
  //   return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
  // },

  getTituloStatus: (status: number) => {
    switch (status) {
      case 1: return 'Pendente';
      case 2: return 'Confirmado';
      case 3: return 'Em Atendimento';
      case 4: return 'Concluído';
      case 5: return 'Cancelado';
      default: return '';
    }
  },

  toSeoUrl: (url: any) => {
    if (typeof url === "undefined") {
      return "";
    }

    return url
      .toString() // Convert to string
      .normalize("NFD") // Change diacritics
      .replace(/[\u0300-\u036f]/g, "") // Remove illegal characters
      .replace(/\s+/g, "-") // Change whitespace to dashes
      .toLowerCase() // Change to lowercase
      .replace(/&/g, "-and-") // Replace ampersand
      .replace(/[^a-z0-9\-]/g, "-") // Remove anything that is not a letter, number or dash
      .replace(/-+/g, "-") // Remove duplicate dashes
      .replace(/^-*/, "") // Remove starting dashes
      .replace(/-*$/, ""); // Remove trailing dashes
  },

  // getLocation: async () => {
  //   return new Promise(async function (resolve, reject) {

  //     if (Platform.OS === 'ios') {
  //       const hasPermission = await Geolocation.requestAuthorization('whenInUse');

  //       if ((hasPermission === 'denied') || (hasPermission === 'restricted')) {
  //         reject(`Ubicación no autorizada`);
  //       }
  //     }
  //     else {
  //       try {
  //         const hasPermissionAndroid = await PermissionsAndroid.request(
  //           PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
  //           {
  //             title: 'Autorizar ubicación',
  //             message: '¡Necesitamos acceder a su ubicación para buscar los centros más cercanos!',
  //             buttonNeutral: "Decide después",
  //             buttonNegative: "No",
  //             buttonPositive: "OK"
  //           }
  //         );

  //         if (hasPermissionAndroid !== PermissionsAndroid.RESULTS.GRANTED) {
  //           reject(`Ubicación no autorizada`);
  //         }
  //       }
  //       catch (e) {
  //         reject(`Ubicación no autorizada`);
  //       }
  //     }

  //     Geolocation.getCurrentPosition(
  //       (param: any) => {
  //         resolve({
  //           lat: param.coords.latitude,
  //           lng: param.coords.longitude,
  //         });
  //       },
  //       (e: any) => {
  //         reject(`Falha ao capturar localizacao ${e.message}`);
  //       },
  //       {
  //         enableHighAccuracy: true,
  //         timeout: 15000,
  //         maximumAge: 10000
  //       }
  //     );
  //   });
  // }
}
