import React from 'react';
import './index.scss';

//Componentes
import Header from '../../../components/header';
import Footer from '../../../components/Footer';

//Calling bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
//Calling WOWjs
import WOW from 'wowjs';
import moment from 'moment';


//Redux
import Api from '../../../services/api';
import ApiV2 from '../../../services/api-v2';
import { ApplicationState } from '../../../store';
import { User } from '../../../store/ducks/user/types';
import * as UserActions from '../../../store/ducks/user/actions';
import { Param } from '../../../store/ducks/param/types';
import * as ParamActions from '../../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import Lottie from 'react-lottie';
//Animaceos Lottie
import animationData from '../../../animations/loading.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  setParam(param: Param): void;
}

interface OwnProps { }

type Props = StateProps & DispatchProps & OwnProps;

class Detalhes extends React.Component<Props> {

  state: any = null;
  api: any;
  apiv2: any;
  userRef: any;


  constructor(props) {
    super(props);
    this.api = new Api();
    this.apiv2 = new ApiV2();
    const { id } = props.match.params;
    this.state = {
      loading: true,
      modalLoading: false,
      idNot: id,
      detalhes: '',
      cliente: '',
      agenda: '',
      modalRea: false,

      idServico: '',

      data: '',
      hora: '',
      horarios: [],

      modalConfirmar: false,
      modalCancelar: false,
      modalFinalizar: false,
    }
  }

  // Defining WOW 
  componentDidMount() {
    new WOW.WOW({
      live: false
    }).init();

    if (this.props.user.empresa == 0) {
      window.location.href = '/home-cliente'
    }

    this.getInfo()
    this.setRegLido()
  }

  private async setRegLido() {
    const self = this;

    moment.locale('pt-BR');

    try {
      await self.apiv2.put(`/notification/${this.state.idNot}`, {
        lido: 'S',
        lido_data: moment().format('YYYY-MM-DD HH:mm:ss'),
      });
    } catch (e) {
      console.log('No se pudo marcar como leído');
    }
  }

  private async getInfo() {
    const self = this;

    try {
      const { data } = await self.apiv2.get(`/notification/${this.state.idNot}`);

      if (data.status === false) {
        alert('Nao foi possivel')
      } else {
        self.setState({ detalhes: data.msg });

        this.getDadosCliente();

        this.getServicoDados();

      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loading: false })
    }
  }

  private async getDadosCliente() {
    const self = this;

    let param = {
      codigo: this.state.detalhes.caracteristicaDados.CLIENTE,
      perfil: 1,
    }

    try {
      const { data } = await self.api.post('/User/getById', param);

      if (data.status === false) {
        alert('Nao foi possivel')
      } else {
        self.setState({ cliente: data.msg });
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  private async getServicoDados() {
    const self = this;

    try {
      self.setState({ agendaIsLoading: true });

      const response = await self.apiv2.get(
        `/schedule/${this.state.detalhes.caracteristica_id}`,
      );

      if (!response.data.status) {
        console.log('No se puede cargar la información, intente nuevamente.',);
      }
      self.setState({ agenda: response.data.msg[0] });

    } catch (e) {
      console.log(e);
      self.setState({ agenda: null });
    } finally {

    }
  }

  private async handleConfirmaAgenda() {
    const self = this;

    this.setState({ modalLoading: true })

    let param = {
      cliente: self.state.agenda.CLIENTE,
      data: self.state.agenda.DATA,
      status: 2,
      comprovante: self.state.agenda.COMPROVANTE,
      obs: self.state.agenda.OBS,
      desconto: self.state.agenda.DESCONTO,
      formapg: self.state.agenda.FORMAPG,
      cupom: self.state.agenda.CUPOM,
      hora: self.state.agenda.HORA,
      bandeira: self.state.agenda.BANDEIRA,
      parcelas: self.state.agenda.PARCELAS,
      autorizacao: self.state.agenda.AUTORIZACAO,
      nsu: self.state.agenda.NSU,
      tid: self.state.agenda.TID,
      gateway: self.state.agenda.GATEWAY,
      lido: 1,
      salao: self.props.user.empresa,
      empresa: self.props.user.codigo,
    };

    try {
      const { data } = await self.apiv2.put(
        `/schedule/${this.state.agenda.PEDIDO}`,
        param,
      );

      if (data.status === false) {
        alert('No se puede actualizar la información, intente nuevamente.');
      }
    } catch (e) {
      alert('Atención No se puede actualizar la información, intente nuevamente');
    } finally {
      this.setState({ modalLoading: false })
      window.location.reload();
    }
  }

  private async handleFinalizaAgenda() {
    const self = this;

    this.setState({ modalLoading: true })

    let param = {
      cliente: self.state.agenda.CLIENTE,
      data: self.state.agenda.DATA,
      status: 4,
      comprovante: self.state.agenda.COMPROVANTE,
      obs: self.state.agenda.OBS,
      desconto: self.state.agenda.DESCONTO,
      formapg: self.state.agenda.FORMAPG,
      cupom: self.state.agenda.CUPOM,
      hora: self.state.agenda.HORA,
      bandeira: self.state.agenda.BANDEIRA,
      parcelas: self.state.agenda.PARCELAS,
      autorizacao: self.state.agenda.AUTORIZACAO,
      nsu: self.state.agenda.NSU,
      tid: self.state.agenda.TID,
      gateway: self.state.agenda.GATEWAY,
      lido: 1,
      salao: self.props.user.empresa,
      empresa: self.props.user.codigo,
    };

    try {
      const { data } = await self.apiv2.put(
        `/schedule/${this.state.agenda.PEDIDO}`,
        param,
      );

      if (data.status === false) {
        alert('No se puede actualizar la información, intente nuevamente.');
      } else {

      }
    } catch (e) {
      alert('Atención No se puede actualizar la información, intente nuevamente');
    } finally {
      this.setState({ modalLoading: false })
      window.location.reload();
    }
  }

  private async handleCancelaAgenda() {
    const self = this;

    this.setState({ modalLoading: true })

    if (self.state.agenda.STATUS <= 1)
      alert('¿Confirma la cancelación del turno?')

    let param = {
      cliente: self.state.agenda.CLIENTE,
      data: self.state.agenda.DATA,
      status: 5,
      comprovante: self.state.agenda.COMPROVANTE,
      obs: self.state.agenda.OBS,
      desconto: self.state.agenda.DESCONTO,
      formapg: self.state.agenda.FORMAPG,
      cupom: self.state.agenda.CUPOM,
      hora: self.state.agenda.HORA,
      bandeira: self.state.agenda.BANDEIRA,
      parcelas: self.state.agenda.PARCELAS,
      autorizacao: self.state.agenda.AUTORIZACAO,
      nsu: self.state.agenda.NSU,
      tid: self.state.agenda.TID,
      gateway: self.state.agenda.GATEWAY,
      lido: 1,
      salao: self.props.user.empresa,
      empresa: self.props.user.codigo,
    };

    try {
      const { data } = await self.apiv2.put(
        `/schedule/${this.state.agenda.PEDIDO}`,
        param,
      );
      if (!data.status) {
        alert('No se puede actualizar la información, intente nuevamente.')
      }
    } catch (e) {
      alert('Atención No se puede actualizar la información, intente nuevamente');
    } finally {
      this.setState({ modalLoading: false })
      window.location.reload();
    }
  }

  private async getHorarios() {
    const self = this;

    let param = {
      salon: this.props.user.codigo,
      professional: 0,
      service: this.state.agenda.ADMPED2_SERVICO,
      date: this.state.data
    };

    try {
      const { data } = await self.apiv2.post('/schedules/availability', param,
      );

      if (data.status == true) {
        this.setState({ horarios: data.msg.agenda });
      } else {
        console.log('Error al obtener horarios.')
      }
    } catch (e) {
      console.log(e);
    } finally {

    }
  }

  private async handleReprogramaAgenda() {
    const self = this;

    this.setState({ modalLoading: true })

    let param = {
      empresa: self.props.user.empresa,
      cliente: self.state.agenda.CLIENTE,
      data: moment(self.state.data).format(
        'DD/MM/YYYY',
      ),
      hora: moment(self.state.hora).format(
        'HH:mm:ss',
      ),
      obs: self.state.agenda.OBS ? self.state.agenda.OBS : '',
      desconto: self.state.agenda.DESCONTO,
      formapg: self.state.agenda.FORMAPG,
      cupom: self.state.agenda.CUPOM,
      salao: self.props.user.empresa,
      fgLembreteEmail: self.state.agenda.FGLEMBRETEEMAIL,
      fgConfirmado: 'N',
    };

    try {
      const { data } = await self.apiv2.put(
        `/schedule/${self.state.agenda.PEDIDO}`,
        param,
      );

      if (!data.status) {
        alert('No se puede reprogramar, inténtalo de nuevo más tarde');
      }
      alert('Éxito Turno reprogramado con éxito. Esperando la confirmación del cliente.')

    } catch (e) {
      console.log(e.message);
      alert('No se puede reprogramar, inténtalo de nuevo más tarde')
    } finally {
      this.setState({ modalLoading: false })
      window.location.reload();
    }
  }

  render() {
    return (
      <>
        <Header
          registra={false}
          login={false}
          notificacoes={true}
        />
        {
          this.state.loading ?
            <div className="modal-content" >
              <Lottie options={defaultOptions}
                height={250}
                width={250}
                isStopped={this.state.isStopped}
                isPaused={this.state.isPaused}
              />
            </div>
            :
            <>
              {
                this.state.modalRea ?
                  <div className="modal-back">
                    <div className="modal-box">
                      <div className="header-modal">
                        <h5>Reprogramar turno</h5>
                      </div>
                      {
                        this.state.modalLoading ?
                          <Lottie options={defaultOptions}
                            height={250}
                            width={250}
                            isStopped={this.state.isStopped}
                            isPaused={this.state.isPaused}
                          />
                          :
                          <div className="dados-inf">
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }} className="wow fadeInUp">
                              <div className="input-box" style={{ marginRight: 5 }}>
                                <small>Fecha*</small>
                                <input
                                  type="date"
                                  value={this.state.data || ''}
                                  onChange={(event) => {
                                    this.setState({ data: event.target.value }, () => this.getHorarios());
                                  }}
                                />
                              </div>
                              <div className="input-box " style={{ marginLeft: 5 }}>
                                <small>Hora*</small>
                                <select name="hora" value={this.state.hora || ''} onChange={(event) => this.setState({ hora: event.target.value })}>
                                  <option value={0}>Selecciona hora</option>
                                  {
                                    this.state.horarios.map(item => (
                                      <option
                                        value={item.data}>{item.data.slice(item.data.length - 5)}</option>
                                    ))
                                  }
                                </select>
                              </div>
                            </div>
                            <button className="btn_blue" onClick={() => this.handleReprogramaAgenda()}>Reprogramar</button>
                            <button className="btn_cancel" onClick={() => this.setState({ modalRea: false })}>Volver</button>
                          </div>
                      }
                    </div>
                  </div>
                  :
                  null
              }
              {
                this.state.modalConfirmar ?
                  <div className="modal-back">
                    <div className="modal-box">
                      <div className="header-modal">
                        <h5>Confirmar turno</h5>
                      </div>
                      {
                        this.state.modalLoading ?
                          <Lottie options={defaultOptions}
                            height={250}
                            width={250}
                            isStopped={this.state.isStopped}
                            isPaused={this.state.isPaused}
                          />
                          :
                          <div className="dados-inf">
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: 25 }} className="wow fadeInUp">
                              <p>¿Quieres confirmar la hora de este trámite?</p>
                            </div>
                            <button className="btn_blue" onClick={() => this.handleConfirmaAgenda()}>Sí</button>
                            <button className="btn_cancel" onClick={() => this.setState({ modalConfirmar: false })}>No, vuelve</button>
                          </div>
                      }
                    </div>
                  </div>
                  :
                  null
              }
              {
                this.state.modalCancelar ?
                  <div className="modal-back">
                    <div className="modal-box">
                      <div className="header-modal">
                        <h5>Cancelar turno</h5>
                      </div>
                      {
                        this.state.modalLoading ?
                          <Lottie options={defaultOptions}
                            height={250}
                            width={250}
                            isStopped={this.state.isStopped}
                            isPaused={this.state.isPaused}
                          />
                          :
                          <div className="dados-inf">
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: 25 }} className="wow fadeInUp">
                              <p>¿Quieres cancelar este trámite?</p>
                            </div>
                            <button className="btn_blue" onClick={() => this.handleCancelaAgenda()}>Sí</button>
                            <button className="btn_cancel" onClick={() => this.setState({ modalCancelar: false })}>No, vuelve</button>
                          </div>
                      }
                    </div>
                  </div>
                  :
                  null
              }
              {
                this.state.modalFinalizar ?
                  <div className="modal-back">
                    <div className="modal-box">
                      <div className="header-modal">
                        <h5>Finalizar turno</h5>
                      </div>
                      {
                        this.state.modalLoading ?
                          <Lottie options={defaultOptions}
                            height={250}
                            width={250}
                            isStopped={this.state.isStopped}
                            isPaused={this.state.isPaused}
                          />
                          :
                          <div className="dados-inf">
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: 25 }} className="wow fadeInUp">
                              <p>¿Desea marcar este procedimiento como finalizado?</p>
                            </div>
                            <button className="btn_blue" onClick={() => this.handleFinalizaAgenda()}>Sí</button>
                            <button className="btn_cancel" onClick={() => this.setState({ modalFinalizar: false })}>No, vuelve</button>
                          </div>
                      }
                    </div>
                  </div>
                  :
                  null
              }
              }
              <section className="container-detalhes">
                <div className="row-detalhes">
                  <div className="header-perfil wow fadeInUp">
                    <h5>Detalle del procedimiento</h5>
                  </div>
                  <div className="box-inf-not">
                    <div className="header-inf-not">
                      <i className="fa fa-user" />
                      <h5>Datos del cliente</h5>
                    </div>
                    <div className="box-inf-tx">
                      <small>Cliente</small>
                      <p>{this.state.cliente.nome}</p>
                    </div>
                    <div className="box-inf-tx">
                      <small>Correo electrônico</small>
                      <p>{this.state.cliente.email}</p>
                    </div>
                  </div>
                  <div className="box-toggle wow fadeInUp">
                    <p>Notificar por correo electrónico</p>
                    <div className={`toggle-box ${this.state.detalhes.email == 'N' ? 'off-b' : 'on-b'}`} onClick={() => this.setState({ not: !this.state.not })}>
                      <div className={`toggle ${this.state.detalhes.email == 'N' ? 'off' : 'on'}`}></div>
                    </div>
                  </div>
                  <div className="box-inf-not">
                    <div className="header-inf-not">
                      <i className="fas fa-calendar " />
                      <h5>Detalles de la reserva</h5>
                    </div>
                    <div className="box-inf-tx">
                      <small>Fecha de compra</small>
                      <p>{this.state.detalhes.caracteristicaDados.DATA}</p>
                    </div>
                    <div className="box-inf-tx">
                      <small>Hora programada</small>
                      <p>{this.state.detalhes.caracteristicaDados.HORA}</p>
                    </div>
                  </div>
                  <div className="box-inf-not">
                    <div className="header-inf-not">
                      <i className="fa fa-scissors" />
                      <h5>Datos de servicios</h5>
                    </div>
                    <div className="box-inf-tx">
                      <small>Servicio</small>
                      <p>{this.state.agenda.SERVICO_NOME}</p>
                    </div>
                    <div className="box-inf-tx">
                      <small>Precio</small>
                      <p>€ {this.state.agenda.ADMPED_VALOR}</p>
                    </div>
                  </div>
                  <div className="box-inf-not">
                    <div className="header-inf-not">
                      <i className="fas fa-credit-card" />
                      <h5>Detalles del pago</h5>
                    </div>
                    <div className="box-inf-tx">
                      <small>Comentario</small>
                      <p>{this.state.agenda.FORMAPG_NOME}</p>
                    </div>
                  </div>
                  <div className="box-inf-not">
                    <div className="header-inf-not">
                      <h5>Status</h5>
                    </div>
                    <div className="box-inf-tx">
                      <small>Situación</small>
                      {
                        this.state.agenda.STATUS == 1 ?
                          <p>Pendente</p>
                          :
                          this.state.agenda.STATUS == 2 ?
                            <p>Confirmado</p>
                            :
                            this.state.agenda.STATUS == 4 ?
                              <p>Finalizado</p>
                              :
                              this.state.agenda.STATUS == 5 ?
                                <p>Cancelado</p>
                                :
                                null
                      }
                    </div>
                  </div>
                  {
                    this.state.agenda.STATUS == 1 ?
                      <div className="box-inf-not">
                        <button className="btn_blue" onClick={() => this.setState({ modalConfirmar: true })}><i className="fas fa-thumbs-up"></i>Confirmar turno</button>
                        <button className="btn_orange" onClick={() => this.setState({ modalRea: true })}><i className="fas fa-solid fa-clock"></i>Reprogramar turno</button>
                        <button className="btn_cancel" onClick={() => this.setState({ modalCancelar: true })}>Cancelar turno</button>
                      </div>
                      :
                      this.state.agenda.STATUS == 2 ?
                        <div className="box-inf-not">
                          <button className="btn_green" onClick={() => this.setState({ modalFinalizar: true })}><i className="fas fa-solid fa-clock"></i>Turno realizado con éxito</button>
                          <button className="btn_cancel" onClick={() => this.setState({ modalCancelar: true })}>Cancelar turno</button>
                        </div>
                        :
                        this.state.agenda.STATUS == 4 || 5 ?
                          <div className="box-inf-not">
                            <button className="btn_cancel" onClick={() => window.location.href = '/home-salon'}>Cerrar</button>
                          </div>
                          :
                          null
                  }
                </div>
              </section>
            </>
        }
        <Footer />
      </>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Detalhes);