import React from 'react';
import './index.scss';

import Lottie from 'react-lottie';

//Componentes
import Header from '../../components/header';
import Footer from '../../components/Footer';

//Calling bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
//Calling WOWjs
import WOW from 'wowjs';

import Salao from '../../assets/images/logo-salao-perfil.png';

//Redux
import Api from '../../services/api';
import ApiV2 from '../../services/api-v2';
import { Diversos } from '../../services/diversos';
import { ApplicationState } from '../../store';
import { User } from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import { Param } from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Auth } from '../../store/ducks/auth/types';
import Informacoes from '../MenuCliente';

interface StateProps {
    user: User;
    param: Param;
}

interface DispatchProps {
    doLogin(user: User): void;
    setParam(param: Param): void;
    setParam(param: Param): void;
}

interface OwnProps { }

type Props = StateProps & DispatchProps & OwnProps;

class MenuSalao extends React.Component<Props> {

    state: any = null;
    api: any;
    apiv2: any;
    userRef: any;

    constructor(props) {
        super(props);
        this.api = new Api();
        this.apiv2 = new ApiV2();
        this.state = {

        }
    }

    // Defining WOW 
    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();

        if (this.props.user.empresa == 0) {
            window.location.href = '/home-cliente'
        }
    }

    render() {
        return (
            <>
                <Header
                    registra={false}
                    login={false}
                    notificacoes={true}
                />
                <section className="container-informacoes-cliente">
                    <div className="box-roxo" />
                    <section className="container-perfil-menu">
                        <div className="box-img-user wow fadeInUp">
                            <div className="border-user">
                                <img src={this.props.user.avatar} className="user-img" />
                            </div>
                        </div>
                        <h4 className="wow fadeInUp">{this.props.user.nome}</h4>
                        <p className="email wow fadeInUp">{this.props.user.email}</p>
                        <div className="container-dados-user wow fadeInUp">
                            <div className="header-container">
                                <h5>Mi Perfil</h5>
                            </div>
                            <a className="line-opt-perfil" href="/mi-informacion-salon">
                                <div className="name">
                                    {/* <i className="fas fa-user"></i> */}
                                    <p style={{ marginLeft: 10 }}>Mi informacion</p>
                                </div>
                                <i className="fas fa-chevron-right arrow"></i>
                            </a>
                            <a className="line-opt-perfil" href="/mis-fotos">
                                <div className="name">
                                    {/* <i className="fas fa-image"></i> */}
                                    <p style={{ marginLeft: 10 }}>Mis fotos</p>
                                </div>
                                <i className="fas fa-chevron-right arrow"></i>
                            </a>
                            <a className="line-opt-perfil" href="/mi-direccion-salon">
                                <div className="name">
                                    {/* <i className="fas fa-map-marker-alt"></i> */}
                                    <p style={{ marginLeft: 10 }}>Mi dirección</p>
                                </div>
                                <i className="fas fa-chevron-right arrow"></i>
                            </a>
                            <a className="line-opt-perfil" href="/cambiar-contrasena-salon">
                                <div className="name">
                                    {/* <i className="fas fa-lock"></i> */}
                                    <p style={{ marginLeft: 10 }}>Cambiar mi contraseña</p>
                                </div>
                                <i className="fas fa-chevron-right arrow"></i>
                            </a>
                        </div>
                        <div className="container-dados-user wow fadeInUp">
                            <div className="header-container">
                                <h5>Mis Horarios</h5>
                            </div>
                            <a className="line-opt-perfil" style={{ border: 'none' }} href="/horarios-salon">
                                <div className="name">
                                    {/* <i className="far fa-calendar-alt"></i> */}
                                    <p style={{ marginLeft: 10 }}>Historial de horarios</p>
                                </div>
                                <i className="fas fa-chevron-right arrow"></i>
                            </a>
                        </div>
                        <div className="container-dados-user wow fadeInUp">
                            <div className="line-opt-perfil" style={{ border: 'none' }} onClick={() => window.location.href = '/login-salon'}>
                                <div className="name">
                                    {/* <i className="fas fa-sign-out-alt"></i> */}
                                    <p style={{ marginLeft: 10 }}>Cerrar sesión</p>
                                </div>
                                <i className="fas fa-chevron-right arrow"></i>
                            </div>
                        </div>
                    </section>
                </section>
                <Footer />
            </>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.data,
    param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuSalao);