import React from 'react';
import './index.scss';

//Calling WOWjs
import WOW from 'wowjs';

//Redux
import Api from '../../services/api';
import ApiV2 from '../../services/api-v2';
import { Diversos } from '../../services/diversos';
import { ApplicationState } from '../../store';
import { User } from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import { Param } from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Auth } from '../../store/ducks/auth/types';

import Lottie from 'react-lottie';
//Animaceos Lottie
import animationData from '../../animations/loading.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


interface StateProps {
    user: User;
    param: Param;
}

interface DispatchProps {
    doLogin(user: User): void;
    setParam(param: Param): void;
    setParam(param: Param): void;
}

interface OwnProps {
    titulo: string,
    valor: string,
    tempo: string,
    descricao: string,
    catetegoria: string,
    codigo: string,
}

type Props = StateProps & DispatchProps & OwnProps;


export class CardServico extends React.Component<Props> {

    state: any = null;
    api: any;
    apiv2: any;
    userRef: any;

    constructor(props) {
        super(props);
        this.api = new Api();
        this.apiv2 = new ApiV2();
        this.state = {
            agregar: false,
            modal: false,
            isLoading: false,
        }
    }

    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();
    }

    private async handleDrop() {
        const self = this;

        this.setState({ isLoading: true })

        try {

            let paramApi = {
                salao: this.props.user.codigo,
                servico: this.props.codigo,
            };

            const { data } = await self.api.post('/Servico/drop', paramApi);

            if (data.status === false) {
                alert(Error)
                window.location.href = 'tratamientos-salon'
            } else {
                alert('Apagado')
                window.location.href = 'tratamientos-salon'
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.setState({ isLoading: false })
        }
    }

    render() {
        return (
            <>
                {
                    this.state.modal ?
                        <section className="modal-funcionarios" style={{ top: 0 }}>
                            <div className="box">
                                <div style={{ display: 'flex' }}>
                                    <i className="fas fa-exclamation-triangle"></i>
                                    <h5>Alerta</h5>
                                </div>
                                {
                                    this.state.isLoading ?
                                        <Lottie
                                            options={defaultOptions}
                                            height={350}
                                            width={350}
                                            isStopped={this.state.isStopped}
                                            isPaused={this.state.isPaused}
                                        />
                                        :
                                        <>
                                            <p>¿Quieres eliminar {this.props.titulo}?</p>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                                                <button className="btn_secondary" onClick={() => this.setState({ modal: !this.state.modal })}>
                                                    <p>No, volver</p>
                                                </button>
                                                <button className="btn_primary" onClick={() => this.handleDrop()}>
                                                    <p style={{ color: '#fff' }}>Sí</p>
                                                </button>
                                            </div>
                                        </>
                                }
                            </div>
                        </section>
                        :
                        null
                }
                <section className="card-servico wow fadeInUp">
                    <h5>{this.props.titulo}</h5>
                    <div className="tags-box">
                        <div style={{ display: 'flex', width: 'auto', height: 40 }}>
                            <div className="tag tipo">
                                <p>{this.props.catetegoria}</p>
                            </div>
                            <div className="tag valor">
                                <p>$ {this.props.valor}</p>
                            </div>
                            <div className="tag tempo">
                                <p>{this.props.tempo} Min</p>
                            </div>
                        </div>
                        <div className="ico-trash" onClick={() => this.setState({ modal: !this.state.modal })}>
                            <i className="fa fa-trash-o" />
                        </div>
                    </div>

                    {
                        this.state.agregar == true ?
                            <div className="dados-area">
                                {/* <div className="info-box">
                                <small>Servicio</small>
                                <h5>{this.props.titulo}</h5>
                            </div>
                            <div className="info-box">
                                <small>Precio</small>
                                <h5># 450,00</h5>
                            </div>
                            <div className="info-box">
                                <small>Tiempo</small>
                                <h5>45 Min</h5>
                            </div> */}
                                <div className="info-box">
                                    <small>Descripción</small>
                                    {
                                        this.props.descricao == null || '' ?
                                            <h5>Tratamiento sin descripción</h5>
                                            :
                                            <h5>{this.props.descricao}</h5>
                                    }
                                </div>
                            </div>
                            :
                            null
                    }

                    {
                        this.state.agregar == false ?
                            <button className="btn_agregar" onClick={() => this.setState({ agregar: !this.state.agregar })}>
                                <i className="fas fa-plus"></i>
                                <p>Ver detalles</p>
                            </button>
                            :
                            <button className="btn_desmarcar" onClick={() => this.setState({ agregar: !this.state.agregar })}>
                                <p>Cerrar</p>
                            </button>
                    }
                </section>
            </>
        )
    }
};

const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.data,
    param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CardServico);

