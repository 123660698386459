/**
 * ACTIONS TYPES
 */
export enum CartTypes {
  ADD = '@cart/ADD',
  DROP = '@cart/DROP',
  TOTAL = '@cart/TOTAL',
}

interface CartProducts {
  codigo: number;
  nome: string;
  preco: number;
  tempo: number;
  data: string;
  hora: string;
  salao: any;
}

/**
 * Data types
 */
export interface Cart {
  produtos: Array<CartProducts>,
}

/**
 * State types
 */
export interface CartState {
  readonly data: Cart
}