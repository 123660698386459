import React from 'react';
import './index.scss';

//Calling WOWjs
import WOW from 'wowjs';

//Redux
import Api from '../../services/api';
import ApiV2 from '../../services/api-v2';
import { Diversos } from '../../services/diversos';
import { ApplicationState } from '../../store';
import { User } from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import { Param } from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Auth } from '../../store/ducks/auth/types';

import Lottie from 'react-lottie';
//Animaceos Lottie
import animationData from '../../animations/loading.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


interface StateProps {
    user: User;
    param: Param;
}

interface DispatchProps {
    doLogin(user: User): void;
    setParam(param: Param): void;
    setParam(param: Param): void;
}

interface OwnProps {
    foto: string,
    servico: string,
    inicio: string,
    final: string,
    valor: string,
    id: any,
    idServico: any,
}

type Props = StateProps & DispatchProps & OwnProps;


export class cardPromo extends React.Component<Props> {

    state: any = null;
    api: any;
    apiv2: any;
    userRef: any;

    constructor(props) {
        super(props);
        this.api = new Api();
        this.apiv2 = new ApiV2();
        this.state = {
            modal: false,
            loading: false,
        }
    }

    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();
    }

    private async handleDrop() {
        const self = this;

        this.setState({ loading: true, })

        try {
            const { data } = await this.apiv2.delete(
                `/salon/${this.props.user.codigo}/service/${this.props.idServico}/promotion/${this.props.id}`,
            );

            if (data.status === false) {
                alert('No se pudo eliminar ahora, inténtalo de nuevo más tarde')
                console.log(data.msg)
                this.setState({ modal: false })
            }
            else {
                alert('Promoción eliminada con éxito')
                window.location.href = '/promocion'
            }
        } catch (e) {

        } finally {
            this.setState({ loading: false, })
        }
    }

    render() {
        return (
            <>
                {
                    this.state.modal ?
                        <section className="modal-funcionarios" style={{ top: 0 }}>
                            <div className="box">
                                <div style={{ display: 'flex' }}>
                                    <i className="fas fa-exclamation-triangle"></i>
                                    <h5>Alerta</h5>
                                </div>
                                {
                                    this.state.loading ?
                                        <Lottie
                                            options={defaultOptions}
                                            height={350}
                                            width={350}
                                            isStopped={this.state.isStopped}
                                            isPaused={this.state.isPaused}
                                        />
                                        :
                                        <>
                                            <p>¿Quieres eliminar {this.props.servico}?</p>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                                                <button className="btn_secondary" onClick={() => this.setState({ modal: !this.state.modal })}>
                                                    <p>No, volver</p>
                                                </button>
                                                <button className="btn_primary" onClick={() => this.handleDrop()}>
                                                    <p style={{ color: '#fff' }}>Sí</p>
                                                </button>
                                            </div>
                                        </>
                                }
                            </div>
                        </section>
                        :
                        null
                }
                <div className="cardPromos">
                    <div className="capa" style={{ backgroundImage: 'url(' + this.props.foto + ')' }} />
                    <div className="infoPromos">
                        <h5>{this.props.servico}</h5>
                        <div className="boxDestaques">
                            <div className="destaquePromos">
                                <p>Del {this.props.inicio}</p>
                            </div>
                            <div className="destaquePromos">
                                <p>Al {this.props.final}</p>
                            </div>
                            <div className="destaquePromos">
                                <p>valor {this.props.valor}</p>
                            </div>
                        </div>
                    </div>
                    <div className="icoBoxPromos" onClick={() => this.setState({ modal: true })}>
                        <i className="fa fa-trash-o" title="Eliminar" />
                    </div>
                </div>
            </>
        )
    }
};

const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.data,
    param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(cardPromo);

