import {Reducer} from 'redux';
import {ParamState, ParamTypes} from './types';

const INITIAL_STATE: ParamState = {
  data: {
    perfil: 0,
    fgNovoCartao: false,
    latitude: '',
    longitude: '',
    endereco: '',
    favoritos: [],
    isLoading: false,
    fgHasRefresh: false,
    acceptCookies: false,
  },
};

const reducer: Reducer<ParamState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ParamTypes.SET_PARAM:
      return {...state, data: action.payload.data};
    default:
      return state;
  }
};

export default reducer;
