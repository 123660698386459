import React from "react";
import "./index.scss";
import swal from "sweetalert";
import Header from "../../../components/header";
import Footer from "../../../components/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import WOW from "wowjs";
import Api from "../../../services/api";
import ApiV2 from "../../../services/api-v2";
import { ApplicationState } from "../../../store";
import { User } from "../../../store/ducks/user/types";
import * as UserActions from "../../../store/ducks/user/actions";
import { Param } from "../../../store/ducks/param/types";
import * as ParamActions from "../../../store/ducks/param/actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import Lottie from "react-lottie";
import animationData from "../../../animations/loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  setParam(param: Param): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class RecuperarSenhaSalao extends React.Component<Props> {
  state: any = null;
  api: any;
  apiv2: any;
  userRef: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.apiv2 = new ApiV2();
    this.state = {
      isLoading: true,
      email: "",
    };
  }

  // Defining WOW
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }

  private async submitForm() {
    const self = this;

    if (self.state.email == "") {
      self.setState({ isLoading: false });
      swal("Atencion", "Formulario incompleto.", "error");
      return;
    }

    self.setState({ isLoading: true });

    let param = {
      email: self.state.email,
      perfil: 2,
    };

    try {
      const { data } = await self.api.post("/User/resetPassword", param);

      if (data.status == false) {
        throw new Error("No se puede recuperar la contraseña");
        return;
      }

      swal(
        "Exito",
        "Se ha enviado una contraseña temporal a su correo electrónico",
        "success"
      );
    } catch (e) {
      console.log(e);
      swal("Atencion", "No se puede recuperar la contraseña", "error");
    } finally {
      self.setState({ isLoading: false });
    }

    return;
  }

  render() {
    return (
      <>
        <Header registra={false} login={false} notificacoes={true} />
        {this.state.loading == true ? (
          <div className="modal-content">
            <Lottie
              options={defaultOptions}
              height={250}
              width={250}
              isStopped={this.state.isStopped}
              isPaused={this.state.isPaused}
            />
          </div>
        ) : null}
        <section className="container-contrasena">
          <div className="row-contrasena">
            <div className="header-perfil wow fadeInUp">
              <i className="fas fa-unlock"></i>
              <h5>Recuperar contraseña</h5>
            </div>
            <div className="input-box wow fadeInUp">
              <small>Correo electrónico*</small>
              <input
                type="email"
                value={this.state.email}
                onChange={(event) =>
                  this.setState({ email: event.target.value })
                }
              />
            </div>
            <button
              className="btn_primary wow fadeInUp"
              style={{ width: "100%", marginTop: 25 }}
              onClick={() => this.submitForm()}
              disabled={this.state.loading}
            >
              <p>Recuperar contraseña</p>
            </button>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecuperarSenhaSalao);
