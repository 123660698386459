import React from "react";
import "./index.scss";

//Componentes
import Header from "../../../components/header";
import Footer from "../../../components/Footer";
import Cartao from "../../../components/CardCartao";

//Calling bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
//Calling WOWjs
import WOW from "wowjs";

//Redux
import Api from "../../../services/api";
import ApiV2 from "../../../services/api-v2";
import { Diversos } from "../../../services/diversos";
import { ApplicationState } from "../../../store";
import { User } from "../../../store/ducks/user/types";
import * as UserActions from "../../../store/ducks/user/actions";
import { Param } from "../../../store/ducks/param/types";
import * as ParamActions from "../../../store/ducks/param/actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import Lottie from "react-lottie";
//Animaceos Lottie
import animationData from "../../../animations/loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  setParam(param: Param): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class Horarios extends React.Component<Props> {
  state: any = null;
  api: any;
  apiv2: any;
  userRef: any;

  constructor(props) {
    super(props);
    this.apiv2 = new ApiV2();
    this.api = new Api();
    this.state = {
      page: 1,
      perPage: 20,
      horarios: [],
      loading: false,
    };
  }

  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();

    window.scrollTo(0, 0);

    this.getHorarios();
  }

  private async getHorarios() {
    this.setState({ loading: true });

    const self = this;
    let params = ``;

    params += `page=${self.state.page}`;
    params += `perPage=${self.state.perPage}`;

    try {
      const { data } = await self.apiv2.get(
        `/customer/${self.props.user.codigo}/schedules?${params}`
      );

      if (data.status == true) {
        if (self.state.page <= 1) {
          this.setState({ horarios: data.msg.data });
        } else {
          console.log("No se pueden encontrar los horarios");
        }
      }
    } catch (e) {
      console.log("erro" + e);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <>
        <Header registra={false} login={false} notificacoes={true} />
        <section className="container-horarios">
          <div className="row-horarios">
            <div className="header-horarios">
              <i className="far fa-clock"></i>
              <h5>Mis horarios</h5>
            </div>

            {this.state.loading ? (
              <div
                style={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Lottie
                  options={defaultOptions}
                  height={350}
                  width={350}
                  isStopped={this.state.isStopped}
                  isPaused={this.state.isPaused}
                />
              </div>
            ) : this.state.horarios.lenght <= 0 ? (
              <div className="alert-card wow fadeInUp">
                <h5>No hay horarios registradas.</h5>
              </div>
            ) : (
              this.state.horarios.map((item) =>
                item.ITENS.map((row2) => (
                  <div
                    className="box-hs"
                    style={{ justifyContent: "space-between" }}
                  >
                    <h5 className="mr-3">{item.PEDIDO}</h5>
                    <h5 style={{ flex: 6 }}>
                      {row2.servicoDados.SERVICO}
                      <br />
                      <small style={{ fontWeight: 500, fontSize: 12 }}>
                        {row2.salaoDados.NOME}
                      </small>
                    </h5>
                    <div
                      style={{
                        flex: 5,
                        flexDirection: "row",
                      }}
                    >
                      <p className="destaque">{`${item.DATA} ${item.HORA}`}</p>
                    </div>
                    <a
                      href={`/horarios-registrados/${item.PEDIDO}/detalhe`}
                      target="_self"
                      className="btn btn-link text-primary"
                    >
                      <i className="fas fa-eye"></i>
                    </a>
                  </div>
                ))
              )
            )}
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Horarios);
