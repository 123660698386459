import React from "react";
import "./index.scss";

import Lottie from "react-lottie";

//Componentes
import Header from "../../../components/header";
import Footer from "../../../components/Footer";

//Calling bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
//Calling WOWjs
import WOW from "wowjs";
import swal from "sweetalert";

//Redux
import Api from "../../../services/api";
import ApiV2 from "../../../services/api-v2";
import { Diversos } from "../../../services/diversos";
import { ApplicationState } from "../../../store";
import { User } from "../../../store/ducks/user/types";
import * as UserActions from "../../../store/ducks/user/actions";
import { Param } from "../../../store/ducks/param/types";
import * as ParamActions from "../../../store/ducks/param/actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Auth } from "../../../store/ducks/auth/types";
import Informacoes from "../../MenuCliente";

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  setParam(param: Param): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class MiInformacion extends React.Component<Props> {
  state: any = null;
  api: any;
  apiv2: any;
  userRef: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.apiv2 = new ApiV2();
    this.state = {
      dados: [],
      nome: this.props.user.nome,
      email: this.props.user.email,
      tel: "",
    };
  }

  // Defining WOW
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();

    this.getTelefone();
  }

  private async getTelefone() {
    // eslint-disable-next-line consistent-this
    const self = this;

    self.setState({ isLoadingPromocao: true }, () =>
      this.props.setParam({ ...this.props.param, isLoading: true })
    );

    let param = {
      codigo: this.props.user.codigo,
    };

    try {
      const { data } = await self.api.post("/User/endereco", param);

      if (data.status === false) {
        throw new Error("Nenhum dado localizado");
      } else {
        self.setState({ dados: data.msg, tel: data.msg.tel });
        this.setState({ isLoading: false });
      }
    } catch (e) {
      console.log(e);
    } finally {
      self.setState({ isLoadingPromocao: false }, () =>
        this.props.setParam({ ...this.props.param, isLoading: false })
      );
    }
  }

  private async handleSubmit() {
    const self = this;

    if (!self.state.nome || !self.state.email || !self.state.tel) {
      swal("Atencion", "Campos obligatorios no rellenados.", "error");
      return;
    }

    self.setState({
      isLoadingSubmit: true,
    });

    let param = {
      perfil: 1,
      codigo: self.props.user.codigo,
      nome: self.state.nome,
      email: self.state.email,
      telefone: self.state.tel,
    };

    console.log(param);

    try {
      const { data } = await self.api.post("/User/update", param);

      if (data.status === false) {
        throw new Error(
          "No se puede actualizar la información, intente nuevamente."
        );
      } else {
        swal("Exito", "Información actualizada con éxito", "success");
      }
    } catch (e) {
      console.log(e);
      swal(
        "Atencion",
        "No se puede cargar la información, intente nuevamente",
        "error"
      );
    } finally {
      await self.setState({ isLoadingSubmit: false });
    }
  }

  render() {
    return (
      <>
        <Header registra={false} login={false} notificacoes={true} />
        <section className="container-mi-informacion">
          <div className="row-mi-informacion">
            <div className="header-perfil wow fadeInUp">
              <i className="fas fa-user"></i>
              <h5>Mi Información</h5>
            </div>
            <div className="input-box wow fadeInUp">
              <small>Nobre*</small>
              <input
                type="text"
                value={this.state.nome}
                onChange={(event) =>
                  this.setState({ nome: event.target.value })
                }
              />
            </div>
            <div className="input-box wow fadeInUp">
              <small>Correo electrónico*</small>
              <input
                type="email"
                value={this.state.email}
                onChange={(event) =>
                  this.setState({ email: event.target.value })
                }
              />
            </div>
            <div className="input-box wow fadeInUp">
              <small>Télefono</small>
              <input
                type="phone"
                value={this.state.tel}
                onChange={(event) => this.setState({ tel: event.target.value })}
              />
            </div>
            <button
              disabled={this.state.isLoadingSubmit}
              className="btn_primary wow fadeInUp"
              style={{ width: "100%", marginTop: 25 }}
              onClick={this.handleSubmit.bind(this)}
            >
              {/* <i className="fas fa-save"></i> */}
              <p>Guardar</p>
            </button>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MiInformacion);
