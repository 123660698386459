import React from 'react';
import './index.scss';

//Redux
import Api from '../../services/api';
import ApiV2 from '../../services/api-v2';
import { ApplicationState } from '../../store';
import { User } from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import { Param } from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import Lottie from 'react-lottie';
//Animaceos Lottie
import animationData from '../../animations/loading.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


interface StateProps {
    user: User;
    param: Param;
}

interface DispatchProps {
    doLogin(user: User): void;
    setParam(param: Param): void;
    setParam(param: Param): void;
}

interface OwnProps {
    codigo: string,
    nome: String,
}

type Props = StateProps & DispatchProps & OwnProps;

export class CardFuncionario extends React.Component<Props> {

    state: any = null;
    api: any;
    apiv2: any;
    userRef: any;

    constructor(props) {
        super(props);
        this.api = new Api();
        this.apiv2 = new ApiV2();
        this.state = {
            avatar: '',
            modal: false,
            loading: false,
        }
    }

    componentDidMount() {
        this.getFotoProfissional()
    }

    async getFotoProfissional() {
        const self = this;

        try {
            let param = {
                codigo: this.props.codigo,
                perfil: 3
            };

            const { data } = await self.api.post(
                `/User/GetById`, param
            );

            this.setState({ avatar: data.msg.avatar })


            if (data.status === false) {
                console.log('erro' + Error)
                this.setState({ avatar: require('../../assets/images/user.png') })
            } else {

            }
        } catch (e) {
            console.log(e);
        } finally {

        }
    }

    async delProfissional() {
        const self = this;

        this.setState({ loading: true })

        try {
            const { data } = await self.apiv2.delete(`/salon/${this.props.user.codigo}/professional/${this.props.codigo}`);

            if (data.status === false) {
                console.log('erro' + Error)
            } else {
                alert('Profesional eliminado con éxito.')
                window.location.href = '/mi-profesionales'
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.setState({ loading: false })
        }
    }

    render() {
        return (
            <>
                {
                    this.state.modal ?
                        <div className="modal-funcionarios-fun" style={{ top: '0px' }}>
                            <div className="box">
                                <div style={{ display: 'flex' }}>
                                    <i className="fas fa-user"></i>
                                    <h5>Aviso</h5>
                                </div>
                                {
                                    this.state.loading ?
                                        <Lottie
                                            options={defaultOptions}
                                            height={350}
                                            width={350}
                                            isStopped={this.state.isStopped}
                                            isPaused={this.state.isPaused}
                                        />
                                        :
                                        <>
                                            <h4>¿De verdad quieres eliminar a {this.props.nome} de tus empleados?</h4>
                                            <div style={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'space-between' }}>
                                                <button className="btn_secondary" onClick={() => this.setState({ modal: false })}>
                                                    <p style={{ color: '#FF7900' }}>No</p>
                                                </button>
                                                <button className="btn_primary" onClick={() => this.delProfissional()}>
                                                    <p>Sí</p>
                                                </button>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                        :
                        null
                }
                <section className="card-funcionario">
                    <div className="del-box">
                        <i className="fa fa-trash-o" onClick={() => this.setState({ modal: true })} />
                    </div>
                    <div className="foto-funcionario" style={{ backgroundImage: `url(${this.state.avatar})` }} />
                    <p>{this.props.nome}</p>
                </section>
            </>
        )
    }
};

const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.data,
    param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CardFuncionario);