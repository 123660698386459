import React from 'react';
import './index.scss';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import Banner1 from '../../assets/images/Banners-turnau/banner-1.png';
import Banner2 from '../../assets/images/Banners-turnau/banner-2.png';
import Banner3 from '../../assets/images/Banners-turnau/banner-3.png';

interface Props {

}

export class Banner extends React.Component<Props> {

    state: any = null;

    constructor(props) {
        super(props);
        this.state = {
            autoPlay:true,
        }
    }

    render() {
        return (
            <section className="slide-banner-container">
                <Carousel
                    autoPlay={true}
                    interval={3000}
                    emulateTouch={true}
                    infiniteLoop={true}
                    showStatus={false}
                    showIndicators={false}
                    showThumbs={false}
                >
                    <div>
                        <img src={Banner1}/>
                    </div>
                    <div>
                        <img src={Banner2}/>
                    </div>
                    <div>
                        <img src={Banner3}/>
                    </div>
                </Carousel>
            </section>
        )
    }
};

export default Banner;

