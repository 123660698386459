import React from 'react';
import './index.scss';
import Card from '../CardSalao';

//Redux
import Api from '../../services/api';
import { Diversos } from '../../services/diversos';
import { ApplicationState } from '../../store';
import { User } from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import { Param } from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';


import Lottie from 'react-lottie';
//Animaceos Lottie
import animationData from '../../animations/loading.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
}

interface OwnProps {
  tit: String,
}

type Props = StateProps & DispatchProps & OwnProps;

export class Slider extends React.Component<Props> {

  state: any = null;
  api: any;


  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      salaoProximo: [],
      isLoading: true,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.getSalaoProximo();
    }, 500);

  }

  private async getSalaoProximo() {
    const self = this;

    self.setState({ isLoadingSalaoProximo: true }, () =>
      this.props.setParam({ ...this.props.param, isLoading: true }),
    );

    let param = {
      limit: 0,
      lat: self.props.param.latitude,
      lng: self.props.param.longitude,
    };

    try {
      const { data } = await self.api.post('/Servico/getSalaoByDistancia', param);

      if (data.status === false) {
        console.log('Nenhum salao localizado');
      } else {
        self.setState({ salaoProximo: data.msg });
        this.setState({ isLoading: false })
      }
    } catch (e) {
      console.log(e);
      self.setState({ salaoProximo: [] });
    } finally {
      self.setState({ isLoadingSalaoProximo: false }, () =>
        this.props.setParam({ ...this.props.param, isLoading: false }),
      );
    }
  }

  render() {
    return (
      <section className="container-slider">
        <div className="header-container-slider">
          <h4>{this.props.tit}</h4>
        </div>
        <div className="container-slider-cards">
          <div className="fadeInLeft" />
          <div className="container-cards">
            <div className="space"></div>
            <div className="containerLoading">
              {
                this.state.isLoading == true ?
                  <Lottie options={defaultOptions}
                    height={250}
                    width={250}
                    isStopped={this.state.isStopped}
                    isPaused={this.state.isPaused}
                  />
                  :
                  this.state.salaoProximo.sort(row => !row.link ? 0 : 1).map(item => (
                    <Card
                      nome={item.salao_nome}
                      distancia="1.2"
                      procedimento={item.nome}
                      local={item.ende}
                      img={item.link}
                      id={item.codigo}
                    />
                  ))
              }
            </div>
          </div>
          <div className="fadeInRight" />
        </div>
      </section>
    )
  }
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Slider);

