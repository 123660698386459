import React from 'react';
import './index.scss';

//Redux
import { Link, Redirect } from "react-router-dom";
import moment from "moment";
import "moment/locale/pt-br";
import Api from "../../services/api";
import { Diversos } from "../../services/diversos";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../../store";
import { Param } from "../../store/ducks/param/types";
import { User } from "../../store/ducks/user/types";
import { Cart as CartProps } from "../../store/ducks/cart/types";
import * as UserActions from "../../store/ducks/user/actions";
import * as ParamActions from "../../store/ducks/param/actions";

import Lupa from '../../assets/images/lupa.svg';
import Rostro from '../../assets/images/rostro-ico.png';
import Cuerpo from '../../assets/images/cuerpo-ico.png';
import Manos from '../../assets/images/manos-ico.png';
import Masajes from '../../assets/images/massage-ico.png';
import Peluqueria from '../../assets/images/peluqueria-ico.png';
import Legs from '../../assets/images/legs-ico.png';

declare var window;

interface StateProps {
  user: User;
  param: Param;
  cart: CartProps;
}

interface OwnProps {
  searchTermo?: string;
  tipo?: number;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
}

type Props = StateProps & DispatchProps & OwnProps;

export class Buscar extends React.Component<Props> {

    api: any = null;
    state: any = null;
    searchbarRef: any;
    listener: any;

    constructor(props) {
        super(props);
        this.api = new Api();
        this.searchbarRef = React.createRef();
        this.state = {
            searchTerm: this.props.searchTermo ? this.props.searchTermo : null,
        }
    }

    private async handleSearch() {
        const self = this;
        if (!self.state.searchTerm) {
          self.searchbarRef.current.focus();
          self.setState({ searchbarHasError: true });
        } else {
          self.setState({
            redirect: `/buscar/${Diversos.toSeoUrl(self.state.searchTerm)}`,
          });
          window.location.href = `/buscar/${Diversos.toSeoUrl(
            self.state.searchTerm
          )}`;
        }
      }

    render() {
        return (
            <section className="container-buscar">
                <div className="input-box">
                    <img src={Lupa}/>
                    <input 
                        type="text" 
                        placeholder="Buscar"
                        ref={this.searchbarRef}
                        value={this.state.searchTerm}
                        onChange={(event) =>
                            this.setState({ searchTerm: event.target.value })
                        }
                        onKeyPress={(event) =>
                            event.key === "Enter" && this.handleSearch()
                        }
                    />
                </div>
                <div className="box-categorias">
                    <a className="box-cat" href="/buscar/rostro">
                        <img src={Rostro} style={{marginRight:'-20px'}}/>
                        <p>Rostro</p>
                    </a>
                    <a className="box-cat" href="/buscar/cuerpo">
                        <img src={Cuerpo}/>
                        <p>Cuerpo</p>
                    </a>
                    <a className="box-cat" href="/buscar/manos">
                        <img src={Manos}/>
                        <p>Manos</p>
                    </a>
                    <a className="box-cat" href="/buscar/masajes">
                        <img src={Masajes}/>
                        <p>Masajes</p>
                    </a>
                    <a className="box-cat" href="/buscar/peluqueria">
                        <img src={Peluqueria}/>
                        <p>Peluqueria</p>
                    </a>
                    <a className="box-cat" href="/buscar/legs">
                        <img src={Legs}/>
                        <p>Depilación</p>
                    </a>
                </div>
            </section>
        )
    }
};

const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.data,
    param: state.param.data,
    cart: state.cart.data,
  });
  
  const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);
  
  export default connect(mapStateToProps, mapDispatchToProps)(Buscar);

