import {Reducer} from 'redux';
import {AuthState, AuthTypes} from './types';

const INITIAL_STATE: AuthState = {
  data: {
    access_token: '',
    type: '',
    expire: '',
  },
};

const reducer: Reducer<AuthState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.SET:
      return {
        ...state,
        data: {
          access_token: action.payload.data.access_token,
          type: action.payload.data.type,
          expire: action.payload.data.expire,
        },
      };
    default:
      return state;
  }
};

export default reducer;
