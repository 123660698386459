import React from 'react';
import './index.scss';

//Componentes
import Header from '../../../components/header';
import Footer from '../../../components/Footer';
import Card from '../../../components/CardSalao';

//Calling bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

//Calling WOWjs
import WOW from 'wowjs';

//Redux
import Api from '../../../services/api';
import ApiV2 from '../../../services/api-v2';
import { Diversos } from '../../../services/diversos';
import { ApplicationState } from '../../../store';
import { User } from '../../../store/ducks/user/types';
import * as UserActions from '../../../store/ducks/user/actions';
import { Param } from '../../../store/ducks/param/types';
import * as ParamActions from '../../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Auth } from '../../../store/ducks/auth/types';

import Lottie from 'react-lottie';
//Animaceos Lottie
import animationData from '../../../animations/loading.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

interface StateProps {
    user: User;
    param: Param;
    auth: Auth;
}

interface DispatchProps {
    doLogin(user: User): void;
    setParam(param: Param): void;
    setAuth(auth: Auth): void;
}

interface OwnProps {

}

type Props = StateProps & DispatchProps & OwnProps;

class Favoritos extends React.Component<Props> {

    state: any = null;
    api: any;
    apiv2: any;
    userRef: any;


    constructor(props) {
        super(props);
        this.apiv2 = new ApiV2();
        this.api = new Api();
        this.state = {
            loading: true,
            page: 0,

            saloesFav: [],
        }
    }

    // Defining WOW 
    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();

        this.getFavoritos();

        if (this.props.user.empresa != 0) {
            window.location.href = '/home-salon'
        }
    }

    async getFavoritos() {
        this.setState({ loading: true })

        let saloes = this.props.param.favoritos;

        const self = this;

        let param = {
            limit: this.state.page,
            codigos: saloes,
        };

        try {
            const { data } = await self.api.post(`/Servico/getSalaoByCodigos`, param);

            if (data.status == false) {
                console.log('Não foi possivel buscar saloes');
                await this.setState({ saloesFav: '' });
            } else {
                await this.setState({ saloesFav: data.msg });

                console.log(this.state.saloesFav)
            }
        } catch (e) {
            console.log(e)
        } finally {
            this.setState({ loading: false })
        }
    }

    render() {
        return (
            <>
                <Header
                    registra={false}
                    login={false}
                    notificacoes={true}
                />

                <section className="container-favoritos">
                    <div className="row-favoritos">
                        <div className="header-favoritos">
                            <i className="fas fa-heart"></i>
                            <h5>Favoritos</h5>
                        </div>

                        {
                            this.state.loading ?
                                <div style={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'center' }}>
                                    <Lottie
                                        options={defaultOptions}
                                        height={350}
                                        width={350}
                                        isStopped={this.state.isStopped}
                                        isPaused={this.state.isPaused}
                                    />
                                </div>
                                :
                                !this.state.saloesFav ?
                                    <div className="alert-card wow fadeInUp">
                                        <h5>No hay Favoritos registradas.</h5>
                                    </div>
                                    :
                                    <div className="box-cards-favs">
                                        {
                                            this.state.saloesFav.map(item => (
                                                <Card
                                                    nome={item.salao_nome}
                                                    distancia="1.2"
                                                    procedimento={item.nome}
                                                    local={item.ende}
                                                    img={item.link}
                                                    id={item.codigo}
                                                />
                                            ))
                                        }
                                    </div>
                        }
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.data,
    param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Favoritos);