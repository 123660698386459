import React from "react";
import "./index.scss";

import Lottie from "react-lottie";
import swal from "sweetalert";
import moment from "moment";

//Componentes
import Header from "../../components/header";
import Footer from "../../components/Footer";

import TurnauPro from "../../assets/images/turnau-pro-logo.png";
import Local from "../../assets/images/local.png";
import Livro from "../../assets/images/livro.png";
import Money from "../../assets/images/money.png";
import Pay from "../../assets/images/pay.svg";

//Calling bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
//Calling WOWjs
import WOW from "wowjs";

//Redux
import Api from "../../services/api";
import ApiV2 from "../../services/api-v2";
import { Diversos } from "../../services/diversos";
import { ApplicationState } from "../../store";
import { User } from "../../store/ducks/user/types";
import * as UserActions from "../../store/ducks/user/actions";
import { Param } from "../../store/ducks/param/types";
import * as ParamActions from "../../store/ducks/param/actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Auth } from "../../store/ducks/auth/types";

//Animaceos Lottie
import animationSucess from "../../animations/complete.json";
import animationErro from "../../animations/erro.json";
import loading from "../../animations/loading.json";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationSucess,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultOptionsErro = {
  loop: false,
  autoplay: true,
  animationData: animationErro,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultOptionsLoading = {
  loop: true,
  autoplay: true,
  animationData: loading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface StateProps {
  user: User;
  param: Param;
  auth: Auth;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  setAuth(auth: Auth): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class Registro extends React.Component<Props> {
  api: any;
  apiv2: any;
  state: any;
  userRef: any;
  passwordRef: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.apiv2 = new ApiV2();
    this.userRef = React.createRef();
    this.passwordRef = React.createRef();
    this.state = {
      //etapa --> 1= form, 2= documentos, 3= finalizado, 4= Erro, 5= Escolher Foto
      etapa: 1,
      isLoading: false,

      //primeiro form
      nomeLocal: "",
      perfil: 2,
      filiais: "",
      nome: "",
      apelido: "",
      telefone: "",
      email: ``,
      senha: "",
      senhaConfirm: "",
      local: "",
      agree: false,

      //form docs
      razaoSocial: "",
      cuit: "",
      cvu: "",
      direcaoFiscal: "",

      //Cad foto
      imgPrev: require("../../assets/images/default.png"),
      imgCad: "",

      pessoa: "",
      position: 0,
      image: null,
      empresa: null,
      empresas: [],
      clienteSalvo: null,
      salonesIsLoading: false,
      salones: [],
      salonesPerPage: 5000,
      salonesPage: 1,
      salonesTotal: 0,
      centroProfissional: null,
      centroProfissionalCodigo: null,
    };
  }

  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();

    if (
      this.props.user &&
      this.props.user.status === true &&
      !this.props.user.fgFuncionario
    ) {
      // SE USUARIO ESTIVER LOGADO REDIRECIONA PARA OUTRA PAGINA
      window.location.href = "/home-cliente";
    } else if (
      this.props.user &&
      this.props.user.status === true &&
      this.props.user.fgFuncionario
    ) {
      window.location.href = "/home-salon";
    }
  }

  //Validacao formulário
  private async verifyForm() {
    const self = this;

    if (!self.state.nome || !self.state.email || !self.state.senha) {
      await self.setState({ isLoading: false }, () =>
        self.props.setParam({ ...self.props.param, isLoading: false })
      );
      swal(
        "¡Atencíon!",
        "Formulario incompleto, verifique datos nuevamente.",
        "error"
      );
      return;
    }

    // if (self.state.perfil === 3 && !self.state.centroProfissionalCodigo) {
    //   await self.setState({ isLoading: false }, () =>
    //     self.props.setParam({ ...self.props.param, isLoading: false })
    //   );
    //   swal(
    //     "¡Atencíon!",
    //     "Formulario incompleto, verifique datos nuevamente.",
    //     "error"
    //   );
    //   return;
    // }

    if (!self.state.agree) {
      await self.setState({ isLoading: false }, () =>
        self.props.setParam({ ...self.props.param, isLoading: false })
      );
      swal(
        "¡Atencíon!",
        "Necesito aceptar los TERMINOS Y CONDICIONES",
        "error"
      );
      return;
    }

    if (this.state.senha != this.state.senhaConfirm) {
      swal("¡Atencíon!", "Las contraseñas no coinciden.", "error");
    } else {
      self.setState({ etapa: 5 });
      console.log(this.state.etapa);
    }
  }

  //Validacao Documentos
  private async verifyDocs() {
    const self = this;

    if (!this.state.razaoSocial) {
      swal("¡Atencíon!", "Por favor revise el campo de Razon social.", "error");
      return;
    }

    if (!this.state.cuit) {
      swal("¡Atencíon!", "Por favor verifique el campo CUIT/CUIL.", "error");
      return;
    }

    if (!this.state.cvu) {
      swal("¡Atencíon!", "Verifique el campo CVU/CBU.", "error");
      return;
    }

    if (!this.state.direcaoFiscal) {
      swal(
        "¡Atencíon!",
        "Por favor, compruebe el campo de dirección fiscal.",
        "error"
      );
      return;
    } else {
      await self.setState({ etapa: 5 });
    }
  }

  handleChangeImage = (event) => {
    this.setState({
      imgCad: event.target.files[0],
      imgPrev: URL.createObjectURL(event.target.files[0]),
    });
  };

  private async submitForm() {
    const self = this;

    await self.setState({ isLoading: true });

    try {
      let param = {
        perfil: self.state.perfil,
        nome: self.state.nome,
        senha: self.state.senha,
        email: self.state.email,
        // empresa: self.state.centroProfissionalCodigo,
      };

      const { data } = await this.api.post("/User/create", param);

      if (data.status === false) {
        swal(
          "¡Atencíon!",
          "No se puede completar el registro, intente nuevamente.",
          "error"
        );
      }

      if (self.state.imgCad !== "" && self.state.imgCad !== null) {
        const imgBase64 = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(this.state.imgCad);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

        if (imgBase64) {
          let paramFoto = {
            user: data.codigo,
            file: String(imgBase64)
              .replace("data:image/png;base64,", "")
              .replace("data:image/jpg;base64,", "")
              .replace("data:image/jpeg;base64,", ""),
            extension: "jpg",
            perfil: self.state.perfil,
          };

          const response = await this.api.post(
            "/Document/upload/perfil",
            paramFoto
          );
        }
      }

      const response = await this.api.post("/User/login", param);

      if (response.data.status === false) {
        swal(
          "Atencion",
          "No se puede iniciar sesión, verifique los datos y vuelva a intentarlo",
          "error"
        );
        this.setState({ isLoading: false });
      } else {
        if (response.data.salao != null && response.data.salao != "") {
          await self.props.doLogin({
            codigo: response.data.salao.codigo,
            nome: response.data.salao.nome,
            email: response.data.salao.email,
            cpf: response.data.salao.cnpj,
            status: true,
            fgFuncionario: true,
            avatar:
              response.data.avatar !== "" &&
              response.data.avatar !== "undefined"
                ? response.data.avatar
                : null,
            empresa: response.data.salao.codigo,
          });

          await self.props.setParam({ ...self.props.param, perfil: 2 });

          window.location.href = "/home-salon";
        } else if (
          response.data.profissional != null &&
          response.data.profissional != ""
        ) {
          await self.props.doLogin({
            codigo: response.data.profissional.codigo,
            nome: response.data.profissional.nome,
            email: response.data.profissional.email,
            cpf: response.data.profissional.cnpj,
            status: true,
            fgFuncionario: true,
            avatar:
              response.data.avatar !== "" &&
              response.data.avatar !== "undefined"
                ? response.data.avatar
                : null,
            empresa: response.data.profissional.empresa,
          });

          await self.props.setParam({ ...self.props.param, perfil: 3 });

          window.location.href = "/home-salon";
        } else {
          window.location.href = "/login-salon";
        }
      }
    } catch (e) {
      console.log(e);
      swal(
        "¡Atencíon!",
        "No se puede completar el registro, intente nuevament.e",
        "error"
      );
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <>
        <Header registra={false} login={true} notificacoes={false} />
        <div className="registra-screen-salao">
          <div className="row">
            {this.state.isLoading ? (
              <div className="container-login">
                <div className="container-loading-cad">
                  <div className="box-animacao">
                    <Lottie
                      style={{ marginRight: 25 }}
                      options={defaultOptionsLoading}
                      height={250}
                      width={250}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                  </div>
                </div>
              </div>
            ) : this.state.etapa == 1 ? (
              <div className="container-login">
                <div className="box-passos animationFade">
                  <img src={TurnauPro} className="logo" />
                  <h5>¡Registrar tu negocio es muy fácil!</h5>
                  <div className="box-passo">
                    <h1>1.</h1>
                    <div className="texto">
                      <p>Completá el formulario.</p>
                    </div>
                  </div>
                  <div className="box-passo">
                    <h1>2.</h1>
                    <div className="texto">
                      <p>Cargá tus dados y documentación del negocio.</p>
                    </div>
                  </div>
                  <div className="box-passo">
                    <h1>3.</h1>
                    <div className="texto">
                      <p>Listo! Bienvenido a Turnau Pro!</p>
                    </div>
                  </div>
                </div>
                <div className="box-inputs animationFade">
                  <div className="registro-screen box-registrar-salao">
                    <div className="input-box">
                      <small>Nombre del local</small>
                      <input
                        type="text"
                        value={this.state.nome || ""}
                        onChange={(event) =>
                          this.setState({ nome: event.target.value })
                        }
                      />
                    </div>
                    {/* <div className="input-box">
                      <small>Tipo de Negocio</small>
                      <select>
                        <option
                          value={2}
                          onChange={() => this.setState({ perfil: 2 })}
                        >
                          Salón de belleza
                        </option>
                        <option
                          value={3}
                          onChange={() => this.setState({ perfil: 3 })}
                        >
                          Profesional
                        </option>
                      </select>
                    </div> */}
                    {/* <div className="input-box">
                      <small>Cantidad de sucursales</small>
                      <input
                        type="number"
                        value={this.state.filiais || ""}
                        onChange={(event) =>
                          this.setState({ filiais: event.target.value })
                        }
                      />
                    </div> */}
                    {/* <div className="input-box">
                      <small>Nombre</small>
                      <input
                        type="text"
                        value={this.state.nome || ""}
                        onChange={(event) =>
                          this.setState({ nome: event.target.value })
                        }
                      />
                    </div>
                    <div className="input-box">
                      <small>Apellido</small>
                      <input
                        type="text"
                        value={this.state.apelido || ""}
                        onChange={(event) =>
                          this.setState({ apelido: event.target.value })
                        }
                      />
                    </div> */}
                    <div className="input-box">
                      <small>Teléfono de contacto</small>
                      <input
                        type="phone"
                        placeholder="+54123455689"
                        style={{ fontFamily: "Arial" }}
                        value={this.state.telefone || ""}
                        onChange={(event) =>
                          this.setState({ telefone: event.target.value })
                        }
                      />
                    </div>
                    <div className="input-box">
                      <small>Correo electrónico</small>
                      <input
                        type="email"
                        placeholder="nombre@email.com"
                        value={this.state.email || ""}
                        onChange={(event) =>
                          this.setState({ email: event.target.value })
                        }
                      />
                    </div>
                    <div className="input-box">
                      <small>Contraseña</small>
                      <input
                        type="password"
                        value={this.state.senha || ""}
                        onChange={(event) =>
                          this.setState({ senha: event.target.value })
                        }
                      />
                    </div>
                    <div className="input-box">
                      <small>Repite la contraseña</small>
                      <input
                        type="password"
                        value={this.state.senhaConfirm || ""}
                        onChange={(event) =>
                          this.setState({ senhaConfirm: event.target.value })
                        }
                      />
                    </div>
                    {/* <div className="input-box">
                      <small>Dirección del local</small>
                      <input
                        type="text"
                        value={this.state.local || ""}
                        onChange={(event) =>
                          this.setState({ local: event.target.value })
                        }
                      />
                    </div> */}
                    <div className="box-termos wow fadeInDown">
                      <input
                        type="checkbox"
                        onClick={() => {
                          this.setState({ agree: !this.state.agree });
                        }}
                      />
                      <p>Acepto los TERMINOS Y CONDICIONES</p>
                    </div>
                    <div className="footer-box">
                      <button
                        className="btn_primary"
                        style={{ height: 40 }}
                        onClick={() => this.verifyForm()}
                      >
                        <p>Comenzar</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : // ) : this.state.etapa == 2 ? (
            //   <div className="container-login">
            //     <div className="img-box animationFade">
            //       <img src={Pay} />
            //     </div>
            //     <div className="box-inputs animationFade">
            //       <div className="tit-area">
            //         <h5>Ahora algunos documentos para finalizar el registro</h5>
            //       </div>
            //       <div className="input-box">
            //         <small>Razon socia/Nombre y apellido*</small>
            //         <input
            //           type="text"
            //           value={this.state.razaoSocial || ""}
            //           onChange={(event) =>
            //             this.setState({ razaoSocial: event.target.value })
            //           }
            //         />
            //       </div>
            //       <div className="input-box">
            //         <small>CUIT/CUIL*</small>
            //         <input
            //           type="number"
            //           style={{ fontFamily: "Arial" }}
            //           value={this.state.cuit || ""}
            //           onChange={(event) =>
            //             this.setState({ cuit: event.target.value })
            //           }
            //         />
            //       </div>
            //       <div className="input-box">
            //         <small>CVU/CBU*</small>
            //         <input
            //           type="number"
            //           style={{ fontFamily: "Arial" }}
            //           value={this.state.cvu || ""}
            //           onChange={(event) =>
            //             this.setState({ cvu: event.target.value })
            //           }
            //         />
            //       </div>
            //       <div className="input-box">
            //         <small>Direcion Fiscal*</small>
            //         <input
            //           type="text"
            //           value={this.state.direcaoFiscal || ""}
            //           onChange={(event) =>
            //             this.setState({ direcaoFiscal: event.target.value })
            //           }
            //         />
            //       </div>

            //       <div
            //         className="footer-box"
            //         style={{ justifyContent: "space-between" }}
            //       >
            //         <button
            //           className="btn_secondary"
            //           style={{ height: 40 }}
            //           onClick={() =>
            //             this.setState({ etapa: this.state.etapa - 1 })
            //           }
            //         >
            //           <p>Volver</p>
            //         </button>
            //         <button
            //           className="btn_primary"
            //           style={{ height: 40 }}
            //           onClick={() => this.verifyDocs()}
            //         >
            //           <p>Ventaja</p>
            //         </button>
            //       </div>
            //     </div>
            //   </div>
            this.state.etapa == 2 ? (
              <div className="sucesso-box">
                <Lottie
                  options={defaultOptions}
                  height={250}
                  width={250}
                  isStopped={this.state.isStopped}
                  isPaused={this.state.isPaused}
                />
                <a href="/home-salon">
                  <button className="btn_primary">
                    <p>Terminar</p>
                    <i className="fas fa-chevron-right"></i>
                  </button>
                </a>
              </div>
            ) : this.state.etapa == 4 ? (
              <div className="erro-box">
                <Lottie
                  options={defaultOptionsErro}
                  height={250}
                  width={250}
                  isStopped={this.state.isStopped}
                  isPaused={this.state.isPaused}
                />
                <h5>
                  Algo salió mal, comuníquese con el administrador del sistema.
                </h5>
                <a href="/login-salao">
                  <button className="btn_primary">
                    <i className="fas fa-chevron-left"></i>
                    <p>Volver</p>
                  </button>
                </a>
              </div>
            ) : this.state.etapa == 5 ? (
              <div className="container-login">
                <div className="box-foto">
                  <h5>Escoje o tomate una foto para tu perfil.</h5>
                  <img className="fotoCapa" src={this.state.imgPrev} />
                  <div
                    className="container-input-promo wow fadeInUp"
                    style={{ marginTop: 10 }}
                  >
                    <label htmlFor="imagem">Foto de perfil</label>
                    <input
                      type="file"
                      name="imagem"
                      id="imagem"
                      onChange={this.handleChangeImage}
                    />
                    <i className="fas fa-upload"></i>
                  </div>

                  <div
                    className="footer-box"
                    style={{ justifyContent: "space-between" }}
                  >
                    <button
                      className="btn_secondary"
                      style={{ height: 40 }}
                      onClick={() => this.setState({ etapa: 2 })}
                    >
                      <p>Volver</p>
                    </button>
                    <button
                      className="btn_primary"
                      style={{ height: 40 }}
                      onClick={() => this.submitForm()}
                    >
                      <p>Ventaja</p>
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="info-ads-login">
            <div className="row">
              <div className="box-infos">
                <div className="box wow fadeInUp">
                  <img src={Local} />
                  <h5>Geolocalización</h5>
                  <p>Tus potenciales clientes cercanos te verán fácilmente.</p>
                </div>
                <div className="box wow fadeInUp">
                  <img src={Livro} />
                  <h5>Agenda</h5>
                  <p>
                    Tene tu agenda ordenada y resolve el problema del
                    ausentismo.
                  </p>
                </div>
                <div className="box wow fadeInUp">
                  <img src={Money} />
                  <h5>Cobro</h5>
                  <p>Todos los medios para cobrar disponibles.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Registro);
