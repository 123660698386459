import React from "react";
import "./index.scss";

import Lottie from "react-lottie";

//Componentes
import Header from "../../../components/header";
import Footer from "../../../components/Footer";

//Calling bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
//Calling WOWjs
import WOW from "wowjs";

import swal from "sweetalert";

//Redux
import Api from "../../../services/api";
import ApiV2 from "../../../services/api-v2";
import { Diversos } from "../../../services/diversos";
import { ApplicationState } from "../../../store";
import { User } from "../../../store/ducks/user/types";
import * as UserActions from "../../../store/ducks/user/actions";
import { Param } from "../../../store/ducks/param/types";
import * as ParamActions from "../../../store/ducks/param/actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Auth } from "../../../store/ducks/auth/types";

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  setParam(param: Param): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class MiDireccion extends React.Component<Props> {
  state: any = null;
  api: any;
  apiv2: any;
  userRef: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.apiv2 = new ApiV2();
    const { id } = props.match.params;
    this.state = {
      id: id,
      titulo: null,
      rua: null,
      cidade: null,
      numero: "",
      complemento: "",
      bairro: "",
      estado: null,
      cep: "",
    };
  }

  // Defining WOW
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();

    if (this.props.user.empresa != 0) {
      window.location.href = '/home-salon'
    }
  }

  private async submitForm() {
    const self = this;

    if (
      !self.state.titulo ||
      !self.state.rua ||
      !self.state.cidade ||
      !self.state.estado
    ) {
      swal("Atencion", "Campos obligatorios no rellenados.", "error");
      return;
    }

    self.setState({
      isLoading: true,
      loadingMsg: "Actualizando la información, por favor espere...",
    });

    try {
      let param = {
        titulo: self.state.titulo,
        rua: self.state.rua,
        numero: self.state.numero,
        complemento: self.state.complemento,
        bairro: self.state.bairro,
        cidade: self.state.cidade,
        estado: self.state.estado,
        cep: self.state.cep,
      };

      const { data } = await self.apiv2.post(
        `/customer/${self.props.user.codigo}/endereco`,
        param
      );

      if (data.status === false) {
        throw new Error(
          "No se puede actualizar la información, intente nuevamente."
        );
      } else {
        swal("Exito", "Información actualizada con éxito", "success");
      }
    } catch (e) {
      console.log(e);
      swal(
        "Atencion",
        "No se puede actualizar la información, intente nuevamente",
        "error"
      );
    } finally {
      await self.setState({ isLoading: false, loadingMsg: "" });
    }
  }

  render() {
    return (
      <>
        <Header registra={false} login={false} notificacoes={true} />

        <section className="container-mi-direccion">
          <div className="row-mi-direccion">
            <div className="header-direccion wow fadeInUp">
              <i className="fas fa-map-marker-alt"></i>
              <h5>Nueva Dirección</h5>
            </div>
            <div className="input-box wow fadeInUp">
              <small>Titulo*</small>
              <input
                type="text"
                value={this.state.titulo}
                onChange={(event) =>
                  this.setState({ titulo: event.target.value })
                }
              />
            </div>
            <div className="input-box wow fadeInUp">
              <small>Dirección*</small>
              <input
                type="text"
                value={this.state.rua}
                onChange={(event) => this.setState({ rua: event.target.value })}
              />
            </div>

            <div className="input-box wow fadeInUp">
              <small>Numero</small>
              <input
                type="number"
                value={this.state.numero}
                onChange={(event) =>
                  this.setState({ numero: event.target.value })
                }
              />
            </div>
            <div className="input-box wow fadeInUp">
              <small>Cuidad</small>
              <input
                type="text"
                value={this.state.cidade}
                onChange={(event) =>
                  this.setState({ cidade: event.target.value })
                }
              />
            </div>
            <div className="input-box wow fadeInUp">
              <small>Provincia</small>
              <select
                style={{ width: "100%", borderWidth: 0 }}
                value={this.state.estado}
                onChange={(event) =>
                  this.setState({ estado: event.target.value })
                }
              >
                {Diversos.getUFs().map((row) => (
                  <option value={row.value}>{row.label}</option>
                ))}
              </select>
            </div>
            <button
              className="btn_primary wow fadeInUp"
              disabled={this.state.isLoading}
              style={{ width: "100%", marginTop: 25 }}
              onClick={() => this.submitForm()}
            >
              <i className="fas fa-save"></i>
              <p>Guardar</p>
            </button>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MiDireccion);
