import { Reducer } from 'redux';
import { UserState, UserTypes } from './types';

const INITIAL_STATE: UserState = {
  data: {
    codigo: 0,
    nome: '',
    email: '',
    cpf: '',
    status: false,
    avatar: '',
  },
  loading: false,
  error: false,
}

const reducer: Reducer<UserState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserTypes.DO_LOGIN:
      return { ...state, loading: false, error: false, data: action.payload.data };
    case UserTypes.DO_LOGOUT:
      return { ...state, loading: false, error: false, data: null };
    default:
      return state;
  }
}

export default reducer;