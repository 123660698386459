import React from 'react';
import './index.scss';

import Salao from '../../assets/images/logo-salao-perfil.png';

//Calling WOWjs
import WOW from 'wowjs';


//Redux
import Api from '../../services/api';
import ApiV2 from '../../services/api-v2';
import { Diversos } from '../../services/diversos';
import { ApplicationState } from '../../store';
import { User } from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import { Param } from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Auth } from '../../store/ducks/auth/types';

interface StateProps {
    user: User;
    param: Param;
}

interface DispatchProps {
    doLogin(user: User): void;
    setParam(param: Param): void;
    setParam(param: Param): void;
    doLogout(): void;
}

interface OwnProps { }

type Props = StateProps & DispatchProps & OwnProps;

export class SideMenu extends React.Component<Props> {

    state: any = null;
    api: any;
    apiv2: any;
    userRef: any;

    constructor(props) {
        super(props);
        this.api = new Api();
        this.apiv2 = new ApiV2();
        this.state = {

        }
    }

    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();

        console.log(this.props.user)

        const self = this;

        if ((self.props.user) && (self.props.user.status === false)) { // SE USUARIO ESTIVER LOGADO REDIRECIONA PARA OUTRA PAGINA
            window.location.href = "/login"
        }
    }

    private async handleLogout() {
        const self = this;
        self.props.doLogout();
        self.setState({ redirect: "/login-salon" });
    }

    render() {
        return (
            <section className="container-perfil wow fadeInLeft">
                <div className="box-roxo" />
                {
                    this.props.user ?
                        <>
                            <div className="border-user">
                                <img src={this.props.user.avatar} className="user-img" />
                            </div>
                            <h4>{this.props.user.nome}</h4>
                        </>
                        :
                        window.location.href = '/login-salon'
                }
                <div className="container-dados-user">
                    <div className="header-container">
                        <h5>Mi Cuenta</h5>
                    </div>
                    <a className="line-opt-perfil" href="/mi-perfil-salon">
                        <div className="name">
                            <p style={{ marginLeft: 10 }}>Mi perfil</p>
                        </div>
                        <i className="fas fa-chevron-right arrow"></i>
                    </a>
                    <a className="line-opt-perfil" href="/tratamientos-salon">
                        <div className="name">
                            <p style={{ marginLeft: 10 }}>Tratamientos</p>
                        </div>
                        <i className="fas fa-chevron-right arrow"></i>
                    </a>
                    <a className="line-opt-perfil" href="/home-salon">
                        <div className="name">
                            <p style={{ marginLeft: 10 }}>Mi agenda</p>
                        </div>
                        <i className="fas fa-chevron-right arrow"></i>
                    </a>
                    <a className="line-opt-perfil" href="/promocion">
                        <div className="name">
                            <p style={{ marginLeft: 10 }}>Crear Promociones</p>
                        </div>
                        <i className="fas fa-chevron-right arrow"></i>
                    </a>
                    <a className="line-opt-perfil" href="/estadisticas">
                        <div className="name">
                            <p style={{ marginLeft: 10 }}>Estadisticas</p>
                        </div>
                        <i className="fas fa-chevron-right arrow"></i>
                    </a>
                    <a className="line-opt-perfil" href="/mi-profesionales">
                        <div className="name">
                            <p style={{ marginLeft: 10 }}>Mis Profesionales</p>
                        </div>
                        <i className="fas fa-chevron-right arrow"></i>
                    </a>
                    <a className="line-opt-perfil" href="/quienes-somos">
                        <div className="name">
                            <p style={{ marginLeft: 10 }}>Sobre Turnau Pro</p>
                        </div>
                        <i className="fas fa-chevron-right arrow"></i>
                    </a>
                    <div className="line-opt-perfil" style={{ border: 'none' }}
                        onClick={this.handleLogout.bind(this)}>
                        <div className="name">
                            <p style={{ marginLeft: 10 }}>Sair</p>
                        </div>
                        <i className="fas fa-sign-out-alt"></i>
                    </div>
                </div>
            </section>
        )
    }
};

const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.data,
    param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);

