import React from 'react';
import './index.scss';

import Lottie from 'react-lottie';
//Animaceos Lottie
import animationData from '../../../animations/loading.json';

//Componentes
import Header from '../../../components/header';
import Footer from '../../../components/Footer';

import Foto1 from '../../../assets/images/FotosSalao/salao-1.png';
import Foto2 from '../../../assets/images/FotosSalao/salao-2.png';
import Foto3 from '../../../assets/images/FotosSalao/salao-3.png';

//Calling bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
//Calling WOWjs
import WOW from 'wowjs';

//Redux
import Api from '../../../services/api';
import ApiV2 from '../../../services/api-v2';
import { Diversos } from '../../../services/diversos';
import { ApplicationState } from '../../../store';
import { User } from '../../../store/ducks/user/types';
import * as UserActions from '../../../store/ducks/user/actions';
import { Param } from '../../../store/ducks/param/types';
import * as ParamActions from '../../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Auth } from '../../../store/ducks/auth/types';
import Informacoes from '../../MenuCliente';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

interface StateProps {
    user: User;
    param: Param;
}

interface DispatchProps {
    doLogin(user: User): void;
    setParam(param: Param): void;
    setParam(param: Param): void;
}

interface OwnProps { }

type Props = StateProps & DispatchProps & OwnProps;

class Fotos extends React.Component<Props> {

    state: any = null;
    api: any;
    apiv2: any;
    userRef: any;

    constructor(props) {
        super(props);
        this.api = new Api();
        this.apiv2 = new ApiV2();
        this.state = {
            fotos: [],
            isLoading: false,
            limitFotos: 5,
            galeria: null,
            type: 2,
        }
    }


    // Defining WOW 
    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();

        if (this.props.user.empresa == 0) {
            window.location.href = '/home-cliente'
        }

        console.log(this.props.user)


        this.getFotos()
    }


    private async getFotos() {
        const self = this;
        self.setState({
            isLoading: true,
        });

        let param = {
            limit: 0,
            codigo: this.props.user.codigo,
            perfil: 2,
            tipo: 'galeria',
        };

        try {
            const { data } = await self.api.post('/User/galeria/', param);

            if (data.status == false) {
                return;
            }

            this.setState({ fotos: data.msg })
        } catch (e) {
            console.log('No se pudieron recuperar las fotos: ', e);
        } finally {
            self.setState({ isLoading: false });
        }
    }

    render() {

        return (
            <>
                <Header
                    registra={false}
                    login={false}
                    notificacoes={true}
                />

                <section className="container-fotos">
                    <div className="header wow fadeInUp">
                        <i className="fas fa-camera"></i>
                        <h5>Mis Fotos</h5>
                    </div>
                    <div className="row-fotos">
                        {
                            this.state.isLoading ?
                                <Lottie
                                    options={defaultOptions}
                                    height={350}
                                    width={350}
                                    isStopped={this.state.isStopped}
                                    isPaused={this.state.isPaused}
                                />
                                :
                                this.state.fotos.lenght <= 0 ?
                                    <h5>todavía no has añadido ninguna foto</h5>
                                    :
                                    this.state.fotos.map(item => (
                                        <div className="foto-box-img wow fadeInUp" style={{ backgroundImage: 'url(' + item.link + ')' }}>
                                            <div className="box-info">
                                                <button>
                                                    <i className="far fa-image"></i>
                                                </button>
                                                <button>
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                    </div>
                    <button className="btn-add">
                        <i className="fas fa-plus"></i>
                    </button>
                </section>


                <Footer />
            </>
        )
    }
}


const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.data,
    param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Fotos);