import {createStore, Store} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {UserState} from './ducks/user/types';
import {ParamState} from './ducks/param/types';
import {CartState} from './ducks/cart/types';
import {AuthState} from './ducks/auth/types';
import {CategoriaState} from './ducks/categoria/types';
import rootReducer from './ducks/rootReducer'

export interface ApplicationState {
  user: UserState,
  param: ParamState,
  auth: AuthState,
  cart: CartState,
  categoria: CategoriaState,
}

const persistConfig = {
  key: 'root',
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = createStore(persistedReducer);
let persistor = persistStore(store);
export { store, persistor };
