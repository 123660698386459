import axios from 'axios';
import https from 'https';
import {store} from '../store';
import * as AuthActions from '../store/ducks/auth/actions';
import moment from 'moment';

const apiAxio = axios.create({
  baseURL: 'https://api.turnau.com.ar',
  httpsAgent: new https.Agent({  
    rejectUnauthorized: false
  })
});

export default class Api {
  private async authorize() {
    let tmp = moment().unix();
    // console.log(Number(tmp));
    // console.log(Number(store.getState().auth.data.expire));
    if (
      store.getState().auth &&
      store.getState().auth.data &&
      store.getState().auth.data.expire
    ) {
      if (Number(tmp) < Number(store.getState().auth.data.expire)) {
        console.log('Access Token salvo!');
        return true;
      }
    }

    try {
      const {data} = await apiAxio.post(
        '/User/authorize',
        'grant_type=client_credentials&client_id=app&client_secret=39ee723957742f8893cb4a24fa4fece34c14fd9915378977ec56a52e38252bc2&scope=',
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
          },
        },
      );

      store.dispatch(
        AuthActions.setAuth({
          access_token: data.access_token,
          type: 'Bearer',
          expire: moment().unix() + data.expires_in,
        }),
      );

      return true;
    } catch (e) {
      console.log('api::authorize (ERROR): ' + process.env.BASE_URL + ' ', e);
      return false;
    }
  }

    public async put (url: string, body: any = null) {
    try {
      let headers = {};
      headers['Content-Type'] = 'application/json';
      headers['Accept'] = 'application/json';
      headers['Access-Control-Allow-Origin'] = '*';
      headers['Authorization'] = ' Bearer ' + process.env.API_KEY;

      var bodyEnd = {
        ...body,
        HTTP_X_AUTH_TOKEN: "fGtaUSg0Q2J8MC9XU2UxNSo4JnJ6c2JiT2hZe2JXaWMyNFVXdlZ7X3F2OG9iNDxYbG1LR3VeK0A8JD02UDtp"
      };

      return apiAxio.put(url, bodyEnd, { headers });
    } catch (e) {
      console.error('api (ERROR): ', e);
    }
  }


  public async get(url: string) {
    try {
      const responseAuth = await this.authorize();

      if (responseAuth === false)
        throw new Error('Nao foi possivel autenticar API');

      return apiAxio.get(
        url + '?access_token=' + store.getState().auth.data.access_token,
        {
          headers: {
            'X-Auth-token': '1ef1169d7e5619f955f2f625cd9b5d5ea969bb86',
          },
        },
      );
    } catch (e) {
      console.error('api (ERROR): ', e);
    }
  }

  public async getExterno(url: string) {
    try {
      return axios.get(url);
    } catch (e) {
      console.error('api (ERROR): ', e);
    }
  }

  public async post(url: string, body: any = null) {
    try {
      const responseAuth = await this.authorize();

      if (responseAuth == false)
        throw new Error('Nao foi possivel autenticar API');

      var bodyEnd = {
        ...body,
        HTTP_X_AUTH_TOKEN:
          'fGtaUSg0Q2J8MC9XU2UxNSo4JnJ6c2JiT2hZe2JXaWMyNFVXdlZ7X3F2OG9iNDxYbG1LR3VeK0A8JD02UDtp',
      };

      return apiAxio.post(
        url + '?access_token=' + store.getState().auth.data.access_token,
        bodyEnd,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-Auth-token': 'YWRtOmxlb0AxOTkz',
          },
        },
      );
    } catch (e) {
      console.log('api (ERROR): ', e);
    }
  }
  
  // public async post (url: string, body: any = null) {
  //   try {
  //     let headers = {};
  //     headers['Content-Type'] = 'application/json';
  //     headers['Accept'] = 'application/json';
  //     headers['Access-Control-Allow-Origin'] = '*';
  //     headers['Authorization'] = ' Bearer ' + process.env.API_KEY;

  //     var bodyEnd = {
  //       ...body,
  //       HTTP_X_AUTH_TOKEN: "fGtaUSg0Q2J8MC9XU2UxNSo4JnJ6c2JiT2hZe2JXaWMyNFVXdlZ7X3F2OG9iNDxYbG1LR3VeK0A8JD02UDtp"
  //     };

  //     return apiAxio.post(url, bodyEnd, { headers });
  //   } catch (e) {
  //     console.error('api (ERROR): ', e);
  //   }
  // }
}
