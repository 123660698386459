import React from 'react';
import './index.scss';

import Lottie from 'react-lottie';
//Animaceos Lottie
import animationData from '../../../animations/loading.json';

//Componentes
import Header from '../../../components/header';
import Footer from '../../../components/Footer';

//Calling bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
//Calling WOWjs
import WOW from 'wowjs';


//Redux
import Api from '../../../services/api';
import ApiV2 from '../../../services/api-v2';
import { Diversos } from '../../../services/diversos';
import { ApplicationState } from '../../../store';
import { User } from '../../../store/ducks/user/types';
import * as UserActions from '../../../store/ducks/user/actions';
import { Param } from '../../../store/ducks/param/types';
import * as ParamActions from '../../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Auth } from '../../../store/ducks/auth/types';
import Informacoes from '../../MenuCliente';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  setParam(param: Param): void;
}

interface OwnProps { }

type Props = StateProps & DispatchProps & OwnProps;

class MiInformacion extends React.Component<Props> {

  state: any = null;
  api: any;
  apiv2: any;
  userRef: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.apiv2 = new ApiV2();
    this.state = {
      dados: [],
      isLoading: false,
    }
  }

  // Defining WOW 
  componentDidMount() {
    new WOW.WOW({
      live: false
    }).init();

    if (this.props.user.empresa == 0) {
      window.location.href = '/home-cliente'
    }

    this.getTelefone()
  }

  private async getTelefone() {
    const self = this;

    this.setState({ isLoading: true })

    let param = {
      codigo: this.props.user.codigo,
    };

    try {
      const { data } = await self.api.post('/User/endereco', param);

      if (data.status === false) {
        throw new Error('Nenhum dado localizado');
      } else {
        self.setState({ dados: data.msg, });
        this.setState({ isLoading: false })
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isLoading: false })
    }
  }

  render() {
    return (
      <>
        <Header
          registra={false}
          login={false}
          notificacoes={true}
        />
        <section className="container-mi-informacion">
          <div className="row-mi-informacion">
            <div className="header-perfil wow fadeInUp">
              <i className="fas fa-user"></i>
              <h5>Mi Información</h5>
            </div>

            {
              this.state.isLoading ?
                <Lottie
                  options={defaultOptions}
                  height={350}
                  width={350}
                  isStopped={this.state.isStopped}
                  isPaused={this.state.isPaused}
                />
                :
                <>
                  <div className="input-box wow fadeInUp">
                    <small>Nobre*</small>
                    <input type="text" value={this.props.user.nome} />
                  </div>
                  <div className="input-box wow fadeInUp">
                    <small>Correo electrónico*</small>
                    <input type="email" value={this.props.user.email} />
                  </div>
                  <div className="input-box wow fadeInUp">
                    <small>Télefono</small>
                    <input type="phone" value={this.state.dados.tel} />
                  </div>
                </>
            }
            {/* <button className="btn_primary wow fadeInUp" style={{width:'100%', marginTop:25}}>
                        <p>Guardar</p>
                    </button> */}
          </div>
        </section>
        <Footer />
      </>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MiInformacion);