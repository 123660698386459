import React from "react";
import "./index.scss";

//Componentes
import Logo from "../../assets/images/logo-vetor-hd.svg";
import LogoSalao from "../../assets/images/turnau-calendario-roxo.png";
import LoginImg from "../../assets/images/login-img.svg";
import LoginImgSalao from "../../assets/images/login-img-salao.svg";

import MascaraMobileCliente from "../../components/MascaraMobileCliente";

//Calling bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
//Calling WOWjs
import WOW from "wowjs";

//Redux
import Api from "../../services/api";
import ApiV2 from "../../services/api-v2";
import { Diversos } from "../../services/diversos";
import { ApplicationState } from "../../store";
import { User } from "../../store/ducks/user/types";
import * as UserActions from "../../store/ducks/user/actions";
import { Param } from "../../store/ducks/param/types";
import * as ParamActions from "../../store/ducks/param/actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Auth } from "../../store/ducks/auth/types";

import Lottie from "react-lottie";
//Animaceos Lottie
import animationData from "../../animations/loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface StateProps {
  user: User;
  param: Param;
  auth: Auth;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  setAuth(auth: Auth): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class LoginEscolha extends React.Component<Props> {
  api: any;
  apiv2: any;
  state: any;
  userRef: any;
  passwordRef: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.apiv2 = new ApiV2();
    this.userRef = React.createRef();
    this.passwordRef = React.createRef();
    this.state = {
      isLoading: false,
      showPassword: false,
      email: " ",
      senha: " ",
    };
  }

  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();

    const self = this;

    if (
      self.props.user &&
      self.props.user.status === true &&
      !self.props.user.fgFuncionario
    ) {
      // SE USUARIO ESTIVER LOGADO REDIRECIONA PARA OUTRA PAGINA
      window.location.href = "/home-cliente";
    } else if (
      self.props.user &&
      self.props.user.status === true &&
      self.props.user.fgFuncionario
    ) {
      window.location.href = "/home-salon";
    }
  }

  render() {
    return (
      <>
        <MascaraMobileCliente />
        <button className="voltar-btn" onClick={() => window.history.back()}>
          <i className="fas fa-chevron-left"></i>
          <p>Volver</p>
        </button>
        <div className="login-screen">
          <div className="row">
            <div className="box-1-ico box-login-cliente">
              <img
                src={Logo}
                className="logo"
                alt="Logo Turnau"
                title="Logo Turnau"
              />
              <img src={LoginImg} className="pic wow fadeInLeft" />
              <a
                className="btn btn-lg btn_primary"
                href="/login-cliente"
                target="_self"
                title="Iniciar sesión como cliente"
              >
                <i className="fas fa-sign-in"></i>
                <p>Iniciar sesión como cliente</p>
              </a>
            </div>

            <div className="box-1-ico box-login-salao">
              <img
                src={LogoSalao}
                className="logo"
                alt="Logo TurnauPRO"
                title="Logo TurnauPRO"
              />
              <img src={LoginImgSalao} className="pic wow fadeInLeft" />
              <a
                className="btn btn-lg btn_primary_2"
                href="/login-salon"
                target="_self"
                title="Iniciar sesión como centro"
              >
                <i className="fas fa-sign-in"></i>
                <p>Iniciar sesión como centro</p>
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginEscolha);
