import React from 'react';
import './index.scss';


interface Props {
    nome: String,
    promo: number,
    valor: number,
    tempo: String,
    id: string,
    idPromo: String,
    Salao: string,
}

export class LinhaServico extends React.Component<Props> {

    state: any = null;

    constructor(props) {
        super(props);
        this.state = {
            valorDesconto: '',
        }
    }

    componentDidMount() {

        let desconto = this.props.promo;
        let valor = this.props.valor;

        let valorTotal = valor * desconto / 100;

        this.setState({ valorDesconto: valorTotal })
    }

    render() {
        return (
            <>
                <a href={'/promocao/programar/' + this.props.id + '/' + this.props.Salao + '/' + this.props.promo}>
                    <section className="linha-servico">
                        <h5>{this.props.nome}</h5>
                        <div className="dados-servico">
                            <div className="dados-1">
                                <p className="valorAntigo">${this.props.valor}</p>
                                <p>${this.state.valorDesconto}</p>
                                <span>
                                    <p>Tiempo {this.props.tempo} minuto(s)</p>
                                </span>
                            </div>
                            <div className="dados-2">
                                <div className="box-stars">

                                </div>
                                <i className="fas fa-chevron-right"></i>
                            </div>
                        </div>
                    </section>
                </a>
            </>
        )
    }
};

export default LinhaServico;

