import React from 'react';
import './index.scss';

import WOW from 'wowjs';


//Redux
import ApiV2 from '../../services/api-v2';
import { Diversos } from '../../services/diversos';
import { ApplicationState } from '../../store';
import { User } from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import { Param } from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  setParam(param: Param): void;
}

interface OwnProps {

}

type Props = StateProps & DispatchProps & OwnProps;

class CookiesContainer extends React.Component<Props> {

  state: any = null;
  api: any;
  apiv2: any;
  userRef: any;

  constructor(props) {
    super(props);
    this.apiv2 = new ApiV2();
    this.state = {

    }
  }

  componentDidMount() {
    new WOW.WOW({
      live: false
    }).init();
  }


  render() {
    return (
      this.props.param.acceptCookies == true ?
        null
        :
        <div className="container-cookies wow fadeInUp">
          <div className="txt-box">
            <p>Utilizamos cookies y otras tecnologías de seguimiento para mejorar su experiencia de navegación en nuestro sitio web. Si continúa navegando, acepta el uso de cookies y otras tecnologías de seguimiento. Para mostrar contenido personalizado, deberá hacer clic en el botón "Entendido".</p>
          </div>
          <button className="btn_primary" onClick={() => this.props.setParam({ ...this.props.param, acceptCookies: true })}>
            <p>comprendido</p>
          </button>
        </div>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CookiesContainer);
