import React from "react";
import "./index.scss";
import swal from "sweetalert";
//Componentes
import Logo from "../../assets/images/turnau-calendario-roxo.png";
import LoginImg from "../../assets/images/login-img-salao.svg";

//Calling bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
//Calling WOWjs
import WOW from "wowjs";

import MascaraMobile from "../../components/MascaraMobile";

//Redux
import Api from "../../services/api";
import ApiV2 from "../../services/api-v2";
import { Diversos } from "../../services/diversos";
import { ApplicationState } from "../../store";
import { User } from "../../store/ducks/user/types";
import * as UserActions from "../../store/ducks/user/actions";
import { Param } from "../../store/ducks/param/types";
import * as ParamActions from "../../store/ducks/param/actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Auth } from "../../store/ducks/auth/types";

import Lottie from "react-lottie";
import animationData from "../../animations/loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface StateProps {
  user: User;
  param: Param;
  auth: Auth;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  setAuth(auth: Auth): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class LoginSalao extends React.Component<Props> {
  state: any;
  api: any;
  apiv2: any;
  userRef: any;
  passwordRef: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.apiv2 = new ApiV2();
    this.userRef = React.createRef();
    this.passwordRef = React.createRef();
    this.state = {
      isLoading: false,
      showPassword: false,
      email: "",
      senha: "",

      boxRec: false,
    };
  }

  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();

    const self = this;

    if (
      self.props.user &&
      self.props.user.status === true &&
      !self.props.user.fgFuncionario
    ) {
      // SE USUARIO ESTIVER LOGADO REDIRECIONA PARA OUTRA PAGINA
      window.location.href = "/home-cliente";
    } else if (
      self.props.user &&
      self.props.user.status === true &&
      self.props.user.fgFuncionario
    ) {
      window.location.href = "/home-salon";
    }
  }

  private async recSenha() {
    const self = this;

    if (!this.state.email) {
      swal("Atencion", "Necessario informar el correo electrónico", "error");
      return false;
    }

    self.setState({ isLoading: true });

    let param = {
      email: this.state.email,
    };

    try {
      const { data } = await self.api.post("/User/resetPassword", param);

      if (data.status === true) {
        swal(
          "Exito",
          "Correo electrónico enviado con éxito, revise su correo electrónico.",
          "success"
        );
      } else {
        console.log(data);
        swal(
          "Atencion",
          "El correo electrónico no se pudo enviar ahora, intente nuevamente más tarde o verifique que el correo electrónico sea correcto.",
          "error"
        );
      }
    } catch (e) {
      console.log("nao foi possivel enviar a senha " + e);
    } finally {
      self.setState({ isLoading: false });
      self.setState({ boxRec: false });
    }
  }

  private async login() {
    const self = this;

    if (!this.state.email || !this.state.senha) {
      swal("Atencion", "Formulario incompleto", "error");
      return false;
    }

    this.setState({
      isLoading: true,
    });

    let param = {
      email: this.state.email,
      senha: this.state.senha,
    };
    try {
      const { data } = await this.api.post("/User/login", param);

      if (data.status === false) {
        swal(
          "Atencion",
          "No se puede iniciar sesión, verifique los datos y vuelva a intentarlo",
          "error"
        );
        this.setState({ isLoading: false });
      } else {
        if (data.salao != null && data.salao != "") {
          await self.props.doLogin({
            codigo: data.salao.codigo,
            nome: data.salao.nome,
            email: data.salao.email,
            cpf: data.salao.cnpj,
            status: true,
            fgFuncionario: true,
            avatar:
              data.avatar !== "" && data.avatar !== "undefined"
                ? data.avatar
                : null,
            empresa: data.salao.codigo,
          });

          await self.props.setParam({ ...self.props.param, perfil: 2 });

          window.location.href = "/home-salon";
        } else if (data.profissional != null && data.profissional != "") {
          await self.props.doLogin({
            codigo: data.profissional.codigo,
            nome: data.profissional.nome,
            email: data.profissional.email,
            cpf: data.profissional.cnpj,
            status: true,
            fgFuncionario: true,
            avatar:
              data.avatar !== "" && data.avatar !== "undefined"
                ? data.avatar
                : null,
            empresa: data.profissional.empresa,
          });

          await self.props.setParam({ ...self.props.param, perfil: 3 });

          window.location.href = "/home-salon";
        } else {
          swal("Atencion", "Login incorrecto", "error");
        }
      }
    } catch (e) {
      console.log(e);
      swal("Atencion", "Login incorrecto", "error");
      this.setState({ isLoading: false });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    return (
      <>
        <MascaraMobile />
        <button className="voltar-btn" onClick={() => window.history.back()}>
          <i className="fas fa-chevron-left"></i>
          <p>Volver</p>
        </button>
        <div className="login-screen">
          <div className="row">
            <div className="box-1-ico">
              {this.state.isLoading == true ? (
                <Lottie
                  options={defaultOptions}
                  height={350}
                  width={350}
                  isStopped={this.state.isStopped}
                  isPaused={this.state.isPaused}
                />
              ) : (
                <img src={LoginImg} className="pic wow fadeInLeft" />
              )}
            </div>
            <div
              className={
                this.state.boxRec == false
                  ? "box-2-dados"
                  : "box-2-dados mudarCor"
              }
            >
              {this.state.boxRec ? (
                <div className="box-rec-senha wow fadeInRight">
                  <h4>
                    No se preocupe, le enviaremos un correo electrónico con
                    instrucciones sobre cómo restablecer su contraseña.
                  </h4>
                  <div className="input-box">
                    <small>Correo electrónico*</small>
                    <input
                      type="email"
                      value={this.state.email || ""}
                      onChange={(event) =>
                        this.setState({ email: event.target.value })
                      }
                    />
                  </div>
                  <button
                    className="btn_primary"
                    onClick={() => this.recSenha()}
                  >
                    <i className="fas fa-sign-in-alt"></i>
                    <p>Enviar</p>
                  </button>
                  <button
                    className="btn_secondary"
                    style={{ border: "solid 1px" }}
                    onClick={() => this.setState({ boxRec: false })}
                  >
                    <i className="fa fa-angle-left"></i>
                    <p>Volver</p>
                  </button>
                </div>
              ) : (
                <div className="box-login wow fadeInRight">
                  <img
                    src={Logo}
                    className="logo"
                    alt="Logo Turnau"
                    title="Logo Turnau"
                    onClick={() => (window.location.href = "/")}
                  />
                  <h4>Ingresar Salao</h4>
                  <div className="input-box">
                    <small>Correo electrónico*</small>
                    <input
                      type="email"
                      value={this.state.email || ""}
                      onChange={(event) =>
                        this.setState({ email: event.target.value })
                      }
                    />
                  </div>
                  <div className="input-box">
                    <small>Contraseña*</small>
                    <input
                      type="password"
                      value={this.state.senha || ""}
                      onChange={(event) =>
                        this.setState({ senha: event.target.value })
                      }
                    />
                  </div>
                  <button
                    className="btn_primary"
                    onClick={this.login.bind(this)}
                  >
                    <i className="fas fa-sign-in-alt"></i>
                    <p>Ingresar</p>
                  </button>
                  <button
                    className="btn_secondary"
                    style={{ border: "solid 1px" }}
                    onClick={() => (window.location.href = "/registrate-salon")}
                  >
                    <i className="fas fa-user-plus"></i>
                    <p>Regístrate</p>
                  </button>
                  <button
                    style={{ border: "none", backgroundColor: "transparent" }}
                    onClick={() =>
                      (window.location.href = "/recuperar-contrasena-salon")
                    }
                  >
                    <h5>Recuperar contraseña?</h5>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginSalao);
