import React from 'react';
import './index.scss';

import Logo from '../../assets/images/logo-4k.png';
import Apple from '../../assets/images/footer/apple.png';
import Google from '../../assets/images/footer/google.png';
import Art from '../../assets/images/mobile-art-1.png';

interface Props {

}

export class MascaraMobile extends React.Component<Props> {

    state: any = null;

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <section className="mascara-mobile">
                <img src={Logo} className="logo"/>
                <p>Para su mejor experiencia, le recomendamos que utilice nuestra aplicación.</p>
                <h4>Descárgalo ahora directamente desde tu tienda</h4>
                <div className="box-lojas">
                    <img src={Apple}/>
                    <img src={Google}/>
                </div>
                <img src={Art} className="art"/>
            </section>
        )
    }
};

export default MascaraMobile;

