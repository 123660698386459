import React from "react";
import "./index.scss";

import Lottie from "react-lottie";

//Componentes
import Header from "../../../components/header";
import Footer from "../../../components/Footer";

//Calling bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
//Calling WOWjs
import WOW from "wowjs";

import Usuario from "../../assets/images/usuario.png";

//Redux
import Api from "../../../services/api";
import ApiV2 from "../../../services/api-v2";
import { Diversos } from "../../../services/diversos";
import { ApplicationState } from "../../../store";
import { User } from "../../../store/ducks/user/types";
import * as UserActions from "../../../store/ducks/user/actions";
import { Param } from "../../../store/ducks/param/types";
import * as ParamActions from "../../../store/ducks/param/actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Auth } from "../../../store/ducks/auth/types";
import Informacoes from "../../MenuCliente";
import swal from "sweetalert";

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  setParam(param: Param): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class MiDireccion extends React.Component<Props> {
  state: any = null;
  api: any;
  apiv2: any;
  userRef: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.apiv2 = new ApiV2();
    this.state = {
      enderecos: [],
    };
  }

  // Defining WOW
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();

    this.getByEnderecos();
  }

  private async getByEnderecos() {
    const self = this;

    try {
      const { data } = await self.apiv2.get(
        `/customer/${self.props.user.codigo}/enderecos`
      );

      if (data.status == false) {
        console.log(
          Error("No se puede cargar la información, intente nuevamente.")
        );
      }

      self.setState({
        enderecos: data.msg,
      });
      console.log(this.state.enderecos);
    } catch (e) {
      console.error(e);
    } finally {
    }
  }

  private async setEndereco() {
    const self = this;

    let param = {
      perfil: 1,
      codigo: this.props.user.codigo,
      rua: this.state.rua,
      numero: this.state.numero,
      bairro: "",
      cidade: this.state.cidade,
      estado: "",
    };

    try {
      const { data } = await self.api.post("/User/update", param);

      if (data.status === false) {
        alert("Ocurrió un error inesperado, inténtalo de nuevo más tarde");
      } else {
        alert("Dirección actualizada correctamente");
        window.location.href = "/mi-direccion";
        console.log(param);
        this.setState({ isLoading: false });
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  private async handleDelete(row) {
    const self = this;

    if (!row) {
      swal(
        "Atencion",
        "No se puede borrar la información, intente nuevamente",
        "error"
      );
      return false;
    }

    swal({
      title: "Aviso",
      text: `¿Confirmar la eliminación de la dirección ${self.state.rua}?`,
      icon: "warning",
      buttons: ["No", "Si"],
      dangerMode: true,
    })
      .then((willDelete) => {
        if (!willDelete) {
          throw null;
        }

        let tmp = self.state.enderecos;
        let achou = false;

        for (let i = 0; i < tmp.length && !achou; i++) {
          if (tmp[i].id === row.id) {
            tmp[i].isLoading = true;
            achou = true;
          }
        }

        return self.apiv2.delete(
          `/customer/${self.props.user.codigo}/endereco/${row.id}`
        );
      })
      .then(({ data }) => {
        if (!data.status) {
          swal(
            "Atencion",
            "No se puede borrar la información, intente nuevamente.",
            "error"
          );
        } else {
          swal("Exite", "Información borrada exitosamente", "success").then(
            () => {
              window.location.reload();
            }
          );
        }
      })
      .catch((e) => {
        if (e) {
          swal(
            "Atencion",
            "No se puede borrar la información, intente nuevamente.",
            "error"
          );
        }
      });

    return true;
  }

  render() {
    return (
      <>
        <Header registra={false} login={false} notificacoes={true} />

        <section className="container-mi-direccion">
          <div className="row-mi-direccion">
            <div className="header-direccion wow fadeInUp">
              <i className="fas fa-map-marker-alt"></i>
              <h5>Mi Direcciones</h5>
            </div>
            {this.state.enderecos.map((item) => (
              <div className="container-end">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h5 style={{ marginRight: 15 }}>{item.titulo}</h5>
                  <p style={{ marginRight: 10 }}>
                    {item.rua},{item.numero}
                  </p>
                  <p style={{ marginRight: 10 }}>{item.cidade}</p>
                  <p style={{ marginRight: 10 }}>{item.estado}</p>
                </div>
                <i
                  className="fas fa-trash"
                  onClick={this.handleDelete.bind(this, item)}
                ></i>
              </div>
            ))}
            <a
              className="btn_primary"
              style={{ width: "100%" }}
              href={"/nueva-direcion"}
            >
              <p>Nueva dirección</p>
            </a>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MiDireccion);
