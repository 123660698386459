/**
 * ACTIONS TYPES
 */
export enum CategoriaTypes {
  ADD = '@categoria/ADD',
  RESET = '@categoria/RESET',
}

/**
 * Data types
 */
export interface Categoria {
  items: Array<{codigo: number, nome: string}>,
}

/**
 * State types
 */
export interface CategoriaState {
  readonly data: Categoria
}