import React from 'react';
import './index.scss';

import Logo from '../../assets/images/turnau.png';
import Mais from '../../assets/icons/mais.svg';
import Tico from '../../assets/icons/t-ico.svg';
import ContainerNotificacoesCliente from '../ContainerNotificacoesCliente';
import ContainerModal from '../ModalNotificacao';
import Cookies from '../CookiesContainer'

//Redux
import ApiV2 from '../../services/api-v2';
import { Diversos } from '../../services/diversos';
import { ApplicationState } from '../../store';
import { User } from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import { Param } from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Auth } from '../../store/ducks/auth/types';

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  setParam(param: Param): void;
}

interface OwnProps {
  registra: boolean,
  login: boolean,
  notificacoes: boolean,
}

type Props = StateProps & DispatchProps & OwnProps;

class Header extends React.Component<Props> {

  state: any = null;
  api: any;
  apiv2: any;
  userRef: any;

  constructor(props) {
    super(props);
    this.apiv2 = new ApiV2();
    this.state = {
      menu: false,
      display: 'none',
      notificacoes: false,
      modal: false,
      numNotificacoes: '',

    }
  }

  componentDidMount() {
    this.getNotificationCountCliente();

    setInterval(() => {
      this.getNotificationCountCliente();
    }, 30000);
  }

  ///FUNCAO BOX MENU AJUDA E LOGIN---------------
  menuFuncao() {
    this.setState({
      menu: !this.state.menu,
    })
    console.log(this.state.menu)

    if (this.state.menu == true) {
      this.setState({
        display: 'flex',
      })
    }
    else {
      this.setState({
        display: 'none',
      })
    }
  }
  private async getNotificationCountCliente() {
    const self = this;

    try {
      const { data } = await self.apiv2.get(
        `/notifications?fgtotal=true&page=${this.state.page}&per_page=${this.state.perPage}&filter=lido:N|destinatario:${this.props.user.codigo}|tipo:cliente`,
      );

      if (!data.status) {
        // console.log('No se puede cargar la información, intente nuevamente. getNotificationCount');
      }
      self.setState({
        numNotificacoes: data.msg,
      });
    } catch {

    } finally {

    }
  }

  render() {
    return (
      <>
        <Cookies />
        <header className="desktop-header">
          <div className="row-header">
            <img src={Logo} className="logo-header" onClick={() => window.location.href = "/"} />
            <div className="box-btn">

              {/* //ICONE DE CADASTRAR SALAO---------------- */}
              {
                this.props.registra == true ?
                  <a href="/login-salon">
                    <button className="registra">
                      <img src={Mais} />
                      Registrá tu negocio
                    </button>
                  </a>
                  :
                  null
              }

              {/* //BOTAO MENU---------------- */}
              {
                this.props.login == true ?
                  <button className="opcoes" onClick={() => this.menuFuncao()}>
                    <div className="ico">
                      <img src={Tico} />
                    </div>
                    <i className="fas fa-chevron-down"></i>
                  </button>
                  :
                  null
              }


              {/* //ICONE DE NOTIFICACOES---------------- */}
              {
                this.props.notificacoes == true ?

                  <div className="notificacoes-box-ico" onClick={() => this.setState({ notificacoes: !this.state.notificacoes })}>
                    {
                      this.state.numNotificacoes <= 0 || null ?
                        null
                        :
                        <div className="contagem">
                          <p>{this.state.numNotificacoes}</p>
                        </div>
                    }
                    {
                      this.state.notificacoes == true ?
                        <i className="fas fa-times"></i>
                        :
                        this.state.notificacoes == false ?
                          <i className="far fa-bell"></i>
                          :
                          null
                    }

                  </div>
                  :
                  null
              }
            </div>
          </div>
          <div className="menu-header" style={{ display: this.state.display }}>
            <a className="row-menu" href="/ayuda">
              <i className="fas fa-headset"></i>
              <p>Ayuda en línea</p>
            </a>
            <a href="/login" className="row-menu">
              <i className="fas fa-sign-out-alt"></i>
              <p>Ingresar/Registrarte</p>
            </a>
          </div>
        </header>
        <ContainerNotificacoesCliente display={this.state.notificacoes} />
        <ContainerModal modal={this.state.modal} />
      </>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
