import React from "react";
import "./index.scss";

//Redux
import Api from "../../services/api";
import { Diversos } from "../../services/diversos";
import { ApplicationState } from "../../store";
import { User } from "../../store/ducks/user/types";
import * as UserActions from "../../store/ducks/user/actions";
import { Param } from "../../store/ducks/param/types";
import * as ParamActions from "../../store/ducks/param/actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

//Componentes
import Header from "../../components/header";
import Footer from "../../components/Footer";
import Mais from "../../assets/icons/mais.svg";
import Salao from "../../assets/images/salao.png";
import Missao from ".././../assets/images/missao.png";
import Visao from ".././../assets/images/visao.png";
import Valores from ".././../assets/images/valores.png";
import Loading from "../../components/loading";

import BannerSlide from "../../components/Banner";

import WOW from "wowjs";

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class Home extends React.Component<Props> {
  api: any;
  state: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      loading: false,
      width: 100,
      search: "",
      currentLongitude: 0,
      currentLatitude: 0,
      promocao: [],
      isLoadingPromocao: false,
      salaoProximo: [],
      isLoadingSalaoProximo: false,
      isLoadingPromocoes: false,
      promocoes: [],
    };
  }

  componentDidMount() {
    // Inicia o WOWJS
    new WOW.WOW({
      live: false,
    }).init();
  }

  private async getPromocao() {
    // eslint-disable-next-line consistent-this
    const self = this;

    self.setState({ isLoadingPromocao: true }, () =>
      this.props.setParam({ ...this.props.param, isLoading: true })
    );

    let param = {
      limit: 0,
      lat: self.props.param.latitude,
      lng: self.props.param.longitude,
    };

    try {
      const { data } = await self.api.post("/Servico/getByPromocao", param);

      if (data.status === false) {
        throw new Error("Nenhum servico localizado");
      } else {
        self.setState({ promocao: data.msg });
      }
    } catch (e) {
      console.log(e);
      self.setState({ promocao: [] });
    } finally {
      self.setState({ isLoadingPromocao: false }, () =>
        this.props.setParam({ ...this.props.param, isLoading: false })
      );
    }
  }

  render() {
    return (
      <>
        <Header registra={true} login={true} notificacoes={false} />
        {this.state.loading == true ? <Loading /> : null}

        <div className="home-main">
          <BannerSlide />
          <div className="layer-1">
            <div className="row">
              <div className="txt-box">
                <h3 className="wow fadeInLeft">
                  Si sos profesional y querés{" "}
                  <span>hacer visibleu negocio</span> para obtner mas reservas
                  registra tu negocio con nosotros!
                </h3>
                <a href="/login">
                  <button className="btn_primary">
                    <img src={Mais} />
                    <p>Registrarte</p>
                  </button>
                </a>
              </div>
              <img src={Salao} className="wow fadeInRight" />
            </div>
          </div>
          <div className="row">
            <div className="box-missao">
              <div className="box wow fadeInUp">
                <img src={Missao} />
                <h5>Geolocalización</h5>
                <p>Tus potenciales clientes cercanos te verán fácilmente.</p>
              </div>
              <div className="box wow fadeInUp">
                <img src={Visao} />
                <h5>Agenda</h5>
                <p>
                  Tene tu agenda ordenada y resolve el problema del ausentismo.
                </p>
              </div>
              <div className="box wow fadeInUp">
                <img src={Valores} />
                <h5>Cobro</h5>
                <p>Todos los medios para cobrar disponibles.</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);
