import React from 'react';
import './index.scss';

//Componentes
import Header from '../../../components/header';
import Footer from '../../../components/Footer';
import Cartao from '../../../components/CardCartao';

//Calling bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
//Calling WOWjs
import WOW from 'wowjs';

import moment from 'moment';

//Redux
import Api from '../../../services/api';
import ApiV2 from '../../../services/api-v2';
import { Diversos } from '../../../services/diversos';
import { ApplicationState } from '../../../store';
import { User } from '../../../store/ducks/user/types';
import * as UserActions from '../../../store/ducks/user/actions';
import { Param } from '../../../store/ducks/param/types';
import * as ParamActions from '../../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Auth } from '../../../store/ducks/auth/types';
import Informacoes from '../../MenuCliente';

import Lottie from 'react-lottie';

import animationData from '../../../animations/loading.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


interface StateProps {
    user: User;
    param: Param;
}

interface DispatchProps {
    doLogin(user: User): void;
    setParam(param: Param): void;
    setParam(param: Param): void;
}

interface OwnProps { }

type Props = StateProps & DispatchProps & OwnProps;

class Horarios extends React.Component<Props> {

    state: any = null;
    api: any;
    apiv2: any;
    userRef: any;

    constructor(props) {
        super(props);
        this.api = new Api();
        this.apiv2 = new ApiV2();
        this.state = {
            loading: true,
            agenda: [],
        }
    }

    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();

        if (this.props.user.empresa == 0) {
            window.location.href = '/home-cliente'
        }

        moment.locale('pt-BR');

        this.getAgenda()
    }

    async getAgenda() {
        const self = this;

        this.setState({ loading: true })

        try {
            const { data } = await self.apiv2.get(`/schedules/${this.props.user.codigo}`);


            if (data.status === false) {
                alert('erro')
            } else {

                this.setState({ agenda: data.msg })

                const tmp: any = [];

                for (let i = 0; i < data.msg.length; i++) {
                    tmp.push({
                        _id: data.msg[i].PEDIDO,
                        name: data.msg[i].clienteDados.NOME,
                        startDateTime: moment(`${data.msg[i].DATA} ${data.msg[i].HORA}`, 'DD/MM/YYYY HH:mm').toDate(),
                        endDateTime: moment(`${data.msg[i].DATA} ${data.msg[i].HORA}`, 'DD/MM/YYYY HH:mm').add(data.msg[i].ITENS[0].servicoDados.TEMPO, 'minutes').toDate(),
                        classes: 'color-1',
                        funcionario: data.msg[i].PROFISSIONAL ? data.msg[i].PROFISSIONAL : null
                    });
                }
                this.setState({ agenda: data.msg });

                console.log(this.state.agenda)
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.setState({ loading: false })
        }
    }

    render() {
        return (
            <>
                <Header
                    registra={false}
                    login={false}
                    notificacoes={true}
                />
                <section className="container-horarios">
                    <div className="row-horarios">
                        <div className="header-horarios">
                            <i className="far fa-clock"></i>
                            <h5>historia del tiempo</h5>
                        </div>
                        {
                            this.state.loading ?
                                <Lottie options={defaultOptions}
                                    height={400}
                                    width={400}
                                    isStopped={this.state.isStopped}
                                    isPaused={this.state.isPaused}
                                    className="anim"
                                />
                                :
                                this.state.agenda.lenght <= 0 ?
                                    <div className="box-hs">
                                        <h5>Aún no tenemos horarios.</h5>
                                    </div>
                                    :
                                    this.state.agenda.map(item => (
                                        <div className="box-hs">
                                            <h5>{item.clienteDados.NOME}</h5>
                                            <p>{item.ITENS[0].servicoDados.SERVICO}</p>
                                            <p className="destaque">{item.DATA}</p>
                                            <p className="destaque">{item.HORA}</p>
                                        </div>
                                    ))
                        }
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.data,
    param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Horarios);