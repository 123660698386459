import React from 'react';
import './index.scss';

//Componentes
import Header from '../../../components/header';
import Footer from '../../../components/Footer';
import CardServico from '../../../components/CardServico';

//Calling bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
//Calling WOWjs
import WOW from 'wowjs';

//Redux
import Api from '../../../services/api';
import ApiV2 from '../../../services/api-v2';
import { Diversos } from '../../../services/diversos';
import { ApplicationState } from '../../../store';
import { User } from '../../../store/ducks/user/types';
import * as UserActions from '../../../store/ducks/user/actions';
import { Param } from '../../../store/ducks/param/types';
import * as ParamActions from '../../../store/ducks/param/actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Auth } from '../../../store/ducks/auth/types';
import Informacoes from '../../MenuCliente';

import Lottie from 'react-lottie';
//Animaceos Lottie
import animationData from '../../../animations/loading.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

interface StateProps {
    user: User;
    param: Param;
}

interface DispatchProps {
    doLogin(user: User): void;
    setParam(param: Param): void;
    setParam(param: Param): void;
}

interface OwnProps { }

type Props = StateProps & DispatchProps & OwnProps;

class NovoTratamento extends React.Component<Props> {

    state: any = null;
    api: any;
    apiv2: any;
    userRef: any;

    constructor(props) {
        super(props);
        this.api = new Api();
        this.apiv2 = new ApiV2();
        this.state = {
            modal: false,

            loadingSubmit: false,
            nome: '',
            descricao: '',
            preco: null,
            tempo: null,
            categoria: null,
            categorias: [],
            servicosProfissional: [],
            servicos: [],
            servicosSelected: [],
            limitServicos: 0,
            search: '',
        }
    }

    // Defining WOW 
    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();

        if (this.props.user.empresa == 0) {
            window.location.href = '/home-cliente'
        }

        this.getServicos()
        this.getCategorias()
    }

    private async getServicos() {
        const self = this;

        let param = {
            profissional: self.props.user.codigo,
            limit: self.state.limit,
        };

        try {
            const { data } = await self.api.post(
                '/Servico/getAllByProfissional',
                param,
            );

            if (data.status == true) {

                this.setState({ servicos: data.msg });
            }
        } catch (e) {
            console.log(e);

        } finally {

        }
    }

    private async getCategorias() {
        const self = this;

        let param = {
            profissional: self.props.user.codigo,
            limit: self.state.limit,
        };

        try {
            const { data } = await self.api.get('/Categoria/getAll');

            if (data.status == true) {

                this.setState({ categorias: data.msg });
            }
        } catch (e) {
            console.log(e);

        } finally {

        }
    }

    private async submitForm() {
        const self = this;

        this.setState({ loadingSubmit: true })

        if (
            !this.state.categoria ||
            !this.state.nome ||
            !this.state.descricao ||
            !this.state.preco ||
            !this.state.tempo
        ) {
            alert('Atencion Campos obligatorios no rellenados.')
            this.setState({ loadingSubmit: false })
        } else {

            let param = {
                categoria: this.state.categoria,
                nome: this.state.nome,
                descricao: this.state.descricao,
                valor: this.state.preco,
                tempo: this.state.tempo,
            };

            try {
                const { data } = await self.api.post('/Servico/create', param,);

                if (data.status == false) {

                    console.log(Error)
                    alert('No se puede agregar el servicio ahora, vuelva a intentarlo más tarde')
                    this.setState({ loadingSubmit: false })

                } else {
                    let param2 = {
                        empresa: self.props.user.codigo,
                        profissional: 0,
                        servicos: [
                            {
                                codigo: data.msg,
                                nome: self.state.nome,
                                categoria: self.state.categoria,
                                descricao: self.state.descricao,
                                valor: self.state.preco,
                                tempo: self.state.tempo,
                            },
                        ],
                    };

                    const response = await self.api.post('/Servico/setServico', param2);

                    if (response.data.status == false) {
                        console.log(Error)
                        alert('No se puede agregar el servicio ahora, vuelva a intentarlo más tarde')
                        this.setState({ loadingSubmit: false })
                    } else {
                        alert('Servicio añadido con éxito')
                        window.location.href = 'nuevo-tratamiento'
                    }
                }
            } catch (e) {
                console.log(Error)
                alert('No se puede agregar el servicio ahora, vuelva a intentarlo más tarde')
            } finally {
                this.setState({ loadingSubmit: false })
            }
        }
    }


    render() {

        return (
            <>
                <Header
                    registra={false}
                    login={false}
                    notificacoes={true}
                />
                {
                    this.state.modal == true ?
                        <section className="modal-novo-tratamentos">
                            <div className="box">
                                <div style={{ display: 'flex' }}>
                                    <i className="fas fa-plus"></i>
                                    <h5>Nuevo Servicio</h5>
                                </div>
                                {
                                    this.state.loadingSubmit ?
                                        <div style={{ marginLeft: '-25px' }}>
                                            <Lottie
                                                options={defaultOptions}
                                                height={350}
                                                width={350}
                                                isStopped={this.state.isStopped}
                                                isPaused={this.state.isPaused}
                                            />
                                        </div>
                                        :
                                        <>
                                            <div className="box-select">
                                                <select className="select" value={this.state.categoria || ''} onChange={(event) => this.setState({ categoria: event.target.value })} >
                                                    <option value="1">Seleccionar categoria de servicio</option>
                                                    {
                                                        this.state.categorias.map(item => (
                                                            <option value={item.codigo}>{item.nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="input-area">
                                                <input placeholder="Servicio*"
                                                    value={this.state.nome || ''} onChange={(event) => this.setState({ nome: event.target.value })} />
                                            </div>
                                            <div className="input-area">
                                                <input placeholder="Descripcíon*"
                                                    value={this.state.descricao || ''} onChange={(event) => this.setState({ descricao: event.target.value })} />
                                            </div>
                                            <div className="input-area">
                                                <i className="fas fa-money-bill" />
                                                <input placeholder="Precio*"
                                                    value={this.state.preco || ''} onChange={(event) => this.setState({ preco: event.target.value })} />
                                            </div>
                                            <div className="input-area">
                                                <i className="fas fa-clock" />
                                                <input placeholder="Tiempo (minutos)*"
                                                    value={this.state.tempo || ''} onChange={(event) => this.setState({ tempo: event.target.value })} />
                                            </div>
                                            <button className="btn_primary" onClick={() => this.submitForm()}>
                                                <i className="fas fa-save"></i>
                                                <p>Guardar</p>
                                            </button>
                                            <button className="btn_secondary" onClick={() => this.setState({ modal: !this.state.modal })}>
                                                <p>Volver</p>
                                            </button>
                                        </>
                                }
                            </div>
                        </section>
                        :
                        null
                }

                <button className="btn_fl" onClick={() => this.setState({ modal: !this.state.modal })}>
                    <i className="fa fa-plus" />
                    <p>Nuevo Servicio</p>
                </button>
                <section className="container-novo-tratamentos">
                    <div className="row-tratamentos">
                        <div className="header-tratamentos">
                            <h5>Servicios existentes</h5>
                        </div>
                        <div className="servicos-container">
                            <div className="container-inf">
                                {/* <CardServico
                                    titulo="Micropigmentacíon labios"
                                    tipo="Peluqueria"
                                    valor="450"
                                    tempo="40-60"
                                /> */}

                                {
                                    this.state.servicos.map(item => (
                                        <section className="card-serv-adc wow fadeInUp">
                                            <h5>{item.nome}</h5>
                                            <div style={{ display: 'flex' }}>
                                                <i className="fa fa-check" style={{ marginRight: 10 }}></i>
                                                <p>Servicio ya agregado</p>
                                            </div>
                                        </section>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </section>

                <div className="spacing" />

                <Footer />
            </>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.data,
    param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NovoTratamento);