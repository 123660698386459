import React from 'react';
import './index.scss';

//Redux
import Api from '../../services/api';
import ApiV2 from '../../services/api-v2';
import {Diversos} from '../../services/diversos';
import {ApplicationState} from '../../store';
import {User} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {Auth} from '../../store/ducks/auth/types';


interface StateProps {
    user: User;
    param: Param;
}
  
interface DispatchProps {
    doLogin(user: User): void;
    setParam(param: Param): void;
    setParam(param: Param): void;
}
  
interface OwnProps {
    numero:String,
    validade:String,
    bandeira:String,
    link:any,
    id:String,
}
  
type Props = StateProps & DispatchProps & OwnProps;

export class CardCartao extends React.Component<Props> {

    state: any = null;
    api: any;
    userRef: any;

    constructor(props) {
        super(props);
        this.api = new Api();
        this.state = {
            modalDelete:false,
        }
    }

    private async deleteCard() {

        let param = {
            codigo: this.props.user.codigo,
            idMercadoPago:this.props.id,
          };
      
          try {
            const {data} = await this.api.post('/MercadoPago/dropCard', param);
      
            if (data.status === false) {
              alert(Error + 'Se ha producido un error al eliminar la tarjeta. Vuelva a intentarlo más tarde.');
            } else {
             alert('Tarjeta eliminada con éxito.')
             window.location.href = '/tarjetas'
            }
          } catch (e) {
            console.log(e);
          } finally {
            
          }
    }

    render() {
        return (
            <>
            {
                this.state.modalDelete ?
                <div className="modal-content" style={{left:0, top:0}}>
                    <div className="box-modal">
                        <h5>¿De verdad quieres borrar esta tarjeta?</h5>
                        <div className="box-pay-card wow fadeInUp">
                            <div>
                                <h5>{this.props.numero}</h5>
                                <p>{this.props.validade}</p>
                            </div>
                            <img src={this.props.link}/>
                        </div>
                        <div className="btn-2">
                            <button className="btn_secondary wow fadeInUp" 
                            style={{width:'48%', marginTop:25, color:'#6851FB', border:'solid 1px',borderColor:'#6851FB'}} 
                            onClick={() => this.setState({modalDelete:false})}>
                                <i className="fas fa-chevron-left"></i>
                                <p>Volver</p>
                            </button>
                            <button className="btn_primary wow fadeInUp" 
                            style={{width:'48%', marginTop:25, color:'#FFFFFF', border:'solid 1px',borderColor:'#6851FB'}} 
                            onClick={() => this.deleteCard() }>
                                <p>Sí</p>
                                <i className="fas fa-chevron-left" style={{transform:'rotate(180deg)'}}></i>
                            </button>
                        </div>
                    </div>
                </div>
                :
                null
            }
            <div className="box-pay-card wow fadeInUp">
                <div>
                    <h5>{this.props.numero}</h5>
                    <p>{this.props.validade}</p>
                </div>
                <img src={this.props.link}/>
                <i className="fas fa-trash" onClick={() => this.setState({modalDelete:true})}></i>
            </div>
            </>
        )
    }
};

const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.data,
    param: state.param.data,
  });
  
  const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({...UserActions, ...ParamActions}, dispatch);
  
  export default connect(mapStateToProps, mapDispatchToProps)(CardCartao);

