import React from "react";
import "./index.scss";

import Header from "../../components/header";
import Footer from "../../components/Footer";

import WOW from "wowjs";

import Conversa from "../../assets/images/conversa.svg";

//Redux
import Api from "../../services/api";
import ApiV2 from "../../services/api-v2";
import { Diversos } from "../../services/diversos";
import { ApplicationState } from "../../store";
import { User } from "../../store/ducks/user/types";
import * as UserActions from "../../store/ducks/user/actions";
import { Param } from "../../store/ducks/param/types";
import * as ParamActions from "../../store/ducks/param/actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Auth } from "../../store/ducks/auth/types";

import Lottie from "react-lottie";
//Animaceos Lottie
import animationData from "../../animations/loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface StateProps {
  user: User;
  param: Param;
  auth: Auth;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  setAuth(auth: Auth): void;
}

interface OwnProps {
  paramCategoria: any;
  paramSearch: string;
}

type Props = StateProps & DispatchProps & OwnProps;

class Ajuda extends React.Component<Props> {
  state: any = null;
  api: any;
  apiv2: any;
  userRef: any;

  public nomeRef: any;
  public emailRef: any;
  public telRef: any;
  public obsRef: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.apiv2 = new ApiV2();

    this.api = new Api();

    this.nomeRef = React.createRef();
    this.emailRef = React.createRef();
    this.telRef = React.createRef();
    this.obsRef = React.createRef();

    this.state = {
      isLoading: false,
      nome: "",
      email: "",
      tel: "",
      obs: "",
    };
  }

  // Defining WOW
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }

  private async submitForm() {
    const self = this;

    this.setState({ isLoading: true });

    if (
      !this.state.nome ||
      !this.state.email ||
      !this.state.tel ||
      !this.state.obs
    ) {
      alert("Atencion Campos obligatorios no rellenados.");
      this.setState({ isLoading: false });
      return;
    }

    try {
      let param = {
        nome: this.state.nome,
        telefone: this.state.tel,
        email: this.state.email,
        mensagem: this.state.obs,
      };

      const { data } = await self.apiv2.post(`/contact`, param);

      if (data.status === false) {
        console.log(Error);
        alert(
          "Ocurrió un error inesperado al enviar el mensaje, intente nuevamente más tarde."
        );
      } else {
        alert(
          "Mensaje enviado con exito, gracias por contactarnos, le responderemos a la brevedad."
        );
        window.location.href = "/";
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <>
        <Header registra={false} login={true} notificacoes={false} />
        <div className="container-quemsomos">
          <div className="row">
            <div className="box-tit">
              <h1 className="wow fadeInUp">
                Hable con nosotros, estamos con usted
              </h1>
              <p className="wow fadeInUp">
                ¿Necesita ayuda? nosotros en Turnau siempre estamos buscando más
                transparencia y eficiencia en la comunicación, y hoy ¿cómo
                podemos ayudarlo?
              </p>
              <img className="wow fadeInUp" src={Conversa} />
              <p className="wow fadeInUp">
                o hablar directamente por whatsapp o email
              </p>
              <a href="malito:info@turnau.com.ar">
                <p>info@turnau.com.ar</p>
              </a>
              <a href="" target="blank">
                <button className="whatsapp-btn wow fadeInUp">
                  <i className="fab fa-whatsapp"></i>
                  <p>WhatsApp</p>
                </button>
              </a>
            </div>

            <div className="box-form">
              {this.state.isLoading ? (
                <Lottie
                  options={defaultOptions}
                  height={350}
                  width={350}
                  isStopped={this.state.isStopped}
                  isPaused={this.state.isPaused}
                />
              ) : (
                <>
                  <div className="header-perfil wow fadeInUp">
                    <i className="fas fa-envelope"></i>
                    <h5>Formulario de contacto</h5>
                  </div>
                  <div className="input-box wow fadeInUp">
                    <small>Nobre y Apelido*</small>
                    <input
                      type="text"
                      value={this.state.nome}
                      onChange={(event) =>
                        this.setState({ nome: event.target.value })
                      }
                    />
                  </div>
                  <div className="input-box wow fadeInUp">
                    <small>Correo electrónico*</small>
                    <input
                      type="email"
                      value={this.state.email}
                      onChange={(event) =>
                        this.setState({ email: event.target.value })
                      }
                    />
                  </div>
                  <div className="input-box wow fadeInUp">
                    <small>Télefono</small>
                    <input
                      type="phone"
                      style={{ fontFamily: "Arial" }}
                      value={this.state.tel}
                      onChange={(event) =>
                        this.setState({ tel: event.target.value })
                      }
                    />
                  </div>
                  <div className="input-box wow fadeInUp">
                    <small>Mensaje</small>
                    <textarea
                      value={this.state.obs}
                      onChange={(event) =>
                        this.setState({ obs: event.target.value })
                      }
                    ></textarea>
                  </div>
                  <button
                    className="btn_primary wow fadeInUp"
                    style={{ width: "100%", marginTop: 25 }}
                    onClick={() => this.submitForm()}
                  >
                    <p>mandar</p>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Ajuda);
