import React from "react";
import swal from "sweetalert";

import "./index.scss";

import Lottie from "react-lottie";
import Loading from "../../components/loading";

//Componentes
import Header from "../../components/header";
import Footer from "../../components/Footer";
import Logo from "../../assets/images/turnau.png";

//Calling bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
//Calling WOWjs
import WOW from "wowjs";

//Animaceos Lottie
import animationData from "../../animations/complete.json";
import animationErro from "../../animations/erro.json";

//Redux
import Api from "../../services/api";
import ApiV2 from "../../services/api-v2";
import { Diversos } from "../../services/diversos";
import { ApplicationState } from "../../store";
import { User } from "../../store/ducks/user/types";
import * as UserActions from "../../store/ducks/user/actions";
import { Param } from "../../store/ducks/param/types";
import * as ParamActions from "../../store/ducks/param/actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Auth } from "../../store/ducks/auth/types";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const erroOptions = {
  loop: false,
  autoplay: true,
  animationData: animationErro,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface StateProps {
  user: User;
  param: Param;
  auth: Auth;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  setAuth(auth: Auth): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class Registro extends React.Component<Props> {
  api: any;
  apiv2: any;
  state: any;
  userRef: any;
  passwordRef: any;
  avatarRef: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.apiv2 = new ApiV2();
    this.userRef = React.createRef();
    this.passwordRef = React.createRef();
    this.avatarRef = React.createRef();
    this.state = {
      etapa: 1,
      perfil: 1,
      nome: "",
      apellido: "",
      email: "",
      telefone: "",
      senha: "",
      pessoa: "",
      agree: false,
      position: 0,
      image: null,
      empresa: null,
      empresas: [],
      isLoading: false,
    };
  }

  // Defining WOW
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();

    if (
      this.props.user &&
      this.props.user.status === true &&
      !this.props.user.fgFuncionario
    ) {
      // SE USUARIO ESTIVER LOGADO REDIRECIONA PARA OUTRA PAGINA
      window.location.href = "/home-cliente";
    } else if (
      this.props.user &&
      this.props.user.status === true &&
      this.props.user.fgFuncionario
    ) {
      window.location.href = "/home-salon";
    }
  }

  private async submitForm() {
    const self = this;

    if (
      self.state.name == "" ||
      self.state.email == "" ||
      self.state.senha == ""
    ) {
      this.props.setParam({ ...this.props.param, isLoading: false });
      swal("Atencion", "Atencion Formulario incompleto.", "error");
      return;
    }

    if (self.state.agree == false) {
      this.props.setParam({ ...this.props.param, isLoading: false });
      swal(
        "Atencion",
        "Atencion Necesito aceptar los TERMINOS Y CONDICIONES.",
        "error"
      );
      return;
    }

    await self.setState(
      {
        isLoading: true,
        loadingMsg: "Guardando su registro, espere a que se complete...",
      },
      () => this.props.setParam({ ...this.props.param, isLoading: true })
    );

    try {
      let param = {
        perfil: self.state.perfil,
        nome: self.state.nome + " " + this.state.apellido,
        senha: self.state.senha,
        email: self.state.email,
        telefone: self.state.telefone,
        empresa: self.state.empresa,
      };

      const { data } = await this.api.post("/User/create", param);

      if (data.status === false) {
        throw new Error(
          "No se puede completar el registro, intente nuevamente."
        );
      }

      let response: any = null;

      if (self.state.image !== "" && self.state.image !== null) {
        await self.setState({
          loadingMsg:
            "Guardando tu foto de perfil, espera a que se complete...",
        });

        let paramFoto = {
          user: data.codigo,
          file: [self.state.image.replace("data:image/png;base64, ", "")],
          extension: "png",
          perfil: self.state.perfil,
        };

        response = await this.api.post("/Document/upload/perfil", paramFoto);
      }

      await self.props.doLogin({
        codigo: data.codigo,
        nome: data.cliente.NOME,
        email: data.cliente.EMAIL,
        cpf: "",
        status: true,
        fgFuncionario: false,
        avatar:
          response && response.data && response.data.msg
            ? response.data.msg
            : null,
        empresa: 0,
      });

      self.setState({ etapa: this.state.etapa + 1 });
    } catch (e) {
      console.log(e);
      self.setState({ etapa: 4 });
      swal(
        "Atencion",
        "No se puede completar el registro, intente nuevamente",
        "error"
      );
    } finally {
      await self.setState({ isLoading: false, loadingMsg: "" }, () =>
        this.props.setParam({ ...this.props.param, isLoading: false })
      );
    }

    return;
  }

  private async handleAvatar() {
    const self = this;

    const file = self.avatarRef.current.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => self.setState({ image: reader.result });
      reader.onerror = (error) =>
        swal("Atencion", JSON.stringify(error), "error");
    }
  }

  render() {
    return (
      <>
        <Header registra={false} login={true} notificacoes={true} />

        {this.state.isLoading == true ? (
          <Loading />
        ) : (
          <div className="registra-screen">
            <div className="row wow fadeInDown">
              <img src={Logo} className="logo " />
              {this.state.etapa == 1 ? (
                <>
                  <div className="input-box ">
                    <small>Nombre*</small>
                    <input
                      type="text"
                      value={this.state.nome || ""}
                      onChange={(event) =>
                        this.setState({ nome: event.target.value })
                      }
                    />
                  </div>
                  <div className="input-box ">
                    <small>Apelido*</small>
                    <input
                      type="text"
                      value={this.state.apelido || ""}
                      onChange={(event) =>
                        this.setState({ apelido: event.target.value })
                      }
                    />
                  </div>
                  <div className="input-box ">
                    <small>Correo electrónico*</small>
                    <input
                      type="email"
                      value={this.state.email || ""}
                      onChange={(event) =>
                        this.setState({ email: event.target.value })
                      }
                    />
                  </div>
                  <div className="input-box ">
                    <small>Teléfono*</small>
                    <input
                      type="phone"
                      style={{ fontFamily: "Arial" }}
                      value={this.state.telefone || ""}
                      onChange={(event) =>
                        this.setState({ telefone: event.target.value })
                      }
                    />
                  </div>
                  <div className="input-box ">
                    <small>Contrasenã*</small>
                    <input
                      type="password"
                      value={this.state.senha || ""}
                      onChange={(event) =>
                        this.setState({ senha: event.target.value })
                      }
                    />
                  </div>
                  <div className="box-termos ">
                    <input
                      type="checkbox"
                      onClick={() => {
                        this.setState({ agree: !this.state.agree });
                        console.log("clicou");
                      }}
                    />
                    <p>Acepto los TERMINOS Y CONDICIONES</p>
                  </div>
                  <div className="footer-btn ">
                    <button
                      className="btn_secondary"
                      onClick={() => window.history.back()}
                    >
                      <i className="fas fa-chevron-left"></i>
                      <p>Volver</p>
                    </button>
                    <button
                      className="btn_primary"
                      onClick={() => {
                        if (!this.state.nome) {
                          swal(
                            "Atencion",
                            "Por favor ingrese el campo Nombre",
                            "error"
                          );
                          return false;
                        }

                        if (!this.state.apelido) {
                          swal(
                            "Atencion",
                            "Por favor ingrese el campo Apelido",
                            "error"
                          );
                          return false;
                        }

                        if (!this.state.email) {
                          swal(
                            "Atencion",
                            "Por favor ingrese el campo Correo electrónico",
                            "error"
                          );
                          return false;
                        }

                        if (!this.state.telefone) {
                          swal(
                            "Atencion",
                            "Por favor ingrese el campo Teléfono",
                            "error"
                          );
                          return false;
                        }

                        if (!this.state.agree) {
                          swal(
                            "Atencion",
                            "Seleccione el TERMINOS Y CONDICIONES",
                            "error"
                          );
                          return false;
                        }

                        this.setState({ etapa: this.state.etapa + 1 });
                      }}
                    >
                      <p>Siguiente</p>
                      <i className="fas fa-chevron-right"></i>
                    </button>
                  </div>
                </>
              ) : this.state.etapa == 2 ? (
                <>
                  <div className="box-foto ">
                    <h5>Escoje o tomate una foto para tu perfil.</h5>
                    <div
                      className="ico-area"
                      onClick={() => {
                        if (this.avatarRef) {
                          this.avatarRef.current.click();
                        }
                      }}
                    >
                      {!this.state.image ? (
                        <>
                          <i className="fas fa-camera"></i>
                          <p>Captura Silfie</p>
                        </>
                      ) : (
                        <img
                          src={`${this.state.image}`}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </div>
                    <input
                      type="file"
                      name="avatar"
                      id="avatar"
                      style={{ display: "none" }}
                      ref={this.avatarRef}
                      onChange={this.handleAvatar.bind(this)}
                    />
                  </div>

                  <div className="footer-btn ">
                    <button
                      className="btn_secondary"
                      onClick={() =>
                        this.setState({ etapa: this.state.etapa - 1 })
                      }
                    >
                      <i className="fas fa-chevron-left"></i>
                      <p>Volver</p>
                    </button>
                    <button
                      className="btn_primary"
                      onClick={() => this.submitForm()}
                    >
                      <p>Siguiente</p>
                      <i className="fas fa-chevron-right"></i>
                    </button>
                  </div>
                </>
              ) : this.state.etapa == 3 ? (
                <>
                  <div className="box-confirm">
                    <Lottie
                      options={defaultOptions}
                      height={300}
                      width={300}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                  </div>
                  <div className="footer-btn">
                    <button
                      className="btn_primary"
                      onClick={() => (window.location.href = "/home-cliente")}
                    >
                      <p>Comezar Ahora</p>
                      <i className="fas fa-chevron-right"></i>
                    </button>
                  </div>
                </>
              ) : this.state.etapa == 4 ? (
                <>
                  <div
                    className="box-confirm"
                    style={{ flexDirection: "column" }}
                  >
                    <Lottie
                      options={erroOptions}
                      height={300}
                      width={300}
                      isStopped={this.state.isStopped}
                      isPaused={this.state.isPaused}
                    />
                    <h5 style={{ color: "#FFF" }}>
                      algo salió mal, inténtalo de nuevo más tarde
                    </h5>
                  </div>
                  <div className="footer-btn">
                    <button
                      className="btn_primary"
                      onClick={() => (window.location.href = "/registrate")}
                    >
                      <p>comienzo</p>
                      <i className="fas fa-chevron-right"></i>
                    </button>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        )}

        <div className="spacing" />

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Registro);
