import React from "react";

//Site-----------------
import Home from "./pages/Home";
import Registrar from "./pages/Registrar";
import LoginEscolha from "./pages/LoginEscolha";
import Politica from "./pages/Politica";
import QuemSomos from "./pages/QuemSomos";
import Ayuda from "./pages/Ajuda";

//CLIENTE-------------
import Login from "./pages/Login";
import HomeCliente from "./pages/HomeCliente";
import PerfilCliente from "./pages/InformacoesCliente";
import Salao from "./pages/SalaoServicos";
import InfoServico from "./pages/InfoServico";
import InfoServicoPromo from "./pages/InfoServicoPromo";
import BuscarPage from "./pages/Buscar";
import MiInformacion from "./pages/InformacoesCliente/MiInformacion";
import MiDirecciones from "./pages/InformacoesCliente/MisDirecciones";
import MiDireccion from "./pages/InformacoesCliente/MiIDireccion";
import Contrasena from "./pages/InformacoesCliente/Contrasena";
import ContrasenaRecuperar from "./pages/InformacoesCliente/ContrasenaRecuperar";
import Tarjetas from "./pages/InformacoesCliente/Tarjetas";
import HorariosRegistrados from "./pages/InformacoesCliente/HorariosRegistrados";
import HorariosRegistradosDetalhe from "./pages/InformacoesCliente/HorariosRegistradosDetalhe";
import Points from "./pages/InformacoesCliente/Points";
import Favoritos from "./pages/InformacoesCliente/Favoritos";
import DetalheNotificacaoCliente from "./pages/InformacoesCliente/DetalheNotificacaoCliente";

//SALAO------------------------------------
import RecuperarSenhaSalao from "./pages/InformacoesSalao/ContrasenaRecuperar";
import LoginSalao from "./pages/LoginSalao";
import RegistrarSalao from "./pages/RegistrarSalao";
import HomeSalao from "./pages/HomeSalao";
import MiInformacionSalao from "./pages/InformacoesSalao/MiInformacionSalon";
import MiDirecionSalao from "./pages/InformacoesSalao/MiIDireccionSalon";
import NovoHorario from "./pages/NovoHorario";
import Confirmacao from "./pages/Confirmacao";
import PerfilSalao from "./pages/InformacoesSalao";
import ContrasenaSalao from "./pages/InformacoesSalao/ContrasenaSalao";
import Contacto from "./pages/InformacoesSalao/Contacto";
import HorariosSalao from "./pages/InformacoesSalao/HorariosRegistradosSalao";
import Promocao from "./pages/InformacoesSalao/Promocao";
import novaPromocao from "./pages/InformacoesSalao/novaPromocao";
import Profissionais from "./pages/InformacoesSalao/Profissionais";
import Fotos from "./pages/InformacoesSalao/Fotos";
import Estatisticas from "./pages/InformacoesSalao/Estatisticas";
import Tratamentos from "./pages/InformacoesSalao/Tratamentos";
import AdicionarTratamentos from "./pages/InformacoesSalao/AdicionarTratamentos";
import DetalheNotificacao from "./pages/InformacoesSalao/DetalheNotificacao";

//Redux
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import Api from "./services/api";
import ApiV2 from "./services/api-v2";
import "moment/locale/ar";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Switch>
            {/* CLIENTE--------------------- */}
            <Route
              path="/registrate-cliente"
              exact
              strict
              component={Registrar}
            />
            <Route path="/home-cliente" exact strict component={HomeCliente} />
            <Route path="/login-cliente" exact strict component={Login} />
            <Route path="/mi-perfil" exact strict component={PerfilCliente} />
            <Route path="/salon/:id" exact strict component={Salao} />
            <Route
              path="/servicio/programar/:id/:salao"
              exact
              strict
              component={InfoServico}
            />
            <Route
              path="/promocao/programar/:id/:salao/:promo"
              exact
              strict
              component={InfoServicoPromo}
            />
            <Route path="/login" exact strict component={LoginEscolha} />
            <Route
              path="/mi-informacion"
              exact
              strict
              component={MiInformacion}
            />
            <Route
              path="/nueva-direcion"
              exact
              strict
              component={MiDireccion}
            />
            <Route
              path="/mi-direcciones"
              exact
              strict
              component={MiDirecciones}
            />
            <Route
              path="/cambiar-contrasena"
              exact
              strict
              component={Contrasena}
            />
            <Route
              path="/recuperar-contrasena-cliente"
              exact
              strict
              component={ContrasenaRecuperar}
            />
            <Route path="/tarjetas" exact strict component={Tarjetas} />
            <Route
              path="/horarios-registrados"
              exact
              strict
              component={HorariosRegistrados}
            />
            <Route
              path="/horarios-registrados/:id/detalhe"
              exact
              strict
              component={HorariosRegistradosDetalhe}
            />
            <Route path="/beautypoints" exact strict component={Points} />
            <Route path="/favoritos" exact strict component={Favoritos} />
            <Route path="/buscar/:termo" exact strict component={BuscarPage} />
            <Route
              path="/informacion-procedimiento-cliente/:id"
              exact
              strict
              component={DetalheNotificacaoCliente}
            />

            {/* SALAO--------------------- */}
            <Route path="/login-salon" exact strict component={LoginSalao} />
            <Route
              path="/recuperar-contrasena-salon"
              exact
              strict
              component={RecuperarSenhaSalao}
            />
            <Route
              path="/registrate-salon"
              exact
              strict
              component={RegistrarSalao}
            />
            <Route path="/home-salon" exact strict component={HomeSalao} />
            <Route
              path="/mi-informacion-salon"
              exact
              strict
              component={MiInformacionSalao}
            />
            <Route path="/nuevo-horario" exact strict component={NovoHorario} />
            <Route path="/confirmacion" exact strict component={Confirmacao} />
            <Route
              path="/mi-perfil-salon"
              exact
              strict
              component={PerfilSalao}
            />
            <Route
              path="/mi-direccion-salon"
              exact
              strict
              component={MiDirecionSalao}
            />
            <Route
              path="/cambiar-contrasena-salon"
              exact
              strict
              component={ContrasenaSalao}
            />
            <Route path="/contacto-salon" exact strict component={Contacto} />
            <Route
              path="/horarios-salon"
              exact
              strict
              component={HorariosSalao}
            />
            <Route
              path="/nueva-promocion"
              exact
              strict
              component={novaPromocao}
            />
            <Route path="/promocion" exact strict component={Promocao} />
            <Route
              path="/mi-profesionales"
              exact
              strict
              component={Profissionais}
            />
            <Route path="/mis-fotos" exact strict component={Fotos} />
            <Route path="/estadisticas" exact strict component={Estatisticas} />
            <Route
              path="/tratamientos-salon"
              exact
              strict
              component={Tratamentos}
            />
            <Route
              path="/nuevo-tratamiento"
              exact
              strict
              component={AdicionarTratamentos}
            />
            <Route
              path="/informacion-procedimiento/:id"
              exact
              strict
              component={DetalheNotificacao}
            />

            {/* INSTITUCIONAL--------------------- */}

            <Route path="/" exact strict component={Home} />
            <Route path="/politica" exact strict component={Politica} />
            <Route path="/quienes-somos" exact strict component={QuemSomos} />
            <Route path="/ayuda" exact strict component={Ayuda} />
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
}
